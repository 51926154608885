import { Component, AfterViewInit, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service";
import { AlertService } from 'ngx-alerts';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from "moment";

@Component({
  	selector: 'app-punches',
  	templateUrl: './punches.component.html',
  	styleUrls: ['./punches.component.scss']
})
export class PunchesComponent implements OnInit {

 	@ViewChild(DataTableDirective)
  	dtElement: DataTableDirective;
  	dtTrigger: Subject<any> = new Subject();
  	startDateEdit: Date = moment().toDate();
  	endDateEdit: Date = moment().toDate();
  	dtOptions: any = {
	    pagingType: 'full_numbers',
	    dom: 'Bfrtip',
    	buttons: [
        	{ extend: 'print', text: 'Imprimir', title: 'Reporte de marcaciones' },
        	{ extend: 'excel', title: 'Reporte de marcaciones' }
      	],
  		language : {
    		url: "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
    		emptyTable : "No existen ausencias con los parametros especificados"
  		},
  		pageLength : 50     
  	};
  	status;
  	type;
  	isLoadingTab: boolean = false;
  	isLoading: boolean = false;
  	reports: any = [];
  	groups: any = null;
 
  	constructor(public httpService: HttpService, private alertService: AlertService) { }
  
  	ngOnInit() {
  		this.status = 't';
  		this.type = 't';
    	this.draw_report();    
  	}
  
  	ngAfterViewInit(): void {
   		this.dtTrigger.next();
   	}

   	ngOnDestroy(): void {
    	// Do not forget to unsubscribe the event
     	this.dtTrigger.unsubscribe();
   	}

   	draw_report() : void {
  		let extra = ''; 
  		let from  = moment(this.startDateEdit).format("YYYY-MM-DD");
  		let to = moment(this.endDateEdit).format("YYYY-MM-DD");
  		if(this.groups) {
  			if(this.groups.users.selected.length > 0) {
  				let id_str = '';
				this.groups.users.selected.forEach((x,i) => {
					id_str += x.id +((i < (this.groups.users.selected.length -1)) ? ',' : '');
				});  				
				extra += '&users='+id_str;
  			} else {
  				if(this.groups.id_grupo != 0) {  			
	  				extra = '&id_tipo_grupo='+this.groups.id_tipo_grupo;
	  				if(this.groups.id_grupo) extra += '&id_grupo='+this.groups.id_grupo;
	  				if(this.groups.id_grupos) extra += '&id_grupos='+this.groups.id_grupos.toString();  			
	  			}
	  		}  			
  		}  		
  		let uri = '/reporte/marcaciones?from='+from+'&to='+to+'&type='+this.type+'&status='+this.status+''+extra;
  		this.isLoadingTab= true;
  		this.isLoading = true;
  		this.httpService.getHttpAuth(uri).subscribe(data => {
       		this.isLoadingTab = false;
       		this.isLoading = false;
       		this.reports = data.data;       	
       		this.rerender();
     	});
  	}
   
   	apply(){
 		this.draw_report();	     
   	}
   
   	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
       		// Destroy the table first
        	dtInstance.destroy();
        	// Call the dtTrigger to rerender again
        	this.dtTrigger.next();
      	});
   	}
   
   	filter(event){   		
      	this.groups=event;
      	if(!this.isLoadingTab) this.isLoading = event.loading;       
    }

}
