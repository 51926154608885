import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { ContentLoaderChecksComponent } from "@app/globals/content-loader-checks/content-loader-checks.component"
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import { ScheduleService } from '@services/globals/schedule.service';
import * as moment from "moment"
import { Templates } from "@interfaces/templates";
import { TemplatesDetails } from "@interfaces/templates-details";
import { GlobalsService } from '@services/globals/globals.service'
import 'twix';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
	dtTrigger: Subject<any> = new Subject();
	//dtElement: DataTableDirective;
   	//dtElement: DataTablesModule;
  	isLoadingTab: boolean = true;  	
  	id_empresa: number;  	
  	dtOptions: any = {
    	pagingType: 'full_numbers',
   		language : {
            "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
   	 	}
  	};

  	// options buttons
  	op_add_template = {
  		text:"Agregar Plantilla",
   	 	disabled: false,
    	type:"btn btn-default btn-block",
    	icon:"fa fa-spinner fa-spin",
    	isLoading:false,
    	principal_icon:"fa fa-plus"
  	};
  	op_add_template_detail = {
  		text:"Añadir",
   	 	disabled: false,
   	 	isLoading:false,
   	 	onlyIcon : true,
       	type:"btn btn-default btn-block",
    	icon:"fa fa-spinner fa-spin",   
    	principal_icon:"fa fa-plus"
  	};
  	op_edit = {
  		disabled : false,
  		type : "btn btn-success",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-pencil",
  		onlyIcon : true,
  		isLoading : false,
  	};
  	op_delete = {
  		disabled : false,
  		type : "btn btn-danger",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-trash",
  		onlyIcon : true,
  		isLoading : false,
  		delete: true
  	}; 
  	op_save = {
  		disabled: false,
   	 	isLoading:false,
   	 	onlyIcon : true,
  		text:"Guardar",
    	type:"btn btn-primary",
    	icon:"fa fa-spinner fa-spin",    	
    	principal_icon:"fa fa-floppy-o"
  	};    	
  	
  	sindicato: boolean=false;
  	sindicato_time: number = 4;

  	modal : any;
  	ng_is_new = false;
  	ng_max_working_time = 0;
  	ng_edit_detail = false;
  	ng_current_detail_index = null;
  	ng_modal_title = 'Crear Plantilla';
  	public ng_start: any="00:00";
  	public ng_end: any="00:00";
  	template : Templates;
  	template_detail : TemplatesDetails;
  	templates_list = []; 
  	pv_admin : boolean = false; 

  	constructor(
  		private scheduleService: ScheduleService, 
  		public gs: GlobalsService,
  		private formatHoursPipe: FormatHoursPipe,
  		public httpService: HttpService, 
  		private alertService: AlertService, 
  		private atp: AmazingTimePickerService) { }

  	ngOnInit() {
	    this.pv_admin = this.gs.getPermission().admin_remover_privilegios_edicion; 
	    this.reset(false);
	   	this.httpService.getHttpAuth('/jornada').subscribe(time => { 
	    	this.ng_max_working_time = time; 	    	
	    	this.draw();
	    });
  	}

  	ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

  	changeRest(e,i){
    	//this.schedules[i].descanso=e;
 	}

 	clickOnTimePicker($event, type){
    	const amazingTimePicker = this.atp.open({
    		time: this.formatHoursPipe.transform($event.target.value,"hh:mm A","HH:mm")
    	});

    	amazingTimePicker.afterClose().subscribe(time => {
      		if(type == "start") {
      			this.ng_start = moment(time,"HH:mm").format("hh:mm A");
      			this.template_detail.hora_entrada = time;
      			this.template_detail.start = this.ng_start;
      		} else {
      			this.ng_end = moment(time,"HH:mm").format("hh:mm A");
      			this.template_detail.hora_salida = time;
      			this.template_detail.end = this.ng_end;
      		}
    	});
  	}

  	open(isNew, id){
  		this.ng_is_new = isNew;
  		this.ng_edit_detail = false;
  		this.ng_current_detail_index = null;
      	if(this.ng_is_new) {
      		this.ng_modal_title = 'Crear Plantilla';
      		this.reset(false);
      		this.modal.open();        
      		return;
      	}
      	this.op_edit.disabled = true;
      	this.op_delete.disabled = true;
      	this.ng_modal_title = 'Editar Plantilla';
      	this.template.id = id;     
      	this.template.borrar = [];  
        this.httpService.getHttpAuth("/plantilla/"+id).subscribe(x => {
        	this.template.nombre = x.data.nombre;
        	this.template.dinamico = x.data.dinamico;
        	this.template.detalle = []; 
        	this.op_edit.disabled = false;
      		this.op_delete.disabled = false;       
          	for(let i=0; i<x.data.plantilla_detalle.length; i++) {          		
          		let mom_start = moment(x.data.plantilla_detalle[i].hora_entrada,"HH:mm");
          		let mom_end = moment(x.data.plantilla_detalle[i].hora_salida,"HH:mm");
          		let diff = this.scheduleService.checkTimeDiff(mom_start, mom_end, x.data.plantilla_detalle[i].descanso);           		
            	this.template.detalle.push({
            		id : x.data.plantilla_detalle[i].id,
            		dias : x.data.plantilla_detalle[i].dias,
            		libre : x.data.plantilla_detalle[i].libre,
            		start : moment(x.data.plantilla_detalle[i].hora_entrada,"hh:mm").format("hh:mm A"),
            		end : moment(x.data.plantilla_detalle[i].hora_salida,"HH:mm").format("hh:mm A"),
            		hora_entrada : x.data.plantilla_detalle[i].hora_entrada,
            		hora_salida : x.data.plantilla_detalle[i].hora_salida,
            		descanso : x.data.plantilla_detalle[i].descanso,
            		syndicate: (x.data.plantilla_detalle[i].hora_sindicato) ? true : false,
            		coffee_break: (x.data.plantilla_detalle[i].tiempo_coffee) ? true : false,
            		tiempo_coffee : x.data.plantilla_detalle[i].tiempo_coffee,
            		cant_coffee : x.data.plantilla_detalle[i].cant_coffee,
            		hora_sindicato : (!x.data.plantilla_detalle[i].hora_sindicato) ? 
            			8 : (x.data.plantilla_detalle[i].hora_sindicato/60),
            		total_hours : diff/60
            	}); 
          	} 
          	this.modal.open();         
        });              
    } 

  	draw() {  		
  		this.isLoadingTab = true;
  		this.op_add_template.isLoading = true;
	    this.op_add_template.disabled = true;  		
  		this.httpService.getHttpAuth("/plantilla/get/all").subscribe(x => {	  
  			this.templates_list = [];  
	      	this.isLoadingTab=false;
	      	this.op_add_template.isLoading = false;
	    	this.op_add_template.disabled = false;
	      	for(let i = 0; i < x.data.length; i++) {
	      		let days = 0;	      		
	      		for(let k = 0; k < x.data[i].plantilla_detalle.length; k++) {
	      			days = days + x.data[i].plantilla_detalle[k].dias;
	      		}
	      		this.templates_list.push({ 
	      			id: x.data[i].id,
	      			dynamic : x.data[i].dinamico, 
	      			name : x.data[i].nombre, 
	      			days : days });
	      	}
	      	this.rerender();	     
	    });
  	}

  	add(){
    	var hour ="";
    	if(this.template_detail.libre){
      		this.template_detail.descanso = 0;
      		this.template_detail.hora_sindicato = 8;
      		this.template_detail.coffee_break = false;  
      		this.template_detail.tiempo_coffee = null;
    		this.template_detail.cant_coffee = null;    		
      		hour = "00:00";
    	}

    	if(!this.template_detail.coffee_break) {
    		this.template_detail.tiempo_coffee = null;
    		this.template_detail.cant_coffee = null; 
    	}
    
    	if(!this.template_detail.libre) {
      		let difference = this.scheduleService.checkTimeDiff(
        		moment(this.ng_start,"hh:mm A"),
        		moment(this.ng_end,"hh:mm A"),
        		this.template_detail.descanso);
      		hour = moment.utc(moment.duration(difference, "minutes").asMilliseconds()).format("hh:mm");
      		var parsed_h = parseInt(hour);
      		this.template_detail.total_hours = parsed_h;

      		if(this.template_detail.syndicate) {
        		if(this.template_detail.hora_sindicato < parsed_h){
        			let msg = 'El horario seleccionado no es valido, valida que el horario sea igual o menor a ';
        			msg += 'las horas de sindicato';
          			this.alertService.danger(msg);
          			return 0;
        		}
      		} else{
      			this.template_detail.hora_sindicato = null;
      		}

      		let validator = this.scheduleService.validateMaxTime(
        		moment(this.ng_start,"hh:mm A"),
        		moment(this.ng_end,"hh:mm A"),
        		this.ng_max_working_time,
        		this.template_detail.descanso);      
      		if(!validator){
      			let msg = 'El horario seleccionado no es valido, valida que el horario sea igual o menor a '; 
      			msg += this.ng_max_working_time + ' horas incluyendo el tiempo de descanso.'
        		this.alertService.danger(msg);
        		return 0;
      		}
    	}

    	if(this.ng_edit_detail) {
    		this.template.detalle[this.ng_current_detail_index] = this.template_detail;
    	} else {
    		this.template.detalle.push(this.template_detail);
    	}
    	
    	this.reset(true);
   	}

  	save(){
     	var success = function(x, mode, $this){
    		let msg = (mode == 'crear') ? 'creada' : 'editada';
    		$this.op_add_template_detail.disabled = false;
			$this.op_save.disabled = false;
    		$this.op_save.isLoading = false;       		     
      		$this.alertService.success("Plantilla "+msg+" exitosamente");
    		$this.modal.dismiss(); 
    		$this.draw(); // reload table again   
    	}
    	this.op_add_template_detail.disabled = true;    
    	this.op_save.disabled = true;
    	this.op_save.isLoading = true;
    	console.log(this.template);
    	if(this.ng_is_new) {
      		this.httpService.postHttpAuth("/plantilla", this.template).subscribe(x => {
          		success(x, 'crear', this);
      		}, err=> {
        		this.op_save.disabled = false;
    			this.op_save.isLoading = false;
    			this.op_add_template_detail.disabled = false;
      		});
    	} else {
      		this.httpService.putHttpAuth("/plantilla/"+this.template.id, this.template).subscribe(x => {
          		success(x, 'editar', this);
     		}, err => {
        		this.op_save.disabled = false;
    			this.op_save.isLoading = false;
    			this.op_add_template_detail.disabled = false;
      		});
    	}
  	}

  	delete(id,i){
  		this.alertService.success("Plantilla eliminada exitosamente");
  		this.draw();    
 	}

  	delete_template_detail(i) {
  		let detail = this.template.detalle[i];
  		console.log(this.template.detalle[i].id);
  		if(detail.id != null || detail.id != undefined) this.template.borrar.push(detail.id); 
  		this.template.detalle.splice(i,1);
  	}

   	edit_curr_detail(index) {       		
   		this.ng_edit_detail = true;
   		if(index == this.ng_current_detail_index) {
   			this.ng_edit_detail = false;
   		}

   		if(!this.ng_edit_detail) {
   			this.ng_current_detail_index = null;
   			this.op_add_template_detail.text = 'Añadir';
   			this.op_add_template_detail.principal_icon = 'fa fa-plus';
   			this.reset(true);   			
   		} else {
   			this.ng_current_detail_index = index;
   			this.op_add_template_detail.text = 'Editar';
   			this.op_add_template_detail.principal_icon = 'fa fa-pencil';
   			this.template_detail = Object.assign({}, this.template.detalle[index]);
   			this.template_detail.hora_sindicato = 
   				(!this.template_detail.hora_sindicato) ? 8 : this.template_detail.hora_sindicato;  
   			if(this.template_detail.libre) {
   				this.ng_start = "12:00 AM";
   				this.ng_end = "12:00 AM";
   			}else{
   				this.ng_start = moment(this.template.detalle[index].hora_entrada,"HH:mm").format("hh:mm A");
   				this.ng_end = moment(this.template.detalle[index].hora_salida,"HH:mm").format("hh:mm A"); 
   			}  	  			    			
   		}	
   		console.log(this.template_detail);  			
   	}

  	reset(onlydetail) : void {
  		this.ng_start = "12:00 AM";
  		this.ng_end = "12:00 AM";
  		this.template_detail = {
  			dias: 1,
  			start : "12:00 AM",
  			end : "12:00 AM",
    		hora_entrada : "00:00",
    		hora_salida : "00:00", 
   	 		libre : false, 
    		descanso : 0, 
    		total: 0, 
    		hora_sindicato : 8,
    		total_hours : null,
    		syndicate : false,
    		coffee_break: false,
    		tiempo_coffee : 15,
    		cant_coffee : 1,
    		delete: false, 
    		id : null
  		};
  		if(!onlydetail) this.template = { id : null, dinamico : false, nombre : '', detalle : [], borrar : [] };   		
  	}

  	deleteScheduleTime(id,i){
    	/*console.log(this.type);
    	if(this.type=="new")
      		this.schedules.splice(i, 1);
    	else{
      		if(this.schedules[i].id)this.schedules[i].delete=true;
      		else this.schedules.splice(i, 1);
    	}*/
  	} 

  	modalIsReady(modal) : void { this.modal = modal; }  

  	close() : void{ this.modal.dismiss(); } 	

  	free_day_adjust(e){
    	if(e.target.value){	    		
    		this.template_detail.syndicate = false; 
    		this.template_detail.hora_sindicato = 8;
    		this.template_detail.hora_entrada = "00:00";
    		this.template_detail.hora_salida = "00:00";
    		this.template_detail.start = "12:00 AM";
    		this.template_detail.end = "12:00 AM";
    		this.ng_start = "12:00 AM";
    		this.ng_end = "12:00 AM";
    		this.template_detail.coffee_break = false;    		
    		this.template_detail.descanso = 0;
    	}
  	}

    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }

  	editRowSchedule(id) {
    	console.log(id);
  	}
}
