import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from "@angular/router";
import {environment} from './../../../environments/environment'

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

 	configJanusSuite: any = null;
 	openNav: boolean=true;
 	token: string = "";
  	user: any;
  	group: any;  
  	ng_profile_picture = '';
  	grploader : any = JSON.parse(localStorage.getItem('grupo'));
  	permissions :any;

  	constructor( private router: Router) {} 

  	ngOnInit() {   	 
    	this.configJanusSuite = environment.suite_conf; 
    	this.user=localStorage.getItem("user");
    	this.token=localStorage.getItem("token");
    	this.user=JSON.parse(this.user);
    	this.group = localStorage.getItem("grupo")
    	this.group = JSON.parse(this.group);
    	this.group = this.group.grupos.empresa; 
    	let pic = this.grploader.usuario.perfil;  		
  		this.ng_profile_picture = (!pic) ? 'assets/images/perfil/alfredorods.png' : pic.url; 
  		this.permissions = JSON.parse(localStorage.getItem("permissions"));	    	    
  	} 

  	logout(){
    	localStorage.removeItem("user");
    	localStorage.removeItem("token");
    	localStorage.removeItem("group");
    	localStorage.removeItem("filters_cards");	
    	localStorage.removeItem("filters_users");	
    	this.router.navigate(["/login"]);
  	}

}
