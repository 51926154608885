import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output} from '@angular/core';
import { ComponentRef, EventEmitter, ViewChild } from '@angular/core';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import { Router,  ActivatedRoute } from "@angular/router";
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GlobalsService } from '@services/globals/globals.service';
import * as moment from "moment";
import { ManualConcepts } from '@interfaces/manual-concepts';
import { Angular2Txt } from 'angular2-txt/Angular2-txt';
import { IgxDatePickerModule } from "igniteui-angular";
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular";

@Component({
  selector: 'app-concepts',
  templateUrl: './concepts.component.html',
  styleUrls: ['./concepts.component.scss']
})
export class ConceptsComponent implements OnInit {

  	constructor(  		
		public httpService: HttpService,	   
	   	private formatHoursPipe: FormatHoursPipe,
	    private alertService: AlertService,
	    private router: Router,	
	    public gs: GlobalsService,
	    private atp: AmazingTimePickerService
  	) { 
  		this.ng_company = this.gs.getCompany();	
  		this.ng_integrity = (this.ng_company.id_sistema == 2) ? true : false;
  	}

  	// datatables
  	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
  	@ViewChild("uidt") dpicker: IgxDatePickerComponent;
  	dtTrigger: Subject<any> = new Subject();  	
  	dtOptions: any = {	 
	    language : {
	      	url : "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
	      	emptyTable : "No existen usuarios"
	    },
	    pageLength : 25     
	};	
	ng_manual_concept : ManualConcepts;
	ng_current_manual_concept = null;
	ng_current_dist = null;
	ng_dist_index = 0;
	ng_dist : any = [];
	ng_start_date = new Date();
	ng_end_date = new Date();
	ng_date = new Date();
	ng_filters = { start : new Date(), end : new Date(), id_concepto : 't' }
	ng_id_closure = null;
	ng_list_manual_concepts = [];
	ng_list_concepts = [];
	ng_closures_list = [];
	ng_list_users = [];
	ng_list_vacations = [];
	ng_txt = [];
	ng_txt_pool = [];
	ng_selected_user = null;
	ng_future_vacs = false;
	isLoadingTab = false;
	ng_loading_users = false;
	ng_all_checked_command :  boolean = false;
	ng_modals = { concept : null, show : null, vacations : null};
	ng_integrity : boolean;
	ng_company;
	grploader : any = JSON.parse(localStorage.getItem('grupo'));  

	ngOnInit() {	
		this.reset();				
		//this.ng_integrity = (this.grploader.grupos.empresa.id_sistema == 2) ? true : false;		
		this.ng_manual_concept.fecha = moment(this.ng_date).format('YYYY-MM-DD');
        this.ng_manual_concept.fecha_inicio = moment(this.ng_start_date).format('YYYY-MM-DD');
        this.ng_manual_concept.fecha_fin = moment(this.ng_end_date).format('YYYY-MM-DD');         
       	this.httpService.getHttpAuth("/concepto-manual").subscribe(x => this.ng_list_concepts = x.data);
        this.search();       
	}

    disableDates() : void {     	    	
    	this.dpicker.disabledDates = [];   
		let date = moment(this.ng_start_date).toDate(); 	
		let disableFrom = moment(date).subtract(100, 'years').toDate();    	
      	var dateMax = date;
      	var dateMin = disableFrom;      	
      	this.dpicker.disabledDates = [
        	{type: DateRangeType.Between,dateRange:[dateMin, dateMax]}
        ];        	
    }  

	ngAfterViewInit(): void {
    	this.dtTrigger.next();
    	this.disableDates(); 
  	}

  	ngOnDestroy() {
  		this.dtTrigger.unsubscribe();
  	}

  	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      		// Destroy the table first
      		dtInstance.destroy();
      		// Call the dtTrigger to rerender again
      		this.dtTrigger.next();
    	});
  	}

	download(){
		let txt = [];
		if(this.ng_txt.length == 0){
			this.alertService.info('No se encuentran usuarios seleccionados');
			return;
		}
		for(let i = 0; i < this.ng_txt.length; i++){
			txt.push({
				'g_financiero' : this.ng_txt[i].g_financiero,
		    	'empresa' : this.ng_txt[i].empresa,
		    	'emp' : this.ng_txt[i].emp, 
		    	'dia' : this.ng_txt[i].dia, 
		    	'f_inicio' : this.ng_txt[i].f_inicio, 
		    	'f_fin' : this.ng_txt[i].f_fin,
		    	'cod' : this.ng_txt[i].cod_integrity
			});
		}   
		var options = { fieldSeparator: ',', quoteStrings:"",useBom :  true};
		new Angular2Txt(txt, 'vacaciones_integrity ' + moment().format("DD-MM-YY HH:mm"),options);
  	}	

	modalIsReady(type, modal) {
		if(type == 1) this.ng_modals.concept = modal;
		if(type == 2) this.ng_modals.show = modal;
		if(type == 3) this.ng_modals.vacations = modal;
	}

	close_modal(){
		this.ng_modals.concept.dismiss();
	}

	open(type) {
		this.btn_1_1.disabled = true;
		this.btn_1_1.isLoading = true;
		this.ng_loading_users = true;
		if(type==3) {
			this.httpService.getHttpAuth("/concepto-manual/cortes").subscribe(x => {  
				this.ng_closures_list = x.data;
				this.ng_id_closure = x.data[0].id;
				this.ng_loading_users = false;
				this.btn_1_1.disabled = false;
				this.btn_1_1.isLoading = false;
				this.ng_modals.vacations.open();				
			});	
			return;	
		}		
		this.ng_manual_concept.libre = (this.ng_company.id == 42 || this.ng_company.id == 43) ? true : false;		
		this.httpService.getHttpAuth("/usuario/vista?id_rol=5&id_estado=1",{}).subscribe(x => { 
			let temporary = [];						
			x.data.filter((user, i) => {				
				//this.ng_manual_concept.usuarios.push(user.id_usuario);
				temporary.push({id : user.id_usuario,  nombre : user.codigo +' - '+user.nombre+' '+user.apellido});
			});  
			this.ng_list_users = temporary;
			this.ng_loading_users = false;
			this.btn_1_1.disabled = false;
			this.btn_1_1.isLoading = false;

			this.ng_select_user('all', {checked : false});
			this.ng_modals.concept.open();	     
        }, error => {
        	this.btn_1_1.disabled = false;
			this.btn_1_1.isLoading = false;
			this.ng_loading_users = false;
        });		
	}

	show_manual_concept(id){
		this.ng_current_manual_concept = null;
		this.ng_current_dist = null;
		this.btn_1_3.isLoading = true;
		this.btn_1_4.isLoading = true;
  		this.btn_1_3.disabled = true;
  		this.btn_1_4.disabled = true;
		this.httpService.getHttpAuth("/concepto-manual/"+id).subscribe(x => {
        	this.ng_current_manual_concept = x.data;
        	this.btn_1_3.isLoading = false;
			this.btn_1_4.isLoading = false;
  			this.btn_1_3.disabled = false;
  			this.btn_1_4.disabled = false;  
        	if(this.ng_current_manual_concept.dist.length > 0){
        		this.ng_current_dist = this.ng_current_manual_concept.dist[0];   
        		this.ng_dist = this.ng_current_manual_concept.dist;     	
        	} 
        	
  			this.ng_modals.show.open();  				
        });
	}

  	ng_select_user(id, check) {		
  		if(id == 'all') this.ng_manual_concept.usuarios = [];
  		this.ng_list_users.filter((u, i) => { 
  			if(id == 'all') {
  				if(check.checked) this.ng_manual_concept.usuarios.push(u.id);
  			} else {
  				if(u.id == id) {
  					if(check.checked) {
  						this.ng_manual_concept.usuarios.push(id);
  					} else {
  						let indx = this.ng_manual_concept.usuarios.findIndex(mcid => mcid == u.id);
  						this.ng_manual_concept.usuarios.splice(indx, 1);
  					}
  				}
  			}  			
  		});		
  	}

  	ng_change_user(user){
  		this.ng_selected_user = null;
		this.ng_select_user(user.id, {checked : true});
	}

  	move_dist(direction) {
  		let indx = this.ng_dist_index;
  		if(direction == 'prev') indx--; 
  		if(direction == 'next') indx++;
  		console.log(indx);
  		if(indx >= 0 && indx < this.ng_dist.length) {
  			this.ng_dist_index = indx; 
  			this.ng_current_dist = this.ng_dist[this.ng_dist_index];	  	
	  	}	
  	}


  	search_vac() { 
        let uri = '/concepto-manual/vacaciones?id_corte='+this.ng_id_closure+'&adelantada='+this.ng_future_vacs;
        this.btn_2_3.isLoading = true;
        this.btn_2_3.disabled = true; 
        this.ng_list_vacations = [];	
        this.ng_txt_pool = [];	
        this.httpService.getHttpAuth(uri).subscribe(x => {
        	this.btn_2_3.isLoading = false;
        	this.btn_2_3.disabled = false; 
        	this.change_txt_file(null, false); // cleans the user select options
        	for(let i = 0; i < x.data.length; i++) {
        		this.ng_list_vacations.push({
        			id : x.data[i].id,	
        			nombre : x.data[i].nombre,
        			fecha_inicio :  x.data[i].fecha_inicio,
        			fecha_fin :  x.data[i].fecha_fin,
        			concepto :  x.data[i].concepto,
        		});
        		this.ng_txt_pool.push(x.data[i].txt);        		
        	}         	       	
        }, error => {
        	this.btn_2_3.isLoading = false;
        	this.btn_2_3.disabled = false; 
        });
   	}

   	search() {   	
        let uri = '?id_concepto='+this.ng_filters.id_concepto;  
        this.isLoadingTab = true;
  		this.btn_1_2.isLoading = true;
  		this.btn_1_2.disabled = true; 		
        this.httpService.getHttpAuth("/concepto-manual/get/all"+uri).subscribe(x => {
            this.ng_list_manual_concepts = [];
        	this.ng_list_manual_concepts = x.data;
        	this.isLoadingTab = false;
  			this.btn_1_2.isLoading = false;
  			this.btn_1_2.disabled = false; 
  			this.rerender();		
        });
   	}

   	save_concept_request(){  	
  		if(this.ng_manual_concept.usuarios.length == 0) {
  			this.alertService.info("No hay usuarios a quienes aplicar éste concepto");
  			return;
  		}
  		if(this.ng_manual_concept.id_concepto == 0) {  		
  			this.alertService.info("Seleccione un concepto"); 
  			return;
  		}  		
  		this.btn_2_2.isLoading =  true;
  		this.btn_2_2.disabled = true;
  		if(this.ng_manual_concept.modo==1) {
  			this.ng_manual_concept.fecha_inicio = moment(this.ng_start_date).format("YYYY-MM-DD");
  			this.ng_manual_concept.fecha_fin =  moment(this.ng_end_date).format("YYYY-MM-DD"); 
  		} else {
  			this.ng_manual_concept.fecha = moment(this.ng_date).format("YYYY-MM-DD");  			
  		} 	
    	this.httpService.postHttpAuth("/concepto-manual", this.ng_manual_concept).subscribe(x => {
    		this.btn_2_2.isLoading =  false;
  			this.btn_2_2.disabled = false;
  			this.alertService.success("Código guardado existosamente");
  			this.ng_modals.concept.dismiss();
  			this.search();
    	}, err =>  {
    		this.btn_2_2.isLoading =  false;
  			this.btn_2_2.disabled = false;
    	});
  	}

  	delete(id){
  		this.alertService.success("Concepto eliminado exitosamente");
  		this.search();    	
  	}

  	change_mode(val) {  
  		this.ng_manual_concept.modo = (!val.checked) ?1 : 2; 
  		if(this.ng_manual_concept.modo==1) this.disableDates();
  	}

  	change_txt_file(id, check) {
  		if(id == null){
  			if(check.checked){
  				this.ng_txt = this.ng_txt_pool;
  				this.ng_all_checked_command = true;
  			} else {
  				this.ng_txt = [];
  				this.ng_all_checked_command = false; 
   			} 			
  		} else {
  			if(check.checked) {
  				let result = this.ng_txt_pool.filter(val => val.id == id);
  				if(result.length > 0) this.ng_txt.push(result[0]);  		 			
  			} else {  			
  				this.ng_txt = this.ng_txt.filter(val => val.id != id);  			
  			}  
  		}
  	}

  	reset() {	
		this.ng_manual_concept = {
			usuarios : [],
  			id_concepto : 0,  
  			modo : 1,
  			tiempo : 't', 		
  			fecha_inicio: "",
  			fecha_fin : "",
  			fecha : "",
  			libre: false,
  			comentario : ""
		};
	}

  	// button objects
   	btn_1_1 : any = { // create concept button
  		disabled : false,
  		type : "btn btn-default",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"",
  		text : "Agregar concepto",
  		onlyIcon : true,
  		isLoading : false,
  	};

  	btn_1_2 : any = { // search button		
  		type : "btn btn-primary",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-search",
  		text : "Búsqueda",
  		onlyIcon : true,
  		disabled : false,
  		isLoading : false,
  	};

  	btn_1_3 : any = { // table show details
  		disabled : false,
  		type : "btn btn-primary",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-search",
  		onlyIcon : true,
  		isLoading : false,
  	};
  	btn_1_4 : any = { // table delete button
  		disabled : false,
  		type : "btn btn-danger",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-trash",
  		onlyIcon : true,
  		isLoading : false,
  		delete: true
  	};
  	btn_2_2 : any = { // modal create concept button
  		disabled : false,
  		text : "Guardar",
  		type : "btn btn-primary",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-save",
  		onlyIcon : true,
  		isLoading : false,
  	};

  	btn_2_3 : any = { // search for vacations filtered results modal
  		disabled : false,
  		text : "Buscar",
  		type : "btn btn-primary",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-search",
  		onlyIcon : true,
  		isLoading : false,
  	}

  	btn_2_4 : any = {
  		disabled : false,
  		text : "Descargar archivo",
  		type : "btn btn-primary",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-download",
  		onlyIcon : true,
  		isLoading : false,
  	};
}