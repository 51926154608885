import { Component, OnInit,ViewChild } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import * as moment from "moment";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { DataTableDirective } from 'angular-datatables';
import { AgmCoreModule } from '@agm/core';
import { HttpService } from "@services/Http/http.service"

@Component({
  	selector: 'app-phone',
  	templateUrl: './phone.component.html',
  	styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
    lat: number = 9.010131;
    lng: number = -79.474144;  
    // datatables 
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dtTrigger: Subject<any> = new Subject();  	
  	dtOptions: any = {	   
	    language : {
	      	url : "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
	      	emptyTable : "No existen usuarios"
	    },
	    pageLength : 100     
	};
	markers: any = [];
	modal :any;
	isLoadingTab: boolean = true;

	// ng variables 
	ng_checks = [];
	ng_check = null;
	ng_check_send;
	ng_modal_title = '';
	ng_let_edit = false;
	ng_state = 'p';

	//buttons
	btn1 = {
		text:"",
      	disabled: false,
      	isLoading:false,
      	type:"btn btn-primary",
      	icon:"fa fa-spinner fa-spin",
      	principal_icon:"fa fa-search",
      	onlyIcon : true
      	
	};
	btn_save: any = {
      	text:"Guardar",
      	disabled: false,
      	type:"btn btn-primary",
      	icon:"fa fa-spinner fa-spin",
      	principal_icon:"fa fa-floppy-o",
      	isLoading:false,
      	onlyIcon : true
    };
    
    btn_search_options :any = {
      	text:"Buscar",
      	disabled: false,
      	type:"btn btn-primary",
      	icon:"fa fa-spinner fa-spin",
      	principal_icon:"fa fa-search",
      	isLoading:false,
      	onlyIcon : true
    };

  	constructor(private httpService: HttpService,private alertService: AlertService) { }

  	ngOnInit() {
  		this.search();
    	//this.loadChecks(1);
  	}

  	ngAfterViewInit(): void {
    	this.dtTrigger.next();
  	}
  	
  	search() : void {
  	    this.btn_search_options.isLoading = true;
  	    this.btn_search_options.disabled = true
  	    this.isLoadingTab=true;  
  	    this.httpService.getHttpAuth("/marcacion-detalle/get/all?estado="+this.ng_state).subscribe(x =>{
    		this.ng_checks = [];
       		this.isLoadingTab=false;
       		this.btn_search_options.isLoading = false;
  	        this.btn_search_options.disabled = false
       		this.ng_checks = x.data;       		 
       		this.rerender();   		
     	});
  	}

  	modalIsReady(modal) {
  		this.modal = modal;
  	}

  	dismiss() {
  		this.modal.dismiss();
  	}

  	open(check){
  		this.reset();
  		this.btn1.isLoading = true;
  		this.btn1.disabled = true;
  		this.ng_modal_title = 'Marcación Móvil de '+check.nombre;
  		this.ng_check = check;
  		this.ng_check_send.id_marcacion_detalle = check.id;
  		this.markers = [{ lat: check.latitud, lng: check.longitud, label : check.nombre+' '+check.fecha }];
  		var codigo = 'p';
  		if(check.estado == 'Pendiente') codigo = 'p';
  		if(check.estado == 'Rechazado') codigo = 'r';
  		if(check.estado == 'Aprobado') codigo = 'a';
  		if(check.estado =='Pendiente') {
  			this.ng_let_edit = true;  			
  		}else{
  			this.ng_check_send.nota_admin = check.nota_admin;
  			this.ng_check_send.codigo = codigo;
  		}
  		this.btn1.isLoading = false;
  		this.btn1.disabled = false;
  		this.modal.open(); 
	}

  	save(){
  		this.btn_save.isLoading = true;
  		this.btn_save.disabled = true;
  		if(this.ng_check_send.codigo=='a') this.ng_check_send.nota_admin = '';	    
	    this.httpService.postHttpAuth("/marcacion-detalle/aprobar",this.ng_check_send).subscribe(x => {
	    	this.alertService.info("Estado de la marcación modificado exitosamente");
	      	this.btn_save.disabled =false;
	      	this.btn_save.isLoading =false;	      	
	      	this.modal.dismiss();     
	      	this.search();
	    }, error => {
	    	this.btn_save.disabled =false;
	      	this.btn_save.isLoading =false;	      	
	    });
  	}

  	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      		// Destroy the table first
      		dtInstance.destroy();
      		// Call the dtTrigger to rerender again
      		this.dtTrigger.next();
    	});
  	}

  	reset() {
  		this.ng_let_edit = false;
  		this.ng_check = null;
  		this.ng_check_send = {id_marcacion_detalle : null,	codigo : 'a', nota_admin : ''};
  	}
}