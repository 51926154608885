import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

//import { RequestOptions, ResponseContentType, Http } from '@angular/http';
import {Observable, throwError, Subscriber} from "rxjs"
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AlertService } from 'ngx-alerts'
import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from "@angular/router";
@Injectable({
  providedIn: 'root'
})


export class HttpService {

    constructor(private router:Router, public alertService: AlertService, private httpClient: HttpClient) { }

    base_api: string = environment.url_api;
    headers: any = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}}
    options: any;

    downloadHttp(url_parameter : string) : Observable <any> { 
        return this.httpClient.get(this.base_api+url_parameter, {
            headers : {
                'Authorization' : "Bearer "+ localStorage.getItem("token")
            },
            responseType : 'blob' as 'json'
        }).pipe(catchError(this.handleError.bind(this)));
    }


    getHttp(url_parameter: string, options?:any): Observable<any>{
        if(options) this.options=options;
        return this.httpClient.get(this.base_api+url_parameter).pipe(catchError(this.handleError.bind(this)));
    }

    getHttpAuth(url_parameter: string, options?:any): Observable<any>{
        if(options) this.options=options;
        var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
        return this.httpClient.get(this.base_api+url_parameter,headers)
            .pipe(
                catchError(
                    this.handleError.bind(this)
                )
            );
    }

    postHttpAuth(url_parameter: string, parameters: any, options?:any): Observable<any>{
        if(options) this.options=options;

        var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
        return this.httpClient.post(this.base_api+url_parameter,parameters,headers)
            .pipe(
                catchError(
                    this.handleError.bind(this)
                )
            );
    }

    postHttp(url_parameter: string, parameters: any,options?:any): Observable<any>{

        if(options) this.options=options;

        return this.httpClient.post(this.base_api+url_parameter,parameters)
            .pipe(
                catchError(
                    this.handleError.bind(this)
                )
            );
    }

    deleteHttpAuth(url_parameter: string, parameters: any): Observable<any>{
        var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
        return this.httpClient.delete(this.base_api+url_parameter,headers)
            .pipe(
                catchError(
                    this.handleError.bind(this)
                )
            );
    }

    putHttpAuth(url_parameter: string, parameters: any,options?:any): Observable<any>{
        if(options) this.options=options;
        var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
        return this.httpClient.put(this.base_api+url_parameter,parameters,headers)
            .pipe(
                catchError(
                    this.handleError.bind(this)
                )
            );
    }


    async asyncHttpAuth(method:string, url_parameter: string, parameters?: any, options?:any) {
        if(options) this.options=options;
        var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
        if(method == 'GET') {
            return this.httpClient.get(this.base_api+url_parameter,headers).pipe(
                catchError(this.handleError.bind(this))
            ).toPromise();
        } else if(method == 'POST'){
            return this.httpClient.post(this.base_api+url_parameter,parameters,headers).pipe(
                catchError(this.handleError.bind(this))
            ).toPromise();
        } else if (method == 'PUT') {
            return this.httpClient.put(this.base_api+url_parameter,parameters,headers).pipe(
                catchError(this.handleError.bind(this))
            ).toPromise();
        }   
    }




    handleError(error: HttpErrorResponse) {
        if(this.options){
            this.options.disabled=false;
            this.options.isLoading=false;
        }
        console.log(error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.alertService.danger('Ha ocurrido un error inesperado');
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,

            if(error.status==422){
                var arr=error.error.data.validation;
                var strErr="";
                for (let i = 0; i < arr.length; i++) {
                    this.alertService.danger(arr[i]);
                    strErr+=arr[i];
                }
                this.alertService.danger(strErr);
                console.log(strErr);
            }

            if(error.status==401){
                let  configJanusSuite = environment.suite_conf;
                let url = <any> configJanusSuite.janustime.janus_url;
                window.location.href = url;              
                this.alertService.danger(error.error.data.message);
                localStorage.removeItem("token")
                return;
            }

           	if(error.status==403){                           
                this.alertService.danger(error.error.data.message);
                this.router.navigate(['/dashboard'])
                return;
            }
        
            if(error.status==503){
                this.router.navigate(['/maintenance'])
                localStorage.removeItem("token")
                return;
            }

            if(error.status==400){            	
                this.alertService.danger(error.error.data.message);
            }
            console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);

            console.log(error);
        }
        // return an observable with a user-facing error message
        var errorText = '';
        if(error.status==400) {
            errorText = error.error.data.message;
        }else{
            errorText =  'Something bad happened; please try again later.';
        }
        return throwError(errorText);
    };
}