import {Directive, Component, Input, OnInit, Output, ElementRef, Renderer2, ViewChild, AfterViewInit} from '@angular/core';
import { ViewEncapsulation }  from '@angular/core'
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation : ViewEncapsulation.None
})

@Directive({ selector: '[modal]' })

export class ModalComponent implements OnInit, AfterViewInit {

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    @Input () title;
    @Input() icon;
    @Input() classbtn;  
    @Input() openModal;  
    @Output() modal : EventEmitter <any> = new EventEmitter<any>();
    @Input() modalSize? = "";
    @ViewChild('backdrop') backdrop : ElementRef;
    isOpen: boolean = false;   
    is_closed : boolean = true;
    body : any;
 
    ngAfterViewInit() { 
        let modalObject = {
            open : () => {  this.open(); }, 
            dismiss : () => { this.dismiss(); }
        };

        this.modal.emit(modalObject);   
    }

    ngOnInit () : void { 
        this.body = document.getElementsByTagName("body")[0];
    }

    open(): void{       
        this.body.classList.add('modal-open');       
        this.isOpen = true;
        this.is_closed = false;        
    }
  
    dismiss(){   
        this.body.classList.remove('modal-open');       
        this.isOpen = false;          
        setTimeout( x => { this.is_closed = true; }, 500);   
    }  
}