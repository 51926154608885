import { Component, OnInit, ViewChild,Input,Output,EventEmitter } from '@angular/core';
import { FormatHoursPipe } from "@pipes/format-hours.pipe"
import { AmazingTimePickerService } from 'amazing-time-picker'; // this line you need
import {HttpService} from '@services/Http/http.service';
import { ScheduleService } from '@services/globals/schedule.service';
import { SpecialSchedule } from '@app/globals/interfaces/special-schedule';
import { AlertService } from 'ngx-alerts';
import { Subject } from "rxjs/Subject"
import * as moment from "moment"

@Component({
  selector: 'app-special-schedule',
  templateUrl: './special-schedule.component.html',
  styleUrls: ['./special-schedule.component.scss']
})
export class SpecialScheduleComponent implements OnInit {
    
  	@ViewChild("modalSchedule") modalSchedule: any;  
  	@Input() canDelete?: boolean = false;  
  	@Input() open: Subject<any>;
  	@Output() onSaved = new EventEmitter();

  	scheduleMode: string = "schedule-template";

  	templates: any = [];
  	templates_detail : any = [];
 	special_schedule : SpecialSchedule;
 	ng_selected_template_detail : number = 0; 	
 	ng_default_date = new Date();
 	ng_start : any;
 	ng_end : any;
 	ng_user_label : string = '';
 	ng_date_label : string = '';
 	ng_max_working_time : 0;
 	ng_list_extra_minutes: any = [0,5,10,15,20,25,30];
 	formats : any = {a : 'hh:mm A', b : 'HH:mm'};
 	ng_list_resttime : any = [
 		{value: 0, text: '0 Minutos'},
 		{value: 15, text:'15 Minutos'},
 		{value: 30, text:'30 Minutos'},
 		{value: 45, text : '45 Minutos'},
 		{value: 60, text : '1 Hora'},
 		{value: 75, text : '1 Hora y 15 Minutos'},
 		{value : 90, text : '1 Hora y 30 Minutos'},
 		{value : 120, text : '2 Horas'},
 		{value : 150, text : '2 Horas y 30 Minutes'},
 		{value : 180, text : '3 Horas'},
 		{value : 210, text : '3 Horas y 30 Minutes'},
 		{value : 240, text : '4 Horas'},
 		{value : 270, text : '4 Horas y 30 Minutes'},
 		{value : 300, text : '5 Horas'},
 		{value : 330, text : '5 Horas y 20 Minutes'},
 		{value : 360, text : '6 Horas'}
 	];
 	// buttons
 	btn_close = {
 		disabled : false,
 		isLoading : false,
  		type : "btn btn-default",
  		icon : "fa fa-spinner fa-spin",
  		text : 'Cerrar' 		
 	};
 	btn_delete = {
 		disabled : false,
 		isLoading : false,
  		type : "btn btn-danger",
  		icon : "fa fa-spinner fa-spin",
  		text : 'Eliminar' 		
 	};
 	btn_save = {
 		disabled : false,
 		isLoading : false,
  		type : "btn btn-primary",
  		icon : "fa fa-spinner fa-spin",
  		text : 'Guardar' 		
 	};

 	

  	constructor(
  		private formatHoursPipe: FormatHoursPipe,
        private atp: AmazingTimePickerService,
        private alertService: AlertService,
        private httpService: HttpService,
        private scheduleService: ScheduleService) { }
  
  	ngOnInit() {
  		this.reset();
      	this.httpService.getHttpAuth("/plantilla/get/all").subscribe(x => {
      		this.httpService.getHttpAuth("/jornada").subscribe(hours => { this.ng_max_working_time = hours; });
       		this.templates = x.data;       			
     	});
  	}

  	reset() : void {  		
  		this.ng_selected_template_detail = 0;
  		this.ng_start = "00:00";
  		this.ng_end = "00:00";  		
  		this.special_schedule = {
  			tipo_horario : "manual",
  			id : null,
			id_usuario : null,
			id_plantilla_detalle : null,
			fecha : moment(this.ng_default_date).format('YYYY-MM-DD'), 
			hora_inicio : "00:00",
			hora_fin : "00:00",
			minutos_extra : 5,			
			libre : false,		
			descanso : 60	
  		};
  	}
  
  	clickOnTimePicker(type) {
  		let formatted_time = '00:00';
  		if(type == 'start') formatted_time = moment(this.ng_start, 'hhmmA').format(this.formats.b); 
  		else formatted_time = moment(this.ng_end, 'hhmmA').format(this.formats.b);   		
    	const amazingTimePicker = this.atp.open({ time: formatted_time});
    	amazingTimePicker.afterClose().subscribe(time => { 
    		let formatAMPM = moment(time, this.formats.b).format(this.formats.a); 	
      		if(type=='start') {	
      			this.ng_start = formatAMPM;	
      			//this.special_schedule.hora_inicio = time;
      		} else { 
      			this.ng_end = formatAMPM; 
      			//this.special_schedule.hora_fin = time; 
      		}       		   		
    	});
  	}
  
  	openModal(data):void {  		
  		this.ng_user_label = '';
  		this.ng_date_label = moment(data.fecha).format("DD-MM-YYYY");  		
  		this.ng_start = moment(data.entrada, this.formats.b).format(this.formats.a);
  		this.ng_end = moment(data.salida, this.formats.b).format(this.formats.a);
  		console.log(data);
  		console.log(this.ng_start);
  		console.log(this.ng_end);
      	this.special_schedule.id = data.id;
      	this.special_schedule.id_usuario = data.id_usuario;
      	this.special_schedule.fecha = moment(data.fecha).format("YYYY-MM-DD");
      	this.special_schedule.hora_inicio = data.entrada; 
      	this.special_schedule.hora_fin =  data.salida; 
      	this.special_schedule.minutos_extra = data.minutos_extra;
      	this.special_schedule.libre = data.libre
       	this.special_schedule.descanso = data.descanso;      	
      	this.scheduleMode="schedule-manual";      	
      	if(data.user) this.ng_user_label = data.user.nombre;      
	    this.modalSchedule.open();
  	}
  
  	close(){
      	this.modalSchedule.dismiss();
  	}
  
  	delete(){      	
  		this.btn_delete.disabled = true;   
  		this.btn_save.disabled = true;
  		this.btn_close.disabled = true;
  		this.btn_delete.isLoading = true;
      	this.httpService.deleteHttpAuth("/horario-especial/"+this.special_schedule.id,{}).subscribe(x => {
          	this.btn_delete.disabled = false;   
  			this.btn_save.disabled = false;
  			this.btn_close.disabled = false;
  			this.btn_delete.isLoading = false;
          	this.onSaved.emit(x);
          	this.modalSchedule.dismiss();
      	},err => {
      		this.btn_delete.disabled = false;   
  			this.btn_save.disabled = false;
  			this.btn_close.disabled = false;
  			this.btn_delete.isLoading = false;
      	});
  	}

  	change_template_details(id) {
  		this.templates_detail = [];  
  		this.ng_selected_template_detail = 0;
  		if(id == 0) return;  				 	
  		let template = this.templates.filter((item, i) => { return id == item.id })[0];
  		this.templates_detail = template.plantilla_detalle;  	
  	}
  
  	save() {  	
  		this.btn_close.disabled = true;
  		this.btn_delete.disabled = true;   
  		this.btn_save.disabled = true;
  		this.btn_save.isLoading = true;
      	if(this.scheduleMode=="schedule-manual") {
        	if(!this.special_schedule.libre){
        		let start = moment(this.ng_start, this.formats.a);
        		let end = moment(this.ng_end, this.formats.a);        		
          		let validator = this.scheduleService.validateMaxTime(
          			start, end, this.ng_max_working_time, this.special_schedule.descanso);         
          		if(!validator){
          			let msg = 'El horario seleccionado no es valido, valida que el horario sea igual o menor a ';
          			msg += this.ng_max_working_time+' horas incluyendo el tiempo de descanso.';
            		this.alertService.danger(msg);
            		this.btn_close.disabled = false;
  					this.btn_delete.disabled = false;   
  					this.btn_save.disabled = false;
  					this.btn_save.isLoading = false;
            		return 0;
          		}
        	}
        	this.special_schedule.tipo_horario = 'manual';
        	this.special_schedule.hora_inicio = moment(this.ng_start, this.formats.a).format(this.formats.b); 
        	this.special_schedule.hora_fin =  moment(this.ng_end, this.formats.a).format(this.formats.b);      	
      	}else{        
        	if(this.ng_selected_template_detail == 0)  {
            	this.alertService.info("Debes seleccionar un horario valido");
            	return 0;
        	}          
        	let detail = this.templates_detail.filter((item, i)=> item.id == this.ng_selected_template_detail)[0];        	
        	//this.special_schedule.hora_inicio = (detail.libre ? "00:00" : detail.hora_entrada);
          	//this.special_schedule.hora_fin = (detail.libre ? "00:00" : detail.hora_salida);
          	this.special_schedule.id_plantilla_detalle = detail.id;
          	this.special_schedule.descanso = detail.descanso;   
          	this.special_schedule.tipo_horario = 'plantilla'; 
          	this.special_schedule.hora_inicio = moment(this.ng_start, this.formats.a).format(this.formats.b); 
        	this.special_schedule.hora_fin =  moment(this.ng_end, this.formats.a).format(this.formats.b);      	      
      	}
 
      	this.httpService.postHttpAuth("/horario-especial", this.special_schedule).subscribe(x => {
	      	this.alertService.success("Horario agregado correctamente");
      		this.btn_close.disabled = false;
  			this.btn_delete.disabled = false;   
  			this.btn_save.disabled = false;
  			this.btn_save.isLoading = false;
	      	this.modalSchedule.dismiss();
	      	this.onSaved.emit(x);      		
	    },err=>{
	    	this.btn_close.disabled = false;
  			this.btn_delete.disabled = false;   
  			this.btn_save.disabled = false;
  			this.btn_save.isLoading = false;	     
	    });
  	} 	
}
