import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {HttpService} from './../../services/Http/http.service';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private ar: ActivatedRoute,private http:HttpService,private router:Router) { }

  ngOnInit() {
    this.ar.fragment.subscribe((fragment: string) => {
        console.log(fragment);
        // localStorage.removeItem("token");
        if(!fragment) {
            this.router.navigate(["/login"]);
        }
        localStorage.setItem("token",fragment.split("=").pop());
        this.http.postHttpAuth("/auth/token",{})
        .subscribe(x =>{
        	let auth = x.data;
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("permissions");
            localStorage.removeItem("grupo");
            localStorage.setItem("token",auth.token);
            localStorage.setItem("user",JSON.stringify(auth.user));
            localStorage.setItem("permissions",JSON.stringify(auth.permissions));
            localStorage.setItem("grupo",JSON.stringify(auth.grupo));
            this.router.navigate(["dashboard"]);
        });
    })  
  }

}
