import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { Subject } from 'rxjs';
import { ModalConfirmComponent } from "@html/modals/modal-confirm/modal-confirm.component"

@Component({
  	selector: 'app-company',
  	templateUrl: './company.component.html',
  	styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

   	constructor(private http: HttpService, private  alertService: AlertService) { }

    @ViewChild(DataTableDirective)    
    dtElement: DataTableDirective;
    dtTrigger: Subject<any> = new Subject();
    dtOptions: any = {}; 
    modal1 : any;
    modal2 : any;
    grploader : any = JSON.parse(localStorage.getItem('grupo'));
    branches : object = [];
    departments : object = [];
    divisions : object = [];
    cost_centers : object = []; 

    // ng variables that affect the angular html
    datasTable: any=[];
    ng_current_grp_label : string = '';
    ng_selected_branch : number = 0;
    ng_selected_department : number = 0;
    ng_selected_division : number = 0;
    ng_selected_cost_center : number = 0;
    ng_grp_name : string = '';
    ng_is_saving : boolean =  true;
    ng_delete_id = null;
    live_feed_group : number =  2; // 2 is branch
    grps_labels : object = [null, null, 'Sucursales', 'Divisiones', 'Departamentos', 'Centros de Costo'];
    grps : any = [null, 'empresa', 'sucursal', 'departamento', 'division', 'centro-costo'];
    breadcrumbs : any = [        
        null,
        {'id' : null, 'name' : ''},
        {'id' : null, 'name' : ''},
        {'id' : null, 'name' : ''},
        {'id' : null, 'name' : ''},
        {'id' : null, 'name' : ''}
    ];
    table_buttons : any = {
    	view: {
    		disabled : false,
    		isLoading : false,
	  		type : "btn btn-success",
	  		icon : "fa fa-spinner fa-spin",
	  		principal_icon:"fa fa-search",
	  		onlyIcon : true,
	  		
    	},
    	edit : {
    		disabled : false,
    		isLoading : false,
	  		type : "btn btn-success",
	  		icon : "fa fa-spinner fa-spin",
	  		principal_icon:"fa fa-pencil",
	  		onlyIcon : true,
    	},
    	delete : {
    		disabled : false,
    		isLoading : false,
	  		type : "btn btn-danger",
	  		icon : "fa fa-spinner fa-spin",
	  		principal_icon:"fa fa-trash",
	  		onlyIcon : true  		
    	}
    };
    title_modal1 : string = 'Crear ';
    title_modal2 : string = '';
    body : any = {id : 0, nombre : '', grupo : ''}; 
    open_confirm_modal = new Subject(); 

    ngOnInit() {
        // set company breadcrumb
        this.breadcrumbs[1] = {id : this.grploader.grupos.empresa.id, name : this.grploader.grupos.empresa.nombre };
        this.http.getHttpAuth("/grupo/get/"+this.grploader.grupos.empresa.id+"?grupo=sucursal").subscribe(data => {
            this.live_feed_group = 2; 
            this.ng_current_grp_label = this.grps_labels[2];            
            this.datasTable = data.data; 
            this.rerender();
        }); 
    }

    look(id, name) : void {      
        let grp = [null, null, 'sucursal', 'departamento', 'division', 'centro-costo'];
        let temp_live_feed_group = this.live_feed_group + 1;

        this.http.getHttpAuth("/grupo/get/"+id+"?grupo="+grp[temp_live_feed_group]).subscribe(data => { 
            let res = data.data;
            this.breadcrumbs[this.live_feed_group] = {id : id, name : name };            
            this.live_feed_group = temp_live_feed_group;  
            if(res.length == 0){               
                this.ng_current_grp_label = this.grps_labels[this.live_feed_group];
                this.datasTable = [];              
            } else {
                this.ng_current_grp_label = this.grps_labels[this.live_feed_group];
                this.datasTable = res;
            }           

            this.rerender();          
        });        
    }

    getByChild(parent, tipo, id) : void {         
        if(id == 0) {
            if(tipo == 'departamento'){
                this.ng_selected_division = 0;
                this.ng_selected_department = 0;
                this.departments = [];
                this.divisions = [];  
            }
            if(tipo == 'division') { this.ng_selected_division = 0; this.divisions = []; }            
        }else{
            this.http.getHttpAuth("/grupo/get/"+id+"?grupo="+tipo).subscribe(data => {                           
                if(tipo == 'departamento') { this.departments = data.data; this.ng_selected_division = 0  } 
                if(tipo == 'division') { this.divisions = data.data; }                         
            });   
        }             
    }


    showHierarchy(i){
        this.live_feed_group = i;
        for(let x = 0; x < this.breadcrumbs.length; x++) {
            if(x > i){
                this.breadcrumbs[x] = {id : null, name : ''};
            }
        }
        this.look(this.breadcrumbs[i].id, this.breadcrumbs[i].name);
    }

    open_create_md() {
        let grp = [null, null, 'sucursal', 'departamento', 'division', 'centro-costo'];
        let temp_live_feed_group = this.live_feed_group - 1; 
        let curr_ = this.breadcrumbs[temp_live_feed_group];
        this.title_modal1 = 'Crear ' + this.grps_labels[this.live_feed_group];
        this.ng_is_saving = false;
        this.http.getHttpAuth("/grupo/get/tree?id_grupo="+curr_.id+"&id_tipo_grupo="+temp_live_feed_group).subscribe(
            data => {  
            let groups = data.data.grupos;
            let sel = data.data.seleccionados;

            this.branches = groups.sucursal;
            this.ng_selected_branch = sel.sucursal;
           
            if(temp_live_feed_group >= 3){ 
                this.ng_selected_department = sel.departamento; 
                this.departments = groups.departamento;           
            }
            if(temp_live_feed_group >= 4){ 
                this.ng_selected_division = sel.division;
                this.divisions = groups.division;
            }
            if(temp_live_feed_group == 5){ 
                this.ng_selected_branch = this.breadcrumbs[2].id;
                this.ng_selected_department = this.breadcrumbs[3].id;
                this.ng_selected_division = this.breadcrumbs[3].id;                
            }           
            //this.modal1.next();
            this.modal1.open();
        });       
    }

    open_update_md(id, name) { 
        let temp_live_feed_group = this.live_feed_group - 2;
        temp_live_feed_group = (temp_live_feed_group <= 1) ? 1 : temp_live_feed_group;        
        this.title_modal2 = 'Editar '+this.grps_labels[this.live_feed_group];
        this.body.id = id;       
        this.body.nombre = name;
        this.body.grupo = this.grps[this.live_feed_group];
        this.ng_is_saving = false;
        this.table_buttons.edit.isLoading = true;
        this.table_buttons.edit.disabled = true;
        let uri = "/grupo/get/"+this.breadcrumbs[temp_live_feed_group].id+"?grupo="+this.grps[temp_live_feed_group+1];
        this.http.getHttpAuth(uri).subscribe(data => {                           
            if(temp_live_feed_group == 1) { // codigo para traer sucursales. 
                this.branches = data.data; 
                this.ng_selected_branch = this.breadcrumbs[2].id;  
            } 
            if(temp_live_feed_group == 2) { // codigo para traer departamentos
                this.departments = data.data;
                this.ng_selected_department = this.breadcrumbs[3].id;
            }
              if(temp_live_feed_group == 3) { // codigo para traer departamentos
                this.divisions = data.data;
                this.ng_selected_division = this.breadcrumbs[4].id;
            }            
            this.table_buttons.edit.isLoading = false;
        	this.table_buttons.edit.disabled = false; 
            this.modal2.open();                      
        });       
    }

    create() : void {
        this.ng_is_saving = true;
        if(this.live_feed_group == 5) this.body.id_division = this.ng_selected_division; 
        if(this.live_feed_group == 4) this.body.id_departamento = this.ng_selected_department; 
        if(this.live_feed_group == 3) this.body.id_sucursal = this.ng_selected_branch; 
        if(this.live_feed_group == 2) this.body.id_empresa = this.grploader.grupos.empresa.id;
        this.body.grupo = this.grps[this.live_feed_group];     
        this.http.postHttpAuth('/grupo', this.body).subscribe( data => {
            this.modal1.dismiss();
            this.ng_is_saving = false;
            this.request_ready('creado');        
        }, error => {
            this.ng_is_saving = false;
        });
    }

    edit() : void {
         this.ng_is_saving = true;
        if(this.live_feed_group == 5) this.body.id_division = this.ng_selected_division; 
        if(this.live_feed_group == 4) this.body.id_departamento = this.ng_selected_department; 
        if(this.live_feed_group == 3) this.body.id_sucursal = this.ng_selected_branch;      
        this.http.putHttpAuth('/grupo/'+this.body.id, this.body).subscribe( data => {
            this.modal2.dismiss();
            this.ng_is_saving = false;
            this.request_ready('modificado');           
        }, error =>{
            this.ng_is_saving = false;
        });
    }

    delete(id, confirm) : void {      	
    	if(id) {
    		this.ng_delete_id = id;    		
    		this.open_confirm_modal.next();
    	} else {
    		if(confirm) {
    			this.http.deleteHttpAuth('/grupo/'+this.ng_delete_id+'?grupo='+this.grps[this.live_feed_group], {})
    				.subscribe(data => {  
    				this.ng_delete_id = null;    			
    				this.alertService.success("Concepto eliminado exitosamente");              
                	this.request_ready('eliminado');         
            	}, error =>{
            		this.ng_delete_id = null;            		
            	});
    		} else {
    			this.ng_delete_id = null;    		
    		}
    	} 
    }

    request_ready(a) : void  {
        this.body = {id : 0, nombre : '', grupo : ''};      
        this.live_feed_group -= 1;
        this.alertService.success('Grupo '+a+' exitosamente'); 
        this.look(this.breadcrumbs[this.live_feed_group].id, this.breadcrumbs[this.live_feed_group].name);       
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }


    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }
    get_modal_create(m) : void {
        this.modal1 = m;
    }
    get_modal_update(m) : void {
        this.modal2 = m;
    }

    resetFormModal(){ }

}
