import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from "./services/Http/http.service"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { DataTablesModule } from 'angular-datatables';
import { AlertModule } from 'ngx-alerts';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxLoadingModule } from 'ngx-loading';
import {NgxMaskModule} from 'ngx-mask'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { IgxDatePickerModule } from 'igniteui-angular';
import { CalendarModule } from 'angular-calendar';
import { ContentLoaderModule } from '@netbasal/content-loader';
import { AgmCoreModule } from '@agm/core';
import { FormatHoursPipe } from "@pipes/format-hours.pipe"
import { NgSelectModule } from '@ng-select/ng-select';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
//import { ToastModule } from 'ng-mdb-pro/pro/alerts';
import { DragulaModule } from 'ng2-dragula';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { AppComponent } from './app.component';
import {AppRoutingModule } from "./app-routing.module"
//import { DashboardComponent } from './dashboard/dashboard.component';
//import { InfoComponent } from './dashboard/info/info.component';

import { LoginComponent } from './pages/login/login.component';
import { routes } from "./routes/routes";
import { CompaniesComponent } from './companies/companies.component';
import { SharedService } from "./services/eventsEmmiter/shared.service";
import { HolidaysComponent } from './pages/configuration/holidays/holidays.component';
//import { RequestsComponent } from './users/requests/requests.component';
import { DatePipe } from './globals/pipes/date.pipe';
//import { MarksComponent } from './users/marks/marks.component';
//import { ChecksComponent } from './users/checks_new/checks.component'
//import { MarksPhoneComponent } from './users/marks/marks-phone/marks-phone.component';
import {Angular2PromiseButtonModule} from 'angular2-promise-buttons/dist';
import { ContentLoaderChecksComponent } from './globals/content-loader-checks/content-loader-checks.component';
import { HoursPipe } from './globals/pipes/hours.pipe';
import { FiltersCompaniesComponent } from './globals/filters/companies/companies.component'
import { ButtonLoaderComponent } from './html/button-loader/button-loader.component';
import { ParseNumberPipe } from './globals/pipes/parse-number.pipe';
import { ModalComponent } from './html/modals/modal/modal.component';
import { ModalDistributionComponent } from './html/modals/modal-distribution/modal-distribution.component';
import { ModalConfirmComponent } from './html/modals/modal-confirm/modal-confirm.component';
import { DomService } from "@html/modals/modal-confirm/modal-service";
import { HexagonComponent } from "./html/hexagon/hexagon.component";
import { WizardComponent } from './html/dsteps/wizard.component'
import { WizardStepComponent } from './html/dsteps/wizard-step.component';
import { GaugesComponent } from './html/gauges/gauges.component'
import { GaugeModule } from 'angular-gauge';
import localeEs from '@angular/common/locales/es';
import { MaintenancePageComponent } from './pages/login/maintenance/maintenance-page.component';
import { PasswordComponent } from '@pages/login/password/password.component';
import { DayPipePipe } from './globals/pipes/day-pipe.pipe';
import { ScheduleWarningComponent } from './html/schedule-warning/schedule-warning.component';
import { HierarchiesPipe } from './globals/pipes/hierarchies.pipe';
import { SpecialScheduleComponent } from './html/special-schedule/special-schedule.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { AuthComponent } from './globals/auth/auth.component';
import { ManualTimeDirective } from './globals/directives/manual-time/manual-time.directive';
import { UsersEditComponent } from './html/users-edit/users-edit.component';
// local components
import { UsersComponent } from '@pages/users/users.component';
import { UsersDetailComponentComponent } from '@pages/users/users-detail-component/users-detail-component.component';
import { ScheduleComponent } from '@pages/schedules/schedule/schedule.component';
import { MonthlyScheduleComponent } from '@pages/schedules/monthly-schedule/monthly-schedule.component';
import { CardsComponent } from '@pages/cards/cards.component';
import { CardsDetailComponent } from '@pages/cards/cards-detail/cards-detail.component';
import { LettersComponent } from './pages/letters/letters.component';
import { CompanyComponent } from './pages/configuration/company/company.component';
import { TemplateComponent } from './pages/configuration/template/template.component';
import { CardsDetailHoursComponent } from './pages/cards/cards-detail-hours/cards-detail-hours.component';

// reports components 
import { DistributionComponent } from '@pages/reports/distribution/distribution.component';
import { SpecialComponent } from '@pages/reports/special_days/special.component';
import { MissingComponent } from '@pages/reports/missing/missing.component';
import { ExtraHoursComponent } from '@pages/reports/extra-hours/extra-hours.component';
import { ExtraApprovesComponent } from '@pages/reports/extra-approves/extra-approves.component';
import { SummaryComponent } from '@pages/reports/summary/summary.component';
import { FundsComponent } from './pages/reports/funds/funds.component';
import { PunchesComponent } from './pages/reports/punches/punches.component';
import { MainComponent } from './pages/main/main.component';
import { SidebarComponent } from './pages/main/sidebar/sidebar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CalendarComponent } from './html/calendar/calendar.component';
import { PhoneComponent } from './pages/punches/phone/phone.component';
import { PeriodComponent } from './pages/reports/period/period.component';
import { ConceptsComponent } from './pages/cards/concepts/concepts.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { RequestsApproveComponent } from './pages/login/requests-approve/requests-approve.component';
import { DchainTimePickerComponent } from './html/dchain-time-picker/dchain-time-picker.component';
import { IncidentsComponent } from './pages/reports/incidents/incidents.component';
import { CommentsComponent } from './pages/reports/comments/comments.component';
import {
    SocialLoginModule,
    AuthServiceConfig,
    GoogleLoginProvider,
    FacebookLoginProvider,
} from "angular-6-social-login";
import { ClockComponent } from './pages/configuration/clock/clock.component';
import { DetailedComponent } from './pages/reports/detailed/detailed.component';
import { MonitorComponent } from './pages/reports/monitor/monitor.component';
registerLocaleData(localeEs);

// Configs 
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
      [        
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider("872745188310-fiuu31kmb9peskfl27u2f2v30qmal9r6.apps.googleusercontent.com")
        }          
      ]
  );
  return config; 
}


@NgModule({
  declarations: [
    AppComponent,
    //DashboardComponent,
    //InfoComponent,
    UsersComponent,
    LoginComponent,
    CompaniesComponent,
    UsersDetailComponentComponent,
    ScheduleComponent,
    HolidaysComponent,
    CardsComponent,
    CardsDetailComponent,
    CardsDetailHoursComponent,
    //RequestsComponent,
    DatePipe,
    //MarksComponent,
    //MarksPhoneComponent,
    ContentLoaderChecksComponent,
    HoursPipe,
    FormatHoursPipe,
    ButtonLoaderComponent,
    ParseNumberPipe,
    ModalComponent,
    ModalConfirmComponent,
    ModalDistributionComponent,
    HexagonComponent,
    WizardComponent,
    WizardStepComponent,
    MissingComponent,  
    ExtraHoursComponent,
    GaugesComponent,
    ExtraApprovesComponent,   
    DistributionComponent,
    FiltersCompaniesComponent,
    MaintenancePageComponent,
    //ChecksComponent,
    SpecialComponent,
    PasswordComponent,
    DayPipePipe,
    ScheduleWarningComponent,
    HierarchiesPipe,
    MonthlyScheduleComponent,
    SpecialScheduleComponent,
    AuthComponent,
    ManualTimeDirective,
    UsersEditComponent,
    CompanyComponent,
    TemplateComponent,
    SummaryComponent,
    FundsComponent,
    PunchesComponent,
    MissingComponent,
    MainComponent,
    SidebarComponent,
    DashboardComponent,
    CalendarComponent,   
    PhoneComponent,
    PeriodComponent,
    ConceptsComponent,
    RequestsComponent,
    RequestsApproveComponent,
    DchainTimePickerComponent,
    IncidentsComponent,
    CommentsComponent,
    LettersComponent,
    ClockComponent,
    DetailedComponent,
    MonitorComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true}),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    AlertModule.forRoot({maxMessages: 5, timeout: 4000}),
    NgxSmartModalModule.forRoot(),
    NgbModule.forRoot(),
    DataTablesModule,
    AmazingTimePickerModule,
    IgxDatePickerModule,
    CalendarModule.forRoot(),
    ContentLoaderModule,
    AgmCoreModule.forRoot({
     apiKey: 'AIzaSyB07SSMEbwCR10kA4xdqJo5COUCs4Y3_B8'
   }),
   ScrollToModule.forRoot(),
   Angular2PromiseButtonModule.forRoot(),
   NgSelectModule,
   DragulaModule.forRoot(),
   GaugeModule.forRoot(),
   NgxLoadingModule.forRoot({}),
   NgxMaskModule.forRoot(),
   ColorPickerModule,
   SocialLoginModule
  ],
  providers: [
    SharedService,
    HoursPipe,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    FormatHoursPipe,DomService,
    {provide: LOCALE_ID, useValue: 'es'}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ ModalConfirmComponent ]
})
export class AppModule { }
