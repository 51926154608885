import { Component, OnInit, ViewChild } from '@angular/core';
import {HttpService} from '@services/Http/http.service';
import { GlobalsService } from '@services/globals/globals.service'
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import * as moment from "moment"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { DataTableDirective } from 'angular-datatables';
import { Request } from '@interfaces/request';
import 'twix';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {

 	constructor(private httpService: HttpService, private  alertService: AlertService, private gs: GlobalsService) { }

	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
	isLoadingTab: boolean = true;
	isApproving: boolean = false;
	hoursModal = new Subject();
	dtTrigger: Subject<any> = new Subject(); 
	dtOptions: any = {
		pagingType: 'full_numbers',     
		order : [],
		dom: 'Bfrtip',
    	buttons: [
        	{ extend: 'print', text: 'Imprimir', title: 'Reporte de fondos' },
        	{ extend: 'excel', title: 'Reporte de vacaciones' }
      	],
		language : {url : "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"},
		pageLength : 25     
	}; 	
  	reasons: any = []
  	requests: any = [];
  	concepts: any = [];
   	body : Request; // main object
  	emp_name : string = '';
  	requested_by : string = '';
  	auth = JSON.parse(localStorage.getItem("user"));    	
  	// ng variables
  	ng_status : string = 'p';
  	modal : any;
  	modal_title = '';
  	modal_mode = 'view';
  	ng_dates : any;
  	ng_vac_fund :any = 0;
  	ng_list_users = [];
  	ng_start_date = new Date();
  	ng_end_date = new Date();
  	start_hr : string = '';
  	end_hr : string = '';
  	ng_current_time_picker_element = 'from';
  	// buttons
  	ng_buttons = {
  		view : {
	    	disabled: false,
	    	onlyIcon: true,
	    	isLoading: false,
	    	type:"btn btn-primary",
	    	icon:"fa fa-spinner fa-spin",
	    	principal_icon : "fa fa-search"	 
  		},
  		create :  {
  			disabled: false,
	    	onlyIcon: false,
	    	isLoading: false,
	    	text: "Crear Solicitud",
	    	type:"btn btn-default btn-block",
	    	icon:"fa fa-spinner fa-spin",
	    	principal_icon : "fa fa-plus"	 
  		},
  		send : {
  			disabled: false,
	    	onlyIcon: false,
	    	isLoading: false,
	    	text: "Enviar Solicitud",
	    	type:"btn btn-primary",
	    	icon:"fa fa-spinner fa-spin",
	    	principal_icon : "fa fa-save"	 
  		},
  		save : {
  			disabled: false,
	    	onlyIcon: false,
	    	isLoading: false,
	    	text: "Guardar",
	    	type:"btn btn-primary",
	    	icon:"fa fa-spinner fa-spin",
	    	principal_icon : "fa fa-save"	 
  		}
  	};

  	async ngOnInit() {
  		
  		this.reset(); 
  		this.ng_buttons.create.disabled = true;  		
    	let data = <any> await this.httpService.asyncHttpAuth("GET", "/solicitud/get/conceptos"); 
    	let data2 = <any> await this.httpService.asyncHttpAuth("GET", "/solicitud/get/motivos");
    	this.concepts = data.data;
    	this.reasons = data2.data;    
    	this.getAllRequest();     		
  	}

  	modalIsReady(modal) : void {
  		this.modal = modal;
  	}

  	no_typing(event) {
  		event.preventDefault();  		
  		return false;
  	}

  	getAllRequest():void {
      	this.isLoadingTab=true; 
      	let uri = "/solicitud/get/all?estado="+this.ng_status;       
      	this.httpService.getHttpAuth(uri).subscribe(x => {
      		this.ng_buttons.create.disabled = false;
      		this.requests = [];
      		this.requests = x.data; 
      		this.isLoadingTab=false; 
      		this.rerender();
      	}, err=> this.isLoadingTab = false);
  	}  
  
  	open(id, type) {   
  		this.reset(); // reset requests body to default values. 		
    	if (type == 'new') { 
    		let user =  JSON.parse(localStorage.getItem('user')); 
    		let fondo_vac_min = user.fondo_vacaciones;
    		let dias = Math.floor((fondo_vac_min / 60) / 8);
    		let horas = Math.floor(fondo_vac_min / 60);

    		this.ng_vac_fund = dias + " días/" +  horas + " horas";
    		this.ng_buttons.create.disabled = true;
    		this.ng_buttons.create.isLoading = true; 		
    		this.modal_title = 'Crear Solicitud';
    		this.modal_mode = 'new';
    		if(this.auth.id_rol == 4) {    			
    			this.httpService.getHttpAuth("/usuario/vista?id_rol=5&id_estado=1",{}).subscribe(x => { 
					let tempy = [];						
					x.data.filter((user, i) => {					
						tempy.push({id : user.id_usuario,  nombre : user.codigo +' - '+user.nombre+' '+user.apellido});
					});  
					this.ng_list_users = tempy;					
        			this.ng_buttons.create.disabled = false;
    				this.ng_buttons.create.isLoading = false; 		       
        			this.modal.open();
				});
    		} else {
    			this.ng_buttons.create.disabled = false;
    			this.ng_buttons.create.isLoading = false; 	
    			this.body.id_usuario = this.auth.id;
    			this.modal.open();
    		}        	
    	} else {     	 	
    		this.ng_buttons.view.isLoading = true;
  			this.ng_buttons.view.disabled = true;     			
      		this.httpService.getHttpAuth("/solicitud/"+id).subscribe(x => { 
      			let request = x.data; 
      			this.emp_name = request.usuario;
      			this.requested_by = request.creado_por;    
      			this.ng_dates.start = moment(request.fecha_inicio).toDate();
				this.ng_dates.end = moment(request.fecha_fin).toDate(); 				
				let user =  JSON.parse(localStorage.getItem('user'));    		
    			let dias = Math.floor((request.fondo_vacaciones / 60) / 8)
    			let horas = Math.floor(request.fondo_vacaciones / 60);    			
    			this.ng_vac_fund =  dias + " días/" +  horas + " horas";    		
        		// body instance update with the current requests values        		
				this.body = {
					id : request.id,
		  			codigo : request.codigo,
		  			id_concepto : 0,
		  			id_motivo : x.data.id_motivo,
		  			fecha_inicio : x.data.fecha_inicio,
		  			fecha_fin : x.data.fecha_fin,
		  			razon: x.data.razon,
		  			nota : x.data.nota		  			
  				};
  				if(this.body.codigo == 'p'){
  					this.modal_title = (this.auth.id_rol == 3) ? 'Aprobar Solicitud' : 'Pendiente por Aprobación';
  					this.modal_mode =  (this.auth.id_rol == 3 || this.auth.id_rol == 4) ? 'waiting' : 'read-only';
  					if(this.auth.id_rol==3) this.body.codigo = 'a';
  				} else {
  					this.body.id_concepto = request.id_concepto;
  					this.modal_title = (this.body.codigo == 'r') ? 'Solicitud Rechazada' : 'Solicitud Aprobada';
  					this.modal_mode = 'read-only';
  				}
  				this.ng_buttons.view.isLoading = false;
  				this.ng_buttons.view.disabled = false;				
        		this.modal.open();
    		}, err => {
    		 	this.ng_buttons.view.isLoading = false;
  				this.ng_buttons.view.disabled = false;	
    		});
    	} 	
  	}

  	time_picker(el){
  		this.modal.dismiss();    	
    	this.hoursModal.next();
    	this.ng_current_time_picker_element = el;
  		
  	}

  	selected_hour(val) {
  		this.modal.open();  		
  		if(val) {
  			if(this.ng_current_time_picker_element =='from') this.ng_dates.start_hr = val;
  			if(this.ng_current_time_picker_element == 'to') this.ng_dates.end_hr = val;
  			
  		}  		
  	}

  	save(type) { 
  		if(type == 1) {
  			if(this.body.id_motivo == 0) {
  				this.alertService.info('Debe seleccionar un motivo');
  				return;
  			}
  			if(this.body.nota == '') {
  				this.alertService.info('Debe escribir una nota');
  				return;
  			}
  			if(this.body.id_usuario == 0) {
  				this.alertService.info('Seleccione un usuario');
  				return;
  			}  			
  			
  			this.ng_buttons.send.isLoading = true;
  			this.ng_buttons.send.disabled = true;
  			this.body.fecha_inicio = moment(this.ng_dates.start).format("YYYY-MM-DD"); 
	        this.body.fecha_fin = moment(this.ng_dates.end).format("YYYY-MM-DD");    	
	    	this.httpService.postHttpAuth("/solicitud", this.body).subscribe(x => {
		        this.ng_buttons.send.isLoading = false;
  				this.ng_buttons.send.disabled = false;
		        this.getAllRequest();    
		        this.alertService.success("Solicitud creada correctamente");
		        this.modal.dismiss();	       
		    } , err => {
		    	this.ng_buttons.send.isLoading = false;
  				this.ng_buttons.send.disabled = false;
		    }); 
  		} else {  		
  			if(this.body.codigo == 'r' && this.body.razon == '') {  				
				this.alertService.info('Debe escribir una nota');
				return;  				
  			}else{
  				if(this.body.codigo == 'a' && this.body.id_concepto == 0) {
  					this.alertService.info('Seleccione un código de Pago');
					return;  			
  				}
  			}
  			this.body.fecha_inicio = moment(this.ng_dates.start).format("YYYY-MM-DD"); 
	        this.body.fecha_fin = moment(this.ng_dates.end).format("YYYY-MM-DD");
  			this.ng_buttons.save.isLoading = true;
  			this.ng_buttons.save.disabled = true;
  			this.httpService.putHttpAuth("/solicitud/"+this.body.id, this.body).subscribe(x => {
  				let msg = "Solicitud " + (this.body.codigo == 'r' ? "rechazada" : "aprobada") +" correctamente";
  				this.ng_buttons.save.isLoading = false;
  				this.ng_buttons.save.disabled = false; 
  				this.alertService.success(msg);          	
	        	this.modal.dismiss();
	        	this.getAllRequest();
        	}, err => {
        		this.ng_buttons.save.isLoading = false;
  				this.ng_buttons.save.disabled = false; 
        	});  			
  		}  	    	
  	}

  	reset() : void {
  		this.ng_dates = {
  			start : new Date(), 
  			end : new Date(), 
  			date : new Date(), 
  			start_hr : '08:00 AM', 
  			end_hr : '05:00 PM'
  		};
  		this.modal_title = '';
  		this.emp_name = '';
  		this.requested_by = '';
  		this.body = {  		
  			codigo : 'p',
  			id_concepto : 0,
  			id_motivo : 0,
  			id_usuario : 0,  		
  			fecha_inicio : moment(this.ng_dates.start).format("YYYY-MM-DD"),
  			fecha_fin : moment(this.ng_dates.end).format("YYYY-MM-DD"),
  			fecha : null,  			
  			razon: '',
  			nota : ''
  		};
  	}
  
  	ng_change_user(user){
  		this.body.id_usuario = user.id;     	
  	}

  	ngAfterViewInit(): void {
    	this.dtTrigger.next();
  	}

  	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      		// Destroy the table first
      		dtInstance.destroy();
      		// Call the dtTrigger to rerender again
      		this.dtTrigger.next();
    	});
  	}



}
