import { Component, OnInit, Input, Output, ViewChild, Injectable, EventEmitter, } from '@angular/core';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import { AmazingTimePickerService } from 'amazing-time-picker';
import { AlertService } from 'ngx-alerts';
import { GlobalsService } from '@services/globals/globals.service';
import { HttpService } from "@services/Http/http.service";
import { ScheduleService } from '@services/globals/schedule.service';
import { Schedule } from "@interfaces/schedule";
import { SpecialSchedule } from '@interfaces/special-schedule';
import { IgxDatePickerModule } from "igniteui-angular";
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal.module';
import {
  	startOfDay,
  	endOfDay,
  	subDays,
  	addDays,
  	endOfMonth,
  	isSameDay,
  	isSameMonth,
  	addHours
} from 'date-fns';
import {
  	CalendarEvent,
  	CalendarEventAction,
  	CalendarDateFormatter,
  	DAYS_OF_WEEK,
  	CalendarEventTimesChangedEvent,
  	CalendarMonthViewDay
} from 'angular-calendar';
import 'hammerjs';
import * as moment from "moment"
import { Subject } from 'rxjs';
import 'twix';

@Component({
  	selector: 'app-calendar',
  	templateUrl: './calendar.component.html',
  	styleUrls: ['./calendar.component.scss'],
   	providers: [
    	{
      		provide: CalendarDateFormatter,
      		useClass: CustomDateFormatter
   	 	}
  	]
})
@Injectable()

export class CalendarComponent implements OnInit {

	// Inputs/Outputs
	@Input() scheduleType : string;
	@Input() users = [];
	@Input() user : any;
	@Input() loadSchedule : Subject<any>;
	@Input() modal : any;	
  	@Input() viewLegend=true;
	@Input() reloadCalendar =  new Subject();
	@Input() resetFields : Subject<any>;
	@Input() saveReady = new Subject();
	@Output() saveFields = new EventEmitter();
	@Output() onClose = new EventEmitter();
	@Output() onDateChanged = new EventEmitter();
	@Output() onHasReloaded = new EventEmitter();
	@ViewChild("modalSchedule") modalSchedule: any;
  	@ViewChild("uidt") dpicker: IgxDatePickerComponent;

  	weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  	eventsCalendar: CalendarEvent[] = [];
	weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
	dtTrigger: Subject<any> = new Subject();
	refresh: Subject<any> = new Subject();	
	view: string = "month";
	locale: string = 'es';
	schedulesByDay: any = [];
	body : Schedule; 
    grploader : any = JSON.parse(localStorage.getItem('grupo'));  
    id_empresa : number;  
    branches: object = [];
    departments: object = [];
    divisions : object = [];
    templates: any = []; 
    allSchedules : any;
    template : any; 
    isLoading = false;
    ng_hide : any = {
    	buttons : false,
    	general : false,
    	schedule : false,    	
    	left_panel : false,
    	multiple_users : false,
    }; 

    ng_button_op = {
    	text:"Guardar",
	    disabled: false,
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin",
	    isLoading:false,
	    principal_icon:"fa fa-floppy-o"
    }; 
    ng_data : any = {}; // main data source of this class.
	ng_selected_branch : number;
    ng_selected_department : number;
    ng_selected_division : number; 
    ng_is_saving : boolean = false;
    ng_start_date = new Date();
    ng_end_date = new Date();
    ng_mode : string = 'create'; // mode of the interface. it can be create or update
    listMinutes: any = [0,5,10,15,20,25,30,35];
    ng_id = null;
    id_usuario = null;
    ng_disable_day_clicked = false;
    pv_admin : boolean = false;
    schedule : any;
    viewDate: Date = new Date();

  	constructor(
  		public httpService: HttpService, 
  		private formatHoursPipe: FormatHoursPipe, 	
  		private alertService: AlertService, 
  		private atp: AmazingTimePickerService, 
  		private gs: GlobalsService,
  		private xf: IgxDatePickerModule) { }

  	ngOnInit() {  	
  		this.pv_admin = this.gs.getPermission().admin_remover_privilegios_edicion;
  		if(this.scheduleType == 'A') {
  			this.ng_hide.buttons = true;
  			this.ng_hide.left_panel = true;  			
  			this.loadCalendar();
  			if(this.reloadCalendar) this.reloadCalendar.subscribe(() => { this.loadCalendar(); });
  		}
  		if(this.scheduleType == 'C' || this.scheduleType == 'B') {
  			let type = this.scheduleType;
  			this.ng_end_date = moment(this.ng_start_date).add(6,'days').toDate();
  			this.resetFields.subscribe( () => { this.reset(); });  
  			this.saveReady.subscribe(() => { 
  				this.ng_button_op.isLoading = false;
  				this.ng_button_op.disabled = false; 		
  			}); 			
  			if(type == 'B') {
  				this.id_empresa = this.grploader.grupos.empresa.id;
  				this.loadSchedule.subscribe(x => { this.load(x); }); 
  				this.ng_data.multiple_users = false;
  				this.httpService.getHttpAuth("/grupo/get/"+this.id_empresa+"?grupo=sucursal").subscribe(x => {                
		            this.branches = x.data; 
		            this.httpService.getHttpAuth("/plantilla/get/all").subscribe(x => {	        
		        		this.templates = x.data;
		        	});		                 
		        }); 
  			}
  			if(type == 'C') {
  				this.ng_hide.general = true;
  				this.ng_hide.schedule = false;
  				this.ng_hide.multiple_users = true;
  				this.httpService.getHttpAuth("/plantilla/get/all").subscribe(x => {	        
	        		this.templates = x.data;
	        	});  				
  			} 			
  		}
  		if(this.scheduleType == 'D') {
  			this.ng_hide.general = true;
  			this.ng_hide.schedule = true;
  			this.ng_hide.multiple_users = true;  
  			this.ng_hide.buttons = true;	
  			this.ng_hide.left_panel = true;
  			this.ng_disable_day_clicked = true;		
  			this.reloadCalendar.subscribe((user) => { 
  				this.user = user;
  				this.loadCalendar(); 
  			});
  		}
   	}
   
  	reset(): void {    
  		this.dpicker.disabledDates = [];   
  		this.ng_start_date = new Date();
  		this.ng_end_date =	moment(this.ng_start_date).add(6, 'days').toDate();	
       	let start = moment(this.ng_start_date).format("YYYY-MM-DD"); 
       	let end = moment(this.ng_end_date).format("YYYY-MM-DD");
       	this.ng_button_op.disabled = false;
       	this.ng_button_op.isLoading = false;
       	this.eventsCalendar = [];
       	this.refresh.next();  
  		if(this.scheduleType == 'B') {
  			this.ng_selected_branch = 0;
  			this.ng_selected_department = 0;
  			this.ng_selected_division = 0;	
  			this.ng_data = {
	  			id_grupo : 0,
	  			id_tipo_grupo : 0,
	  			id_plantilla : 0,
	  			extender : false,
	  			minutos_extra : 5,
	  			fecha_inicio : start,
	  			fecha_fin : end,
	  			nombre : ""
  			};  		
  		}
  		if(this.scheduleType == 'C') {  			
  			this.ng_data = {
  				fecha_inicio : start,
  				fecha_fin : end,
  				minutos_extra : 0,
  				id_plantilla : 0,
  				id : []
  			};
  		} 		
  	}

  	loadCalendar() {    				
    	this.isLoading = true;
    	if(!this.user.id) return;
    	this.httpService.getHttpAuth("/calendario/"+this.user.id).subscribe(x => {
    		//this.calendar = x.data;
      		this.isLoading = false;
      		this.updateCalendar(x.data);
      		
    	}, err => this.isLoading=false);
  	}

  	async load(x) { 
  	 	this.eventsCalendar = [];
	  	this.refresh.next(); 			
  		let start = moment(x.fecha_inicio, "YYYY-MM-DD HH:mm:ss");
  		let end = moment(x.fecha_fin, "YYYY-MM-DD HH:mm:ss");
  		this.ng_data.id_grupo = x.id_grupo;
  		this.ng_data.id_tipo_grupo = x.id_tipo_grupo;
  		this.ng_data.id_plantilla = x.id_plantilla; 
	  	this.ng_data.extender = x.extender;
	  	this.ng_data.minutos_extra = x.minutos_extra;		
	  	this.ng_data.fecha_inicio = start.format("YYYY-MM-DD");
	  	this.ng_data.fecha_fin = end.format("YYYY-MM-DD"); 	  	
	  	this.ng_data.nombre = x.nombre; 
	  	this.ng_start_date = start.toDate();
	  	this.ng_end_date = end.toDate();
	  	this.viewDate = this.ng_start_date;

	  	let url =  "/grupo/get/tree?id_grupo="+x.id_grupo+"&id_tipo_grupo="+x.id_tipo_grupo;
	  	let data = <any> await this.httpService.asyncHttpAuth("GET", url);
	  	let groups = data.data;	  	
	  	this.branches = groups.grupos.sucursal;
  		this.departments = groups.grupos.departamento;
  		this.divisions = groups.grupos.division;
  		this.ng_selected_branch = groups.seleccionados.sucursal;
  		this.ng_selected_department = (!groups.seleccionados.departamento) ? 0 : groups.seleccionados.departamento;
  		this.ng_selected_division = (!groups.seleccionados.division) ? 0 : groups.seleccionados.division; 
  		this.schedule = x;

	  	this.updateCalendar(x.horario_detalle); 
  	}

  	changeDate(type, $component) {   		
  		if(type == 'start') {
  			this.disableDates(); 	
  		} else {
  			let mstart = moment(this.ng_start_date);
  			let mend = moment(this.ng_end_date);
  			if(!mend.isSameOrAfter(mstart)){
  				this.alertService.info("La fecha de fin debe ser mayor a la fecha de inicio");
  				this.ng_end_date = mstart.add(7, 'day').toDate();   				
  				return 0;
  			}  			
  		} 
  		// full calendar, shown in users-detail component
  		if(this.scheduleType == 'A') { 

  		}  	 		
  		if(this.scheduleType == 'B' || this.scheduleType == 'C') {  			
	    	if(this.ng_end_date && !this.ng_start_date){
	      		this.alertService.info("Debes seleccionar una fecha de inicio");      		
	      		return 0;
	    	}	    		     
  			if(this.ng_start_date && this.ng_end_date && this.ng_data.id_plantilla) {
  		
  				let from = moment(this.ng_start_date).format('DD-MM-YYYY');
  				let to = moment(this.ng_end_date).format('DD-MM-YYYY');
  				this.httpService.getHttpAuth('/plantilla?from='+from+'&to='+to+'&id='+this.ng_data.id_plantilla)
  					.subscribe(x => {
  						this.updateCalendar(x.data);  
  					});
  			}  			
  		}  		
  	}

  	dateChanged() {
  		this.onDateChanged.emit(this.viewDate);
  	}

  	dayClicked(day){  		
  		if(this.pv_admin) {
  			return;
  		}
  		if(this.gs.getRole() == 5) return 0; 
  		if(this.ng_disable_day_clicked) return 0;
  		let meta = null;
  		let data = {
  			id : null,
  			id_usuario :  this.user.id,
  			fecha : day.date,
  			entrada : moment(this.ng_start_date).startOf('day').toDate(),
  			salida : moment(this.ng_start_date).startOf('day').toDate(),
  			descanso : 15,
  			minutos_extra :  15,
  			libre : false,
  		};  		
  		if(day.events.length > 0) meta = day.events[0].meta;
  		if(meta) {
  			data.id = meta.raw.id;
  			if(!meta.raw.libre) data.entrada = meta.raw.entrada;
  			if(!meta.raw.libre) data.salida = meta.raw.salida;
  			if(!meta.raw.libre) data.descanso = meta.raw.descanso;
  			data.minutos_extra = meta.raw.minutos_extra;
  			data.libre = meta.raw.libre;
  		}

  		this.modalSchedule.openModal(data);
  	}

  	edit_special() : void {
  		this.loadCalendar();
  	}

  	getByChild(parent, tipo, id) : void { 
        this.ng_data.id_tipo_grupo = parent;
        this.ng_data.id_grupo = id;
        if(tipo != '') {           
            if(id == 0){ 
                if(tipo == 'department') { this.departments = []; this.divisions = []; }
                if(tipo == 'division') this.divisions = [];              
                if(parent == 2){
                    this.ng_data.id_grupo = this.grploader.grupos.empresa.id;
                    this.ng_data.id_tipo_grupo = 1;
                }
                else if(parent == 3){
                    this.ng_data.id_grupo = this.ng_selected_branch;
                    this.ng_data.id_tipo_grupo = 2;
                }
                else if(parent == 4){
                    this.ng_data.id_grupo = this.ng_selected_department;
                    this.ng_data.id_tipo_grupo = 3;
                }
                    
                return; 
            }   
            
           if(tipo=='last') return;
                
           this.httpService.getHttpAuth("/grupo/get/"+id+"?grupo="+tipo).subscribe(data => {                           
                if(tipo == 'departamento') { this.departments = data.data;  } 
                if(tipo == 'division') { this.divisions = data.data; }                        
            });        
       }        
    }

    disableDates() : void {     	    	
    	this.dpicker.disabledDates = [];   
		let date = moment(this.ng_start_date).toDate(); 		
    	var disableFrom = moment(date).subtract(100, 'years').toDate();
      	var dateMax = moment(date).add(3, 'months').toDate();
      	var dateMin = moment(date).subtract(1, 'days').toDate();
      	this.dpicker.disabledDates = [
        	{type: DateRangeType.Between,dateRange:[dateMax, new Date("2200-1-15")]},
        	{type: DateRangeType.Between, dateRange:[disableFrom, dateMin]}
      	];      	
      	if(moment(this.ng_end_date).isBetween(disableFrom, dateMin)) 
      		this.ng_end_date = moment(dateMin).add(1, 'day').toDate();      	
    }  

    updateCalendar(daysObject) {    	
    	let i = 0;
    	this.eventsCalendar = [];
       	this.refresh.next();              		
  		do {  			
  			let day = daysObject[i];  
  			let meta : any = {};		
        	if(!day) { i++; continue; } 
        	if(!day.activo) { i++; continue; }
           	let date = moment(day.fecha, "YYYY-MM-DD").toDate();        	
        	meta.isFree = day.libre;
        	meta.isHoliday = day.feriado;
        	meta.sindicate = (!day.sindicato) ? false : true;
        	meta.isConcept = false;
        	meta.scheduleType = day.tipo_horario;
        	meta.cell = {};
        	meta.raw = day;
        	if(!day.libre) {
        		let entrada = moment(day.fecha+' '+day.entrada, 'YYYY-MM-DD HH:mm').toDate();
        		let salida = moment(day.fecha+' '+day.salida, 'YYYY-MM-DD HH:mm').toDate();        		
        		meta.cell.schedule = this.formattedHour(entrada) + ' - ' + this.formattedHour(salida);
           	} else {
           		meta.cell.schedule = 'Libre';
           	}
        	if(day.concepto_manual) {        		
        		let cm = day.concepto_manual.concepto;
        		meta.isConcept = true;
        		meta.cell.concept = (cm.codigo == 'VAC') ? 'Vacaciones' : day.nombre;        		
        	}
        	if(day.feriado) meta.cell.holiday = 'Feriado:' + day.dia_feriado.nombre;        	
        	this.eventsCalendar.push({title: '', start: date, end: date, meta: meta	}); // event creation
        	this.refresh.next();  
        	i++;        	
  		}while(i < daysObject.length)   		
  		// send emitter when calendar has finished updating
  		this.onHasReloaded.emit(true);
  	}   

  	close() {
  		this.onClose.emit(true);
  	}

  	save() {
  		this.ng_button_op.isLoading = true;
  		this.ng_button_op.disabled = true;  		
  		// type B is for calendar used in schedules screen, this one saves schedules by template
  		if(this.scheduleType == 'B' || this.scheduleType == 'C') {
  			if(this.ng_data.id_plantilla == 0){
  				this.alertService.danger('Seleccione una plantilla para crear éste horario.');
  				this.ng_button_op.isLoading = false;
	  			this.ng_button_op.disabled = false;
	  			return;
  			}
  			this.ng_data.fecha_inicio = moment(this.ng_start_date).format("YYYY-MM-DD"); 
	  		this.ng_data.fecha_fin = moment(this.ng_end_date).format("YYYY-MM-DD");
  			if(this.scheduleType == 'B'){
  				if(!this.schedule) {
	  				if(this.ng_data.id_tipo_grupo == 0 || this.ng_data.id_grupo == 0) {
	  					this.alertService.danger("Seleccione un grupo o jerarquía para guardar este horario.");
	  					this.ng_button_op.isLoading = false;
	  					this.ng_button_op.disabled = false;
	  					return;
	  				}  	  				
	  			} else {
	  				//console.log(this.ng_data);
	  				//alert("pelea!");
	  			}
  			}
  			if(this.scheduleType == 'C'){
  				console.log(this.ng_data);
  				for(let i = 0; i < this.users.length; i++) this.ng_data.id.push(this.users[i].id);  				
  			}  			
  		}  	

  		this.saveFields.emit(this.ng_data);
  	}
  
  	deleteUserList(user){
  		var index = 0;
  		if (this.users.length==1){
  			this.alertService.info('Debe tener al menos un colaborador para crear un horario');
  			return 0;
  		}
  		this.users.filter((u, i) => { if(u.id == user.id) this.users.splice(i, 1); });
     	
  	}

  	formattedHour(date): void{
    	return this.formatHoursPipe.transform(date,"HH:mm","hh:mm A")
  	}
}