import { Component, OnInit,Output, Input, AfterViewInit, Injectable,ViewChild,EventEmitter  } from '@angular/core';
import * as moment from "moment"
import { Subject } from 'rxjs';
import 'twix';


@Component({
  	selector: 'app-dchain-time-picker',
  	templateUrl: './dchain-time-picker.component.html',
  	styleUrls: ['./dchain-time-picker.component.scss']
})
@Injectable({
  	providedIn: 'root'
})

export class DchainTimePickerComponent implements OnInit {

	//@ViewChild("modalTag") modal: any;
	@Output() selected = new EventEmitter();
	@Input() openComp = new Subject()

	hours: any = [];  
  	scheduleMode: string = 'AM';
  	modal : any;
  
  	constructor() { }

  	ngOnInit() {
  		this.openComp.subscribe(() => {
  			
  			this.open();
  		});
  	}

  	modalIsReady(modal) {
  		this.modal = modal;
  	}

  	open() {
      	this.hours = {am:[], pm:[]};      
      	console.log('hola');
	    var day = moment().startOf("day");
	    var day_finish = moment().endOf("day");
	    var endTime = moment('12:00', "hh:mm");
	    var noon = moment()
	    var finish = true;
      	this.hours.am.push({
          	time:day.format("hh:mm A") 
      	});

      	while(finish){
          	day = day.add(30,'minutes');          
          	if(day.isSameOrAfter(day_finish))
          	{   
              	finish=false;
              	break;
          	}
          
          	if (day.isBefore(endTime)){
              	this.hours.am.push({time : day.format("hh:mm A") });
          	} else {
              	this.hours.pm.push({time : day.format("hh:mm A") });
          	}        
      	}

     	this.modal.open();
  	}
  
  	close(){
      	this.modal.dismiss();
      	this.selected.emit(null);
  	}

  	returnHour(val){
      	this.modal.dismiss();
      	this.selected.emit(val);
  	}
 


}
