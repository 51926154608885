import { Component, OnInit, ViewChild,Input,Output,EventEmitter } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { Subject } from "rxjs/Subject";
import * as moment from "moment";

@Component({
  selector: 'app-modal-distribution',
  templateUrl: './modal-distribution.component.html',
  styleUrls: ['./modal-distribution.component.scss']
})
export class ModalDistributionComponent implements OnInit {

  	constructor(private alertService: AlertService) { }

	@Input() dist: Subject<any>;
	@Input() open: Subject<any>;
	@Input() close : Subject<any>;
	@Input() title = '';	
	@Input() mode = 1;
	@Output() back = new EventEmitter();
	@Output() confirm = new EventEmitter();

	modal : any;		
	open_modal_confirm : Subject<any> = new Subject();
	ng_distribution : any;
	is : string = '';
	ng_employee_name : string = '';
	ng_current_distribution : any;
	ng_current_item : number = 0;
	ng_period_data : any;
	ng_loaded : boolean = false;
	ng_confirm_txt : string = '';
	ng_current_date = '';
	ng_user_info = {};
	ng_selected_employee = 0;
	ng_show_buttons = true;
	ng_hide_general = false;
	emit_verified_fn_btn = null;
	ng_buttons = {
		btn_left : {
			onlyIcon : true,
			disabled : false, 
			principal_icon : "fa fa-arrow-left",  		 		
  			type : "btn btn-warning btn-xs"  		
		},
		btn_right : {
			onlyIcon : true,
			disabled : false, 			
  			type : "btn btn-warning btn-xs",
  			principal_icon : "fa fa-arrow-right"  		 
		},
		btn_close_modal : {
			disabled: false,
			isLoading:false,
			text:"Cancelar",		   
		    type:"btn btn-default"		  
		},
		btn_back : {
			disabled: false,
			isLoading:false,
			text:"Ir atrás",		   
		    type:"btn btn-default"		   
		},
		btn_approve : {
			disabled: false,
			isLoading:false,
			onlyIcon : true,
			text:"Aprobar",		   
		    type:"btn btn-primary",
		    icon:"fa fa-spinner fa-spin",		    
		    principal_icon:"fa fa-floppy-o"
		}
	}


  	ngOnInit() {
  		this.reset();  
  		this.close.subscribe(() => { this.modal.dismiss(); });
  		this.open.subscribe((dist) => {	
  			this.mode = dist.modo;
  			console.log		
  			if(dist.modo == 4) {
  				this.is = 'employee';
  				this.title = 'Cálculo de la Distribución';
  				this.ng_show_buttons = (dist.show_buttons != undefined) ? dist.show_buttons : true;
  				this.ng_confirm_txt = '¿Desea confirmar la aprobación de éstas marcaciones?';
  				this.ng_current_distribution = dist.distribucion;
  				this.ng_user_info = { user : dist.usuario, schedule : dist.horario };
  			}else{    					
  				this.ng_distribution.general = dist.general;
  				this.ng_distribution.summary = dist.resumido;
  				this.ng_distribution.detailed = dist.detallado;
  				if(dist.modo == 1) {
  					this.ng_hide_general = false;
  					this.ng_buttons.btn_left.disabled = true;
  					this.ng_current_distribution = this.ng_distribution.general;
  					this.ng_period_data = this.ng_distribution.summary[0].periodo; 
  					console.log(this.ng_period_data);
  					console.log(this.ng_distribution.summary[0]);			
  				}
  				if(dist.modo == 3) {
  					this.ng_hide_general = true;
  					this.ng_buttons.btn_left.disabled = true;
  					if(this.ng_show_buttons) {
  						//change approve button
  						this.ng_buttons.btn_approve.text = 'Verificar';
  						this.ng_buttons.btn_approve.principal_icon = 'fa fa-check-o';
  						this.emit_verified_fn_btn = dist.emit_fn;
  					}
  					if(typeof this.ng_distribution.detailed[0] != undefined){
  						this.ng_period_data = this.ng_distribution.detailed[0].periodo;
  						if(this.ng_distribution.detailed[0].distribucion.length > 0) {
  							this.ng_current_date = this.ng_distribution.detailed[0].distribucion[0].fecha;
  							this.ng_selected_employee = this.ng_distribution.detailed[0].usuario.id;
  							this.ng_current_distribution = this.ng_distribution.detailed[0].distribucion[0].distribucion; 
  							if(this.ng_distribution.detailed[0].distribucion[1] == undefined)
  								this.ng_buttons.btn_right.disabled = true; 							
  						}
  					}  					
  				}
  			}    			
  			this.ng_loaded = true;
  			this.modal.open(); 
  		});  		
  	}

  	reset() {
  		this.ng_distribution = { general : null, summary : null, detailed : null, employee : null};
  		this.is = 'general';
  	}

  	ng_tab(is) {
  		if(is == 'general') this.ng_current_distribution = this.ng_distribution.general;
  		if(is == 'summary') this.ng_current_distribution = this.ng_distribution.summary;
  		if(is == 'detailed') this.ng_current_distribution = this.ng_distribution.detailed;
  	}

  	ng_emit_action() {
  		this.emit_verified_fn_btn();
  	}

  	ng_change_user() {
  		let id  = this.ng_selected_employee;
  		let dist = null;
  		if(this.mode == 2) { // summary
  			dist = this.ng_distribution.summary.filter((obj, i) => {return (obj.usuario.id == id) ? true : false })[0];  			
  		}
  		if(this.mode == 3) { // detailed;
  			dist = this.ng_distribution.detailed.filter((obj, i) => {return (obj.usuario.id == id) ? true : false})[0];
  			dist = dist.distribucion[0];
  			this.ng_current_date = dist.fecha;
  			this.ng_current_item = 0;  
  			this.ng_buttons.btn_right.disabled = false;
  			this.ng_buttons.btn_left.disabled = true;			
  		}

  		this.ng_current_distribution = dist.distribucion;  		
  	}

  	ng_change_summary_item(type) {
  		let i = this.ng_current_item;  	
  		let can_do = true;
  		if(type == 'next' && this.ng_buttons.btn_right.disabled) can_do = false;
  		if(type == 'prev' && this.ng_buttons.btn_left.disabled) can_do = false;
  	
  		if(can_do) {
  			if(type == 'next') i++;
	  		if(type == 'prev') i--;
	  		if(this.mode == 3) {
	  			let id = this.ng_selected_employee;
	  			let dist = this.ng_distribution.detailed;
	  			let o_i = 0;  			
	  			dist = dist.filter((di, i) => {  return (di.usuario.id == id) ? true : false; })[0];  			
	  			this.ng_current_distribution = dist.distribucion[i].distribucion;  		
	  			this.ng_current_date = dist.distribucion[i].fecha;
	  			this.ng_current_item = i;			
	  			// depending of the direction, check if next or prev item exists  
	  			if(type == 'next') { i++; o_i = this.ng_current_item -1; }
	  			if(type == 'prev') { i--; o_i = this.ng_current_item +1; }
	  			if(dist.distribucion[i] == undefined) {  				
	  				if(type == 'next') this.ng_buttons.btn_right.disabled = true;
	  				if(type == 'prev')  this.ng_buttons.btn_left.disabled = true;
	  			} 
	  			let disable_that = (dist.distribucion[o_i] == undefined) ? true : false;
	  			if(type=='next') this.ng_buttons.btn_left.disabled = disable_that; 
	  			if(type=='prev') this.ng_buttons.btn_right.disabled = disable_that; 
	  			
	  			// 			
	  			
	  		}
  		}	
  	}

  	change_tab(mode) {
  		this.mode = mode;
  		if(this.mode == 1){
  			this.ng_current_distribution = this.ng_distribution.general;
  		}else{
  			this.ng_selected_employee = this.ng_distribution.summary[0].usuario.id;
  			if(this.mode == 2 || this.mode == 3) {
	  			let dist = [];
	  			let id = this.ng_selected_employee;
	  			if(this.mode == 2) dist = this.ng_distribution.summary;
	  			if(this.mode == 3) dist = this.ng_distribution.detailed;
	  			let dist_ = dist.filter((di, i) => {  return (di.usuario.id == id) ? true : false; })[0];  			
	  			if(this.mode == 2) {
	  				this.ng_current_distribution = dist_.distribucion;
	  			}else{
	  				this.ng_current_distribution = dist_.distribucion[this.ng_current_item].distribucion;  
	  				this.ng_current_date = dist_.distribucion[this.ng_current_item].fecha;		
	  			}
	  		}
  		}  		
  	}



  	modalIsReady(modal) {
  		this.modal = modal;
  	}

  	ng_approve() {
  		// this will display the confirm modal, once approve button has been clicked
  		this.open_modal_confirm.next();  	
  		this.modal.dismiss();	
  	}

  	ng_confirm($event) {
  		if(!$event) this.modal.open();  		  		
  		this.confirm.emit($event);
  	}

  	ng_go_back() {
  		this.modal.dismiss();
  		this.back.emit();
  	}

  	close_me() {
  		this.modal.dismiss();
  	}

}
