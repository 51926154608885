import { Component, OnInit, EventEmitter, Output,Input } from '@angular/core';
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { Subject } from 'rxjs';

@Component({
  selector: 'filters-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})

export class FiltersCompaniesComponent implements OnInit {

  	constructor(private http: HttpService, private  alertService: AlertService) { }
  
  	@Input() classCols? = "col-md-3 boxes-filter"
  	@Input() showUsers? = false;
  	@Input() canChangeUser? = false;
  	@Input() isLoading? = false; 
  	@Input() loadUserAfter? = 2;
  	@Input() hideAfter? = 5;
  	@Output() modifyType = new EventEmitter();  
  	@Output() onLoaded = new EventEmitter();   	

    // mine
    grploader : any = JSON.parse(localStorage.getItem('grupo')); 
    branches : any=[];
    departments : any=[];
    divisions: any=[];
    cost_centers: any = [];
    ng_users: Array<any> = [];
  	ng_selected_users: Array<any> = [];
    ng_selected_branch : number = 0;
    ng_selected_department :  number = 0;
    ng_selected_division : number = 0;
    ng_selected_cost_center : number = 0;
    ng_data : any = {};  
    ng_respy_filters :any; 

    ngOnInit(){
    	this.ng_data = {
    		id_grupo : 0, 
    		id_tipo_grupo : 2, 
    		init: true, 
    		loading: false, 
    		users : {
    			total:[], 
    			selected: []
    		},
    		filters : { branch: 0, department : 0, division: 0, cc : 0}
    	};
    	this.emitter(true);
        this.http.getHttpAuth("/grupo/get/"+this.grploader.grupos.empresa.id+"?grupo=sucursal").subscribe(data => {                
            this.branches = data.data;  
            this.ng_selected_branch = this.ng_data.filters.branch;          
            this.emitter(false);
        });        
    }

   	getByChild(parent, tipo, id)  {     		
   		if(id == 0) { // tener un id 0, necesita buscar de un grupo superior   			
   			if(parent == 2) { tipo = 'sucursal'; id = this.grploader.grupos.empresa.id; }
   			if(parent == 3) { tipo = 'departamento'; id = this.ng_selected_branch;  }
   			if(parent == 4) { tipo = 'division';  id = this.ng_selected_department; }
   			if(parent == 5) { tipo = 'centro_costo'; id = this.ng_selected_division; }
   			parent -= 1;
   		}    			
        if(tipo != '') {
        	this.emitter(true);
           	this.http.getHttpAuth("/grupo/get/"+id+"?grupo="+tipo).subscribe(data => {         			
           		if(tipo == 'sucursal'){ 
           			this.ng_selected_branch = 0; 
           			this.branches = data.data;
           			this.departments = [];
      				this.divisions = [];
      				this.cost_centers = []; 
      				this.ng_data.filters = { branch: 0, department : 0, division: 0, cc : 0};
           		}                        
                if(tipo == 'departamento') {                	
                   	this.ng_data.filters.branch = id;
                	this.ng_data.filters.department = 0;
                	this.ng_data.filters.division = 0;  
                	this.ng_data.filters.cc = 0; 
                	this.departments = data.data; 
                	this.ng_selected_department = (this.ng_respy_filters) ? this.ng_respy_filters.department : 0;              	
                	this.divisions = [];
                	this.cost_centers = [];     
                } 
                if(tipo == 'division') {                 	
                	this.ng_data.filters.department = id;
                	this.ng_data.filters.division = 0; 
                	this.ng_data.filters.cc = 0;                  	
                	this.divisions = data.data; 
                	this.ng_selected_division = (this.ng_respy_filters) ? this.ng_respy_filters.division : 0;   
                	this.cost_centers = [];                		
                }
                if(tipo == 'centro-costo') {                 	  	
                	this.ng_data.filters.division = id;
                	this.cost_centers = data.data;
                	this.ng_selected_cost_center =  (this.ng_respy_filters) ? this.ng_respy_filters.cc : 0;           
                }           
                // assignment ng_data
                this.ng_data.id_grupo = id;
                this.ng_data.id_tipo_grupo = parent;
                this.ng_data.init = false;
                this.ng_data.users = {total:[], selected: []};                            
                if(parent >= this.loadUserAfter) this.getusers();
                else this.emitter(false);                          
            });        
        } else {        	
        	// only cost-center case 
        	this.ng_data = {
        	    id_grupo : id, 
        	    init: false, 
        	    id_tipo_grupo : 5, 
        	    users : {total:[], selected: []},
        	    filters : { 
        	    	branch: this.ng_selected_branch, 
        	    	department : this.ng_selected_department, 
        	    	division: this.ng_selected_division, 
        	    	cc : this.ng_selected_cost_center
        	    }
        	}; 
        	if(this.loadUserAfter == 5) this.getusers();
            else this.emitter(false);          

        }           
    }
 
    getusers() {
    	this.ng_users = [];
    	this.ng_selected_users = [];
    	let uri = "/usuario/vista?id_rol=5&id_estado=1";
    	uri+= "&id_grupo="+this.ng_data.id_grupo+"&id_tipo_grupo="+this.ng_data.id_tipo_grupo;     	
    	this.http.getHttpAuth(uri,{}).subscribe(x => {  
    		let user = [];    		
    		for(let i = 0; i < x.data.length; i++) 
    			user.push({id: x.data[i].id_usuario, nombre: x.data[i].nombre +' '+x.data[i].apellido });    		
    		this.ng_users = user;
    		this.ng_data.users.total = user;
    		this.emitter(false);   		
    	});
    }

    changeDataUser() {  		
  		let selected = this.ng_users.filter((item) => { return this.ng_selected_users.includes(item.id); });
  		this.ng_data.users.selected = selected;
  		this.emitter(false);    	
  	}

   	emitter(ready) : void {
   		this.ng_data.loading = ready;   		
  		this.onLoaded.emit(this.ng_data);
  	}  

  	reset_filters(filters){    	
  		if(filters){
  			this.ng_respy_filters = filters;
  			/*this.ng_selected_branch = 0;
  			this.ng_selected_department = 0;
  			this.ng_selected_division = 0;
  			this.ng_selected_cost_center = 0;
  			this.departments = [];
  			this.divisions = [];
  			this.cost_centers = [];*/

  			if(filters.branch != 0) {
  			 	//this.ng_selected_branch = filters.branch;     
  				this.getByChild(2, 'departamento', filters.branch);
  				
  			}
  			if(filters.department != 0){     			
  				this.getByChild(3, 'division', filters.department);
  				
  			}
  			if(filters.division != 0) {  			
  				this.getByChild(4, 'centro-costo', filters.division);
  			}  	
  		} 
  	}
  	
} 