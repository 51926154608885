import { Component, OnInit, Inject, ViewContainerRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  ComponentRef,
  ViewChild,
  Directive,
  Renderer2,
  ElementRef,
  EventEmitter
 } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute,Router } from "@angular/router";
import * as moment from "moment"
import 'twix';
import { AmazingTimePickerService } from 'amazing-time-picker'; // this line you need
import { AgmCoreModule } from '@agm/core';
import { FormatHoursPipe } from "@pipes/format-hours.pipe"
import { listChecks } from "@interfaces/checks"
import { GlobalsService } from '@services/globals/globals.service'
import { ModalConfirmComponent } from "@html/modals/modal-confirm/modal-confirm.component"
import { DomService } from "@html/modals/modal-confirm/modal-service"
import { DragulaService } from 'ng2-dragula';
import { Angular2Txt } from 'angular2-txt/Angular2-txt';

@Component({
  selector: 'app-cards-detail',
  templateUrl: './cards-detail.component.html',
  styleUrls: ['./cards-detail.component.scss']
})

export class CardsDetailComponent implements OnInit {
	@ViewChild('PDF') pdf : ElementRef<any>;
	@ViewChild("alertContainer", { read: ViewContainerRef }) container;
	@ViewChild("modalSchedule") modalSchedule: any;

  	scheduleInfo:any;
 	user: any = null;
  	ng_users_list = [];
  	ng_selected_user;
  	isLoadingTab = true;
  	isLoadingExtra = false;
  	dataCards: any = [];
  	totals : any = [];
  	concepts:  any = [];
  	date : any = [new Date("07-15-2018"),new Date("07-31-2018")];
  	modelReason: String="REG";
  	finalDistribution: any = [];
  	modelConcept: any = [];
  	concept_total :  number = 0;
  	journyLimit: number = null;
  	selectedDate: String="";
  	total: String= "";
  	regularHours: String="";
  	extraHours: String="";
  	aproveHours: boolean=false;
  	checkList: string[] =[];
  	//lat: number = 9.010131;
  	//lng: number = -79.474144;
  	//markers: any = [];
  	description: String ="";
  	deletedIds: any = [];
  	logs: any = [];
  	extra_init: String ="";
  	extra_end: String ="";
  	approveExtra: boolean = true;
  	isLoadingApprove: boolean=false;
  	isApproved: boolean=false;
  	userApprove: any = [];
  	id_marcacion:number=null;
  	//from: any ="";
  	extra: Array<any> = [];
  	extraObject: any = <any>{};
  	extra_formated: Array<any> = [];
  	//to: any ="";
  	id_period: any ="";
  	periods;
  	calculatedTime: Array<any> = [];
  	next: String = "Siguiente";
  	prev: String = "Anterior";
  	start: boolean = true;
  	isOpen: boolean = true;
  	dragulaSub = new Subscription();
  	closure : any;
 	currentPunchDay : any;
 	ng_history_mode_tab = 'logs';
 	ng_dist_tab = 'distribution';

 
	buttonLoadLogs: any = {
	    text:"",
	    disabled: false,
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin",
	    principal_icon:"fa fa-history",
	    onlyIcon:true,
	    isLoading:false
	};
  	optionsConfig: any = {
	    text:"",
	    disabled: false,
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin",
	    principal_icon:"fa fa-clock-o",
	    onlyIcon:true,
	    isLoading:false
  	};
  	styles: any[] = [
	    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
	    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
	    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
  	];
  	changingValue: Subject<number> = new Subject();  
  	marks = [] = [];
  	marksaux = [] = [];
  	checksProccesed: Array<any> = [];

  	componentRef: ComponentRef<any>;
  	finalDistributionDaily: any = [];
  	indexDailyDistribution = 0;
  	wasModified: boolean = false;
  	message_modified: String = "";
  	extraComment: string  = "";
  	extraStructure: any =<any>{};
  	extraEnable: number = 0;
  	counter:number = 1;
  	first_dist = [];
  	modals : any = {};
  	confirm_overtime_msg = '';
  	ng_hide_approve_buttons = false;
  	dragulaOptions = {
  		moves : function (el, container, handle) {
    		console.log("hola");
  		}
  	};

  	ng_period_data : any = null;
  	ng_title = '';
  	ng_emp_photo : string = '';
  	ng_users_first_time_loaded : boolean;
  	open_modal_distribution : Subject <any> =  new Subject();
  	distribute_to_modal : Subject <any>  = new Subject();
  	open_confirm_modal : Subject <any> = new Subject();
  	ng_modal_distribution : any;
  	ng_show_sDistribution:boolean = true;
  	ng_list_id_punches : any = [];
  	ng_group_filters:any;
  	ng_role;
  	pv_admin : boolean = false;



  	constructor(
  		private httpService: HttpService,
        private _route: ActivatedRoute,
        private atp: AmazingTimePickerService,
        private formatHoursPipe: FormatHoursPipe,
        private alertService: AlertService,
        public gs: GlobalsService,
        public domService: DomService,
        private resolver: ComponentFactoryResolver,
        private router:Router,
        private renderer : Renderer2,
        private dragula : DragulaService,
        private el : ElementRef) { 

  		router.routeReuseStrategy.shouldReuseRoute = () => false;
   	 	this.dragula.createGroup('dragula-time', <any>{
  	 		removeOnSpill: false,
  	 		copy : false,  	 	
      		moves: function (el, source, handle, sibling) {      		  		
   				return handle.classList.contains('dragger');
			}
      	});

  	 	this.dragula.createGroup('random', <any>{
  	 		copy : false,
  	 		moves : function (el, source, handle, sibling) { 
  	 			return false;   			
			}
  	 	});

    	this.dragulaSub.add(this.dragula.drop('dragula-time').subscribe( ({name, el, target, source, sibling}) => {
	      	var horaSource = "";
	      	var horaTarget = "";
	      	var markaux = this.marksaux;

      		if(sibling != null){
        		source.appendChild(sibling);
      		}else{
		        let aux = target.firstChild;
		        target.removeChild(target.firstChild);
		        source.appendChild(aux);
     		}
      		if(source.id[0] == "e")  {
		        let id = source.id[8];
		        horaSource = markaux[id].entrada;
      		}else{
		        let id = source.id[7];
		        horaSource = markaux[id].salida;
		    }

		    if(target.id[0] == "e")  {
		        let id = target.id[8];
		        horaTarget = markaux[id].entrada;
		        markaux[id].entrada = horaSource;
		    }else{
		        let id = target.id[7];
		        horaTarget = markaux[id].salida;
		        markaux[id].salida = horaSource;
		    }

      		if(source.id[0] == "e")  {
        		let id = source.id[8];
        		markaux[id].entrada = horaTarget;
      		}else{
        		let id = source.id[7];
        		markaux[id].salida = horaTarget;
      		}
      		this.marksaux = markaux;
    	}));
  	}

  	ngOnInit() {  
  		this.ng_group_filters = JSON.parse(localStorage.getItem("filters_cards"));
  		this.pv_admin = this.gs.getPermission().admin_remover_privilegios_edicion;
  		this.ng_role = this.gs.getRole();

  		this.reset();
  		this.ng_period_data = null;	
  		this.ng_users_first_time_loaded = false;
  		this.httpService.getHttpAuth("/usuario/"+this._route.snapshot.params.id).subscribe(data => {
			this.user=data.data;
				
		});
    	let id=null
    	if(this._route.snapshot.params.id_corte) id=this._route.snapshot.params.id_corte;
    	this.loadPeriod(id);    	
  	}

  	reset() {
  		this.ng_modal_distribution = { open : new Subject(), close : new Subject() };
  		this.ng_emp_photo = 'assets/images/perfil/alfredorods.png';
  		this.modals = {
  			approve : { object : null, 	modal_view : 1, title : '' }, 
  			history : {object : null, }, 
  			distribution: { object:  null } , 
  			print : {object : null, booleans : {supervisor_sign : true} },
  			confirm_overtime : new Subject(),
  			confirm_verified : new Subject(),
  			confirm_disapprove : new Subject()
  		}
  	}

  	

  	modalIsReady(modal, type) : void {
  		if(type == 1) this.modals.approve.object = modal;
  		if(type == 2) this.modals.distribution.object = modal;
  		if(type == 3) this.modals.history.object = modal; 
  		if(type == 4) this.modals.print.object = modal; 
  	}  

  	showSDistribution(){
  		this.ng_show_sDistribution = (this.ng_show_sDistribution) ?  false : true;
  	}

  	openScheduleModal(day) { 
  		if(this.ng_role== 5) return;
  		let id = this.user.usuario.id;
  		this.httpService.getHttpAuth('/calendario-dia?usuarios='+id+'&fecha='+day.fecha).subscribe(x => { 
	  		let sinfo = x.data[0].horario;	  		
	  		let data = {
	  			id : sinfo.id,
	  			id_usuario : id,
	  			user : this.user.usuario,
	  			fecha : moment(sinfo.fecha),
	  			entrada : (sinfo.libre) ? "00:00" : sinfo.entrada,
	  			salida : (sinfo.libre) ? "00:00" : sinfo.salida,
	  			descanso : (sinfo.libre) ? 15 : sinfo.descanso,
	  			minutos_extra : (sinfo.libre) ? 5 : sinfo.minutos_extras,
	  			libre : sinfo.libre,
	  		}; 

	  		this.modalSchedule.openModal(data);
	  		
	  			   	
	  	}, error => {	  	 		
	  	});    
  	}

  	closeScheduleModal(data){  	
  		this.loadPeriod(null);
    	this.modalSchedule.close();
  	}

  	multiple_distribution(override){  		
  		var obj = {
  			this: this,
  			multiple : function(override, $this){
	  			$this.httpService.postHttpAuth('/marcacion/aprobacion-dia', {
	  				'id_marcacion': $this.ng_list_id_punches,
	  				'override': override 
	  			}).subscribe(x => { 
	  				var data = x.data;
	  				if(data.error){
	  					$this.confirm_overtime_msg = '';
	  					$this.modals.confirm_overtime.next(obj); // opens confirm modal	
	  				}else{
	  					$this.alertService.info("Las aprobaciones múltiples se aprobarán brevemente. Aguarde");
	  				}      	
	  	 		}, error => {
	  	 			$this.isLoadingApprove = false;
	  	 		}); 
	  		}
  		} 		

  		obj.multiple(false, this);   		
  	}

  	quick_approveList(evt, id,pos){
  		let chk = evt.target.checked;

  		if(chk){
  			this.ng_list_id_punches.push(id);
  			console.log(this.ng_list_id_punches);
  			return;
  		}	

  		this.ng_list_id_punches.splice(this.ng_list_id_punches.indexOf(id), 1);  				
  	}

  	changePeriod(type){
	    let id = (type == 'prev') ? this.periods.prev : this.periods.next;
	  	if(!id) {
	  		 this.alertService.warning("No existe otro periodo");
	  		 return 0;
	  	}
	  	this.loadPeriod(id); 
	}

   	changed(object) {  
   		this.router.navigate(['/cards', object.id]);  
    }  

  	close_modal_history() {
  		this.modals.history.object.dismiss();
  	}

  	close_modal_print() {
  		this.modals.print.object.dismiss();
  	}

  	generateFile(){ 
  		this.isLoadingApprove = true;
  		let url = '/periodo/csv-rapido?id='+this.ng_period_data.user.id;
  		if(this.ng_period_data.cerrado) url += '&id_corte=' + this.ng_period_data.id_corte;  		
  		this.httpService.getHttpAuth(url).subscribe(x => {  	 	  
      		this.isLoadingApprove = false;
      		var options = { 
        		fieldSeparator: (x.data.config.separator == 'tab' ? "\t" : x.data.config.separator),
        		quoteStrings:"",
        		useBom : x.data.config.bom
      		};             
      		new Angular2Txt(x.data.data,x.data.config.filename + " " + moment().format("DD-MM-YY HH:mm"),options);
  	 	}, error => {
  	 		this.isLoadingApprove = false;
  	 	}); 		
  	}

  	show_period_distribution() { 
  		this.isLoadingApprove = true; 		
  		this.httpService.getHttpAuth('/distribucion/periodo?id_periodo='+this.ng_period_data.id).subscribe(data => {
  			let temp = [];
  			let dist = data.data;
  			dist.modo = 3;
  			dist.show_buttons = false;	   		 	
  		 	this.isLoadingApprove = false;
  		 	this.ng_modal_distribution.open.next(data.data);  		 	
  		});
  	}
  

  	show_distribution(obj) {  		
  		this.modals.approve.title = 'Distribución de horas diarias';  		
  		let button = obj.buttonDist;
  		this.ng_hide_approve_buttons = true;
  		button.isLoading = true;
  		this.httpService.getHttpAuth('/distribucion/'+obj.id_marcacion).subscribe(data => {
  			let temp = [];
  			let dist = data.data;
  			dist.modo = 4;
  			dist.show_buttons = false;	 
  		 	button.isLoading = false;
  		 	this.ng_modal_distribution.open.next(data.data);  		 	
  		});
  	}

  	loadPeriod(id):void {
	    let url="/periodo/"+this._route.snapshot.params.id;
	    if(id) { url="/periodo/"+this._route.snapshot.params.id+"/"+id; }
	    this.isLoadingTab=true;
	    this.httpService.getHttpAuth(url).subscribe(data => {
	      	this.isLoadingTab=false;
	      	var fecha = moment().format('YYYY-MM-DD');
	      	this.ng_period_data = data.data.periodo; // period + user data
      	  	this.httpService.getHttpAuth('/perfil/'+this.ng_period_data.user.id).subscribe(data => { 
      	  		if(data.data != null) this.ng_emp_photo =  data.data.url;      	  		
      	  	});

	      	

	      	for (let i = 0; i < data.data.marcaciones.length; i++) {
	          	this.isOpen = data.data.cerrar;

	          	data.data.marcaciones[i].highlight = false;
	          	var useBadge = false;
	           	if(!data.data.marcaciones[i].libre){
	            	data.data.marcaciones[i].marcaciones.forEach(function(value2, index2) {
		              	if(!value2.entrada || !value2.salida){
		                	let fecha2 = moment(data.data.marcaciones[i].fecha, 'DD-MM-YYYY').format('YYYY-MM-DD');
	                		if(fecha > fecha2){ useBadge = true; }
	              		}
	            	});
	          	}


		        data.data.marcaciones[i].button= {
		            text:"",
		            type:"btn btn-primary pos-relative",
		            icon:"fa fa-spinner fa-spin",
		            principal_icon:"fa fa-clock-o",
		            onlyIcon:true,
		            isLoading:false,
		            useBadge:useBadge,
		            badgeClass:"badge-example",
		            badgeText:"!",
		            disabled: (((data.data.marcaciones[i].horario && !data.data.cerrar && this.gs.getPermission().ver)) ? false : true)
		        };

	          	data.data.marcaciones[i].buttonLogs  = {
		            text:"",
		            disabled: !data.data.marcaciones[i].estado.historial,
		            type:"btn btn-primary",
		            icon:"fa fa-spinner fa-spin",
		            principal_icon:"fa fa-history",
		            onlyIcon:true,
		            isLoading:false
	          	};
	          	data.data.marcaciones[i].buttonDist =  {
	          		text:"",		           
		            type:"btn btn-primary",
		            icon:"fa fa-spinner fa-spin",
		            principal_icon:"fa fa-balance-scale",
		            onlyIcon:true,
		            isLoading:false,
		            disabled : !data.data.marcaciones[i].estado.distribucion
	          	}
	          	data.data.marcaciones[i].background = null;
	      	}
	      
	      	this.dataCards = data.data.marcaciones;
	      	this.totals = data.data.totales;
	      	this.concepts = data.data.conceptos;
	      	this.concept_total = data.data.totales_conceptos;	      	
	      	this.id_period = data.data.periodo_actual.id;
	      	this.periods = data.data.periodos;
	      	this.closure = data.data.corte;		     
	      	if(!this.ng_users_first_time_loaded) {
	      		this.ng_users_first_time_loaded = true;
	      		let rol = this.gs.getRole();
	    		if(rol != 5){
	    			let url_plus = '';
	    			if(this.ng_group_filters!= undefined){
	    				let type = 2;
	    				let gid = 0;
	    				if(this.ng_group_filters.division != 0) { type = 4; gid = this.ng_group_filters.division; }
	    				else if(this.ng_group_filters.department != 0) { type = 3; gid = this.ng_group_filters.department; }
	    				else if(this.ng_group_filters.branch != 0){  type = 2; gid = this.ng_group_filters.branch; }	    					   
	    				url_plus = "&id_grupo="+gid+"&id_tipo_grupo="+type;
	    			}
	    			let uri = '/usuario/vista?id_estado=1&id_rol=5' + url_plus;	
	    			  	
			    	this.httpService.getHttpAuth(uri).subscribe(x => {
			    		this.ng_users_list = x.data.map((user) => {	    			
			    			return {
			    				id : user.id_usuario, 
			    				nombre : '' + ((user.codigo) ? user.codigo + ' - ' : '') + user.nombre + ' ' + user.apellido
			    			};          			
			    		});
			    		this.ng_selected_user = this.ng_users_list.find(u => u.id == this._route.snapshot.params.id);			    		  		
			    	});
	    		}
	      	}      	
	    });
 	}

 	highlight(id) {
  		for(let i = 0; i < this.dataCards.length; i++) {
  			this.dataCards[i].highlight = false;
  			let indx = this.dataCards[i].conceptos.indexOf(id);  			
  			if(indx != -1) {
  				if(this.dataCards[i].highlight) this.dataCards[i].highlight = false;
  				else this.dataCards[i].highlight = true;
  			}
  		}
  		window.scroll(0,0);
  	}




  	deleteRange(i){
	    this.marks.splice(i,1);

	    if(this.marksaux[i].entrada.id && typeof(this.marksaux[i].entrada.id) != 'string')
	      this.deletedIds.push(this.marksaux[i].entrada.id);
	    
	    if(this.marksaux[i].salida.id && typeof(this.marksaux[i].salida.id) != 'string')
	      this.deletedIds.push(this.marksaux[i].salida.id);
	    this.marksaux.splice(i,1);
	    this.wasModified = true;
	    this.changeHours();
  	}

    clickOnTimePicker(value,i,id,type,mark){
      const amazingTimePicker = this.atp.open({
        time: this.formatHoursPipe.transform(value,"hh:mm A","HH:mm"),
      });
      var markaux = this.marksaux;
      amazingTimePicker.afterClose().subscribe(time => {

        if(type=='entry')
          this.marks[i].entrada=
          {
            hora:moment(time,"HH:mm").format("hh:mm A"),
            id:(mark.entrada.id ? mark.entrada.id : null),
            tipo: mark.entrada.tipo
          };
        else
          this.marks[i].salida={
            hora:moment(time,"HH:mm").format("hh:mm A"),
            id:mark.salida.id ? mark.salida.id : null,
            tipo: mark.salida.tipo
        };
        /*if(type=='entry')
          this.marksaux[i].entrada=
          {
            hora:moment(time,"HH:mm").format("hh:mm A"),
            id:(mark.entrada.id ? mark.entrada.id : null),
            tipo: mark.entrada.tipo
          };
        else
          this.marksaux[i].salida={
            hora:moment(time,"HH:mm").format("hh:mm A"),
            id:mark.salida.id ? mark.salida.id : null,
            tipo: mark.salida.tipo
        };*/
        markaux.forEach(function(value,index){
          if(type == 'entry'){
            if(value.entrada.id == id){
              value.entrada.hora = moment(time,"HH:mm").format("hh:mm A");
            }
            if(value.salida.id == id){
              value.salida.hora =  moment(time,"HH:mm").format("hh:mm A");
            }
          }else{
            if(value.entrada.id == id){
              value.entrada.hora = moment(time,"HH:mm").format("hh:mm A");
            }
            if(value.salida.id == id){
              value.salida.hora = moment(time,"HH:mm").format("hh:mm A");
            }
          }
        });
        // this.marksaux = markaux;
        console.log(this.marks);
        console.log(this.marksaux);
        this.wasModified = true; 
        this.changeHours();

      });
    }


    print(option) {
    	if(option == 'open') {
    		this.modals.print.object.open();
    	}
    	if(option == 'print') {
    		let HTML = this.pdf.nativeElement.children[0].innerHTML;     	
   			var mywindow = window.open("'PRINT', 'height=400,width=1200'");
   			mywindow.document.write(HTML);
   			mywindow.document.close();
      		mywindow.focus();
      		mywindow.print();
      		mywindow.close();
      		this.modals.print.object.dismiss();
    	}   	
    }

    modifyEntrada(i , id){
      console.log(this.marks);
      console.log(i);
      console.log(id);
      this.wasModified = true;
      var mark = this.marks
      var markaux = this.marksaux;
      markaux.forEach(function(value,index){
        if(value.entrada.id == id){
          value.entrada.hora = mark[i].entrada.hora;
        }
        if(value.salida.id == id){
          value.salida.hora =  mark[i].entrada.hora;
        }
      });
      console.log(markaux);
      this.marksaux = markaux;
    }

    modifySalida(i , id){
      console.log(this.marks);
      console.log(i);
      console.log(id);
      this.wasModified = true;
      var mark = this.marks
      var markaux = this.marksaux;
      markaux.forEach(function(value,index){
        if(value.entrada.id == id){
          value.entrada.hora = mark[i].salida.hora;
        }
        if(value.salida.id == id){
          value.salida.hora = mark[i].salida.hora;
        }
      });
      console.log(markaux);
      this.marksaux = markaux;
    }

    clickOnTimePickerExtra(value,i,type, io){    	
 
      const amazingTimePicker = this.atp.open({
        time: this.formatHoursPipe.transform(value,"hh:mm A","HH:mm"),
      });
      amazingTimePicker.afterClose().subscribe(time => {

        var hour = moment(time,"HH:mm").format("hh:mm A");
    
        if(type=='entry' && io=='init')
          this.extraObject.inicio.entrada.hora= hour;
        else if(type=='exit' && io=='init')
          this.extraObject.inicio.salida.hora= hour;
        else if(type=='entry' && io=='end')
          this.extraObject.fin.entrada.hora= hour;
        else if(type=='exit' && io=='end')
          this.extraObject.fin.salida.hora= hour;      

      });
    }


    reason(i,e){
      let type  = e.target.value;
      this.marks[i].id_concepto=type;
      this.marksaux[i].id_concepto=type;
    }

    changeHours():void {

      var seconds=0;
      for (let i = 0; i < this.marks.length; i++) {

        if(this.marks[i].entrada.hora && this.marks[i].salida.hora){

          var momentE=moment(this.formatHoursPipe.transform(this.marks[i].entrada.hora,"hh:mm A","HH:mm"),"hh:mm");
          var momentS=moment(this.formatHoursPipe.transform(this.marks[i].salida.hora,"hh:mm A","HH:mm"),"hh:mm");

          var duration = momentS.diff(momentE, 'seconds');
          seconds+=duration;
          var formatted = moment.utc(duration*1000).format('HH:mm');
          this.marks[i].horas=formatted;
        }
        
          
      }

      for (let i = 0; i < this.marksaux.length; i++) {

        if(this.marksaux[i].entrada.hora && this.marksaux[i].salida.hora){

          var momentE=moment(this.formatHoursPipe.transform(this.marksaux[i].entrada.hora,"hh:mm A","HH:mm"),"hh:mm");
          var momentS=moment(this.formatHoursPipe.transform(this.marksaux[i].salida.hora,"hh:mm A","HH:mm"),"hh:mm");

          var duration = momentS.diff(momentE, 'seconds');
          seconds+=duration;
          var formatted = moment.utc(duration*1000).format('HH:mm');
          this.marksaux[i].horas=formatted;
        }
        
          
      }

      var getTotal = moment.utc(seconds*1000).format('HH:mm')
      this.total=getTotal;

      if(seconds>(this.journyLimit*60)){
        this.aproveHours=true;
        this.extraHours=moment.utc((seconds-(this.journyLimit*60))*1000).format('HH:mm');
        this.regularHours=moment.utc((this.journyLimit*60)*1000).format('HH:mm');
      }
      else{
        this.extraHours="12:00 PM";
        this.aproveHours=false;
        this.regularHours=getTotal;
      }
    }

    addNewMark(){
      this.wasModified = true;
      console.log("hereeeee");
      var counter = this.counter;
      var auxcounter = counter +1;
      this.marks.push({
        "entrada":{hora: "", id:'id_' + counter,tipo:"admin"},
        "salida":{hora: "", id:'id_' + auxcounter,tipo:"admin"},
        "id_concepto":this.modelConcept[0].id

      });

      this.marksaux.push({
        "entrada":{hora: "", id:'id_' + counter,tipo:"admin"},
        "salida":{hora: "", id:'id_' + auxcounter,tipo:"admin"},
        "id_concepto":this.modelConcept[0].id

      });
      this.counter = this.counter +2;
      console.log(this.marks);
      console.log(this.marksaux);
      this.changeHours();
    }

    addExtra(){
      this.extra.push({
        entrada:{hora:null,id:null},
        salida:{hora:null,id:null},
        id_tipo:"hora_extra",
        nota:""
      });
    }

    deleteExtra(i){
      this.extra.splice(i,1);
    }

    openMap(date,length,free){
      this.counter = 1;
      this.selectedDate=date;
      this.changeHours();
      (<any>$("#modalMarksMap")).modal("show");
    }

    openMarks(schedule){
      this.marks=[];
      this.marksaux =[];
      this.wasModified = false;
      this.message_modified="";
      this.total="0";
      this.regularHours = "0";
      this.extraHours = "0";
      this.calculatedTime=[];
      this.selectedDate=schedule.fecha;
      this.extra = [];
      this.first_dist = [];
      this.deletedIds = [];
      this.modals.approve.modal_view = 1;
      this.extra.push({
        marcacion: {
          entrada:{hora:null,id:null},
          salida:{hora:null,id:null}
        },
        id_tipo:"hora_extra",
        nota:""
      });
      this.modals.approve.title = 'Edición y aprobación de marcaciones';
      this.currentPunchDay = null;
     
      var {id_marcacion} = schedule;
      this.ng_hide_approve_buttons = false;
      if(id_marcacion){
        this.id_marcacion=id_marcacion;
        schedule.button.disabled=true;
        schedule.button.isLoading=true;

        this.httpService.getHttpAuth("/marcacion/"+id_marcacion+"/1",schedule.button).
        subscribe(data => {
          schedule.button.disabled=false;
          schedule.button.isLoading=false;
          var marcaciones = data.data.marcaciones;
          this.currentPunchDay = data.data.marcacion;
          this.modelConcept=data.data.conceptos;        
          this.scheduleInfo=data.data.horario;
          this.first_dist = data.data.distribucion;

          if(this.gs.getRole() == 4 && !this.gs.getPermission().editar){
          	this.btn15.disabled = true;
          	this.btn16.disabled = true;
          }         
          
          for (let i = 0; i < marcaciones.length; i++) {
              var marcacion = marcaciones[i];

              this.marks.push({
                "entrada": {
                  hora: (marcacion.entrada ? this.formatHoursPipe.transform(marcacion.entrada.hora,"HH:mm","hh:mm A") : null),
                  id:(marcacion.entrada ? marcacion.entrada.id : null),
                  tipo:marcacion.entrada.tipo
                },
                "salida": {
                  hora: (marcacion.salida ? this.formatHoursPipe.transform(marcacion.salida.hora,"HH:mm","hh:mm A") : null),
                  id:(marcacion.salida ? marcacion.salida.id : null),
                  tipo:(marcacion.salida ? marcacion.salida.tipo : "admin")
                },
                "id_concepto":marcacion.id_concepto
              });
              this.marksaux.push({
                "entrada": {
                  hora: (marcacion.entrada ? this.formatHoursPipe.transform(marcacion.entrada.hora,"HH:mm","hh:mm A") : null),
                  id:(marcacion.entrada ? marcacion.entrada.id : null),
                  tipo:marcacion.entrada.tipo
                },
                "salida": {
                  hora: (marcacion.salida ? this.formatHoursPipe.transform(marcacion.salida.hora,"HH:mm","hh:mm A") : null),
                  id:(marcacion.salida ? marcacion.salida.id : null),
                  tipo:(marcacion.salida ? marcacion.salida.tipo : "admin")
                },
                "id_concepto":marcacion.id_concepto
              });
          }
          this.changeHours();
          this.modals.approve.object.open();
        });
      }else{
        var data = {
            "fecha": schedule.fecha,
            "id_usuario":this._route.snapshot.params.id
        };
        schedule.button.disabled=true;
        schedule.button.isLoading=true;
        this.httpService.postHttpAuth("/marcacion",data)
        .subscribe( x => {
            
            this.httpService.getHttpAuth("/marcacion/"+x.data.marcacion.id+"/1",schedule.button).
            subscribe(data => {
                this.id_marcacion=x.data.marcacion.id;
                this.scheduleInfo=data.data.horario;
                schedule.id_marcacion=x.data.marcacion.id;
                schedule.button.disabled=false;
                schedule.button.isLoading=false;
                this.modelConcept=x.data.conceptos;
                this.marks=[];
                this.marksaux=[];
                this.modals.approve.object.open();
                //(<any>$("#modalMarks")).modal({backdrop: 'static', keyboard: false,show:true});
            }, err=> {
                schedule.button.disabled=false;
                schedule.button.isLoading=false;
            });
            
            
        },err => {
            schedule.button.disabled=false;
            schedule.button.isLoading=false;
        });

      }

    }

    save(type){    
      this.sendChecks(type);
    }

    sendChecks(type):void{
        var clone = JSON.parse(JSON.stringify(this.marksaux));       
        var checks = [];
        var data = <any>{};
        var self = this;
        clone.forEach(function(value,index){
          	if(typeof(value.entrada.id) == 'string'){
            	value.entrada.id = null;
         	}
          	if(typeof(value.salida.id) == 'string'){
            	value.salida.id = null;
          	}
          	checks.push({
            	"entrada": {
              		hora: (value.entrada.hora ? self.formatHoursPipe.transform(value.entrada.hora,"hh:mm A","HH:mm") : null),
              		id:(value.entrada.id ? value.entrada.id : null),
              		tipo: value.entrada.tipo
            	},
            	"salida": {
              		hora: (value.salida.hora ? self.formatHoursPipe.transform(value.salida.hora,"hh:mm A","HH:mm") : null),
              		id:(value.salida.id ? value.salida.id : null),
              		tipo: value.salida.tipo
            	},  
            	"id_concepto":value.id_concepto
          	});
        });

        this.btn11.disabled = true;
        this.btn12.disabled = true;
        this.btn15.disabled = true;
        this.btn16.disabled = true;
        if(this.marks.length == 0 || type == 'save') {
        	if(this.marks.length == 0) type = 'save';
        	this.isLoadingApprove = true;
        	this.btn11.isLoading = true;
        } else {        	
            this.btn12.isLoading = true;

        }
        console.log(clone);
      	/*  var checks = [];
        var data = <any>{};*/
        this.extra = [];
        this.extraObject = {};
        /*for (let i = 0; i < clone.length; i++) {
            let current= clone;

            checks.push({
              "entrada": {
                hora: (current['entrada']['hora'] ? this.formatHoursPipe.transform(current['entrada']['hora'],"hh:mm A","HH:mm") : null),
                id:(current['entrada'] ? current['entrada'].id : null),
                tipo: current['entrada'].tipo
              },
              "salida": {
                hora: (current['salida'].hora ? this.formatHoursPipe.transform(current['salida'].hora,"hh:mm A","HH:mm") : null),
                id:(current['salida'] ? current['salida'].id : null),
                tipo: current['salida'].tipo
              },  
              "id_concepto":current['id_concepto'],
            });
        }*/

        data.marcaciones=checks;
        data.marcaciones_eliminadas = this.deletedIds;
        data.modificado =  this.wasModified;
        data.comentario = this.message_modified;
        //this.isLoadingApprove=true;
        this.extraEnable=0;
        this.httpService.putHttpAuth("/marcacion/"+this.id_marcacion, data).subscribe(x => {
            this.message_modified="";             
            if(type=='saveandapprove') {
              	//this.isLoadingApprove=true;              	
              	this.httpService.getHttpAuth("/marcacion/"+this.id_marcacion+"/2",).subscribe(data => {                
	                //this.isLoadingApprove=false;	           
	                //this.changingValue.next(0);
	                //this.modals.approve.dismiss();
	                //this.modals.distribution.open();
	                this.checksProccesed = data.data.marcaciones;
	                this.scheduleInfo = data.data.horario;                
	                if(data.data.hora_extra && data.data.hora_extra.inicio){
	                  	// this.extraEnable++;
	                  	this.extraObject.inicio = {  
	                    	"entrada":(data.data.hora_extra.inicio.entrada ? {
	                      		"hora":this.formatHoursPipe.transform(data.data.hora_extra.inicio.entrada.hora,"HH:mm","hh:mm  A"),
	                      		"id":data.data.hora_extra.inicio.entrada.id
	                    	} : null),
	                    	"salida":(data.data.hora_extra.inicio.salida ? {
	                        	"hora":this.formatHoursPipe.transform(data.data.hora_extra.inicio.salida.hora,"HH:mm","hh:mm  A"),
	                        	"id":data.data.hora_extra.inicio.salida.id
	                      	} : null),
	                    	"approve":false
	                  	};
	                  	console.log(this.extraObject);
	                }
	              
	                
	                if(data.data.hora_extra && data.data.hora_extra.fin){
	                  	// this.extraEnable++;
	                  	this.extraObject.fin = {  
	                    	"entrada":(data.data.hora_extra.fin.entrada ? {
	                        	"hora":this.formatHoursPipe.transform(data.data.hora_extra.fin.entrada.hora,"HH:mm","hh:mm  A"),
	                        	"id":data.data.hora_extra.fin.entrada.id
	                    	}: null),
	                    	"salida":(data.data.hora_extra.fin.salida ? {
	                        	"hora":this.formatHoursPipe.transform(data.data.hora_extra.fin.salida.hora,"HH:mm","hh:mm  A"),
	                        	"id":data.data.hora_extra.fin.salida.id
	                    	} : null),
	                    	"approve":false
	                	};
	                }
	               	this.extraComment = "";
	              	this.btn11.disabled = false;
              		this.btn12.disabled = false;
              		this.btn15.disabled = false;
        			this.btn16.disabled = false;
              		this.btn12.isLoading = false;
              		this.modals.approve.modal_view = 2;
	                //(<any>$("#modalMarks")).modal("hide");
	                //(<any>$("#modalExtra")).modal({backdrop: 'static', keyboard: false,show:true});                  
            	},x => {
            		this.btn11.disabled = false;
              		this.btn12.disabled = false;
              		this.btn15.disabled = false;
        			this.btn16.disabled = false;
              		this.btn12.isLoading = false;
              		//this.isLoadingApprove=false
              	});

            }else{
              	this.loadPeriod(null);
              	this.isLoadingApprove=false;
              	this.modals.approve.object.dismiss();
              	//this.modals.approve.dismiss();
              	//(<any>$("#modalMarks")).modal("hide");
              	this.btn11.disabled = false;
              	this.btn12.disabled = false;
              	this.btn15.disabled = false;
        		this.btn16.disabled = false;
              	this.btn11.isLoading = false;
              	this.alertService.success("Marcaciones guardadas de manera correcta");
            }
         }, x => {
          	this.isLoadingApprove = false;
          	this.btn11.disabled = false;
            this.btn11.isLoading = false;
            this.btn12.isLoading = false;
            this.btn15.disabled = false;
        	this.btn16.disabled = false;
            this.btn12.disabled =false;
      	});
    }

    onPrevNext(type) {
    	let step = this.modals.approve.modal_view;
    	if(type == 'next') step += 1;
    	if(type == 'prev') step -= 1;

    	if(step == 3) this.onStep1Next();
    	//else if(step == 4) this.onStep2Next();
    	else this.modals.approve.modal_view = step;    	
    }

    onStep1Next(){      
      	this.btn14.disabled = true;
      	this.btn14.isLoading = true;
      	this.isLoadingExtra = true;
      	this.calculatedTime = [];
      	this.extraStructure = {
        	"inicio": ( this.extraObject.inicio && this.extraObject.inicio.approve ? {
          		"entrada":{
            		"hora":this.formatHoursPipe.transform(this.extraObject.inicio.entrada.hora,"hh:mm  A","HH:mm"),
            		"id":this.extraObject.inicio.entrada.id
          		},
          		"salida":{
            		"hora":this.formatHoursPipe.transform(this.extraObject.inicio.salida.hora,"hh:mm  A","HH:mm"),
            		"id":this.extraObject.inicio.salida.id
          		},
          		aprobar: true,
          		id_tipo:null
        	} : null ),
        	"fin":( this.extraObject.fin && this.extraObject.fin.approve ? {
          		"entrada":{
            		"hora":this.formatHoursPipe.transform(this.extraObject.fin.entrada.hora,"hh:mm  A","HH:mm"),
            		"id":this.extraObject.fin.entrada.id
          		},
          		"salida":{
            		"hora":this.formatHoursPipe.transform(this.extraObject.fin.salida.hora,"hh:mm  A","HH:mm"),
            		"id":this.extraObject.fin.salida.id
          		},
            	aprobar: true,
            	id_tipo:null
        	} : null ),
        	nota: this.extraComment
      	};      
      
      	if(!this.extraStructure.inicio && !this.extraStructure.fin) this.extraStructure=null;          
      	var data  = {"pre" : true, "extra" :this.extraStructure, override: false };     
      	this.httpService.postHttpAuth("/marcacion/aprobacion/"+this.id_marcacion,data).subscribe(data => {
          	//this.isLoadingExtra=false;      
      		this.btn14.disabled = false;
      		this.btn14.isLoading = false;
      		this.isLoadingExtra = false;
          	this.calculatedTime = data.data.distribucion;
          	//this.modals.approve.modal_view = 3;
          	this.modals.approve.object.dismiss();          
          	this.ng_modal_distribution.open.next(data.data); 

        }, err => {        	
      		this.btn14.disabled = false;
      		this.btn14.isLoading = false;
      		this.isLoadingTab = false; 
          	this.isLoadingExtra=false;
          	this.modals.approve.modal_view = 2;
          	//this.changingValue.next(0);
        });
    }

    onStep2Back() {
    	this.modals.approve.modal_view = 2;
    	this.modals.approve.object.open();
    }


    confirm_punches_approval(override, confirm) {    
    	this.isLoadingExtra = true;
    	if(confirm) {    	
    		var data  = { "pre" : false, "extra" : this.extraStructure, override: override };  
    		this.httpService.postHttpAuth("/marcacion/aprobacion/"+this.id_marcacion, data).subscribe(data => { 
    			if(data.data.error) {
    				this.confirm_overtime_msg = data.data.msj;
    				this.ng_modal_distribution.close.next(); // hides distribution modal 
    				this.modals.confirm_overtime.next(); // opens confirm modal
    			} else {
    				if(!override) this.ng_modal_distribution.close.next();    				 
    				this.loadPeriod(this.id_period);
        			this.isLoadingExtra = false;    
        			this.alertService.success("Día aprobado de manera correcta");    	
    			}
    					
    		}); 
    	}
    }  

    /*onStep2Next(){     
      	var data  = { "pre" : false, "extra" : this.extraStructure, override: false };      
      	this.container.clear();
      	const factory = this.resolver.resolveComponentFactory(<any>ModalConfirmComponent);
      	this.componentRef = this.container.createComponent(factory);
      	this.componentRef.instance.open();
      	this.componentRef.instance.Confirm.subscribe(x => {        	
        	this.modals.approve.modal_view = 4;
        	this.componentRef.instance.justDismiss();
        	this.proccessApprove(data);        		
      	});
      	this.componentRef.instance.Close.subscribe(x => {
        	this.componentRef.instance.justDismiss();
        	this.modals.approve.modal_view = 3;         	
      	});
    }
    
    proccessApprove(data):void{
      	this.isLoadingExtra=true;   
      	this.btn13.disabled = true;
      	this.btn14.disabled = true;
      	this.btn14.isLoading = true; 
      	this.httpService.postHttpAuth("/marcacion/aprobacion/"+this.id_marcacion,data).subscribe(data_backend => {     
        	if(data_backend.data.error)
        	{ 
	          	this.container.clear();
	          	const factory = this.resolver.resolveComponentFactory(<any>ModalConfirmComponent);
	          	this.componentRef = this.container.createComponent(factory);
	          	this.componentRef.instance.title = data_backend.data.msj;
	          	this.componentRef.instance.open();
	          	this.componentRef.instance.Confirm.subscribe(x => {
            		this.isLoadingExtra=false;      
            		data.override=true;
            		this.componentRef.instance.justDismiss();
            		this.proccessApprove(data);
                	this.modals.approve.object.dismiss();            		
            		return 0;
          		});
	          	this.componentRef.instance.Close.subscribe(x => {
	            	this.isLoadingExtra=false;    
	            	this.componentRef.instance.justDismiss();
	            	this.modals.approve.modal_view = 3;
	            	this.btn13.disabled = false;
      				this.btn14.disabled = false;
      				this.btn14.isLoading = false; 
	          	});
          		return 0;
        	}
        	this.btn13.disabled = false;
      		this.btn14.disabled = false;
      		this.btn14.isLoading = false; 
        	this.modals.approve.object.dismiss();
        	this.loadPeriod(this.id_period);
        	this.isLoadingExtra = false;    
        	this.alertService.success("Día aprobado de manera correcta");
      	});
    }*/
    
    savePeriod(type) {  
    	//console.log(this.modals.confirm_verified);
    	//this.modals.confirm_verified.next(); //abre modal confirm;
    	//return;

    	this.isLoadingApprove = true;
    	var $this = this;
    	this.httpService.postHttpAuth("/periodo/verificar",{id : this.id_period, pre : type }).subscribe(x => {
          	let data = x.data;
          	this.isLoadingApprove = false;
          	if(type==1) {
            	if(data.success==false) {        
              		for (let i = 0; i < this.dataCards.length; i++) {
                  		for (let k = 0; k < data.dias.length; k++) {
                     		var data_moment = moment(this.dataCards[i].fecha,"DD-MM-YYYY").format("DD-MM-YYYY");        
		                    if(data.dias[k].fecha==data_moment) {
		                    	let bdg = "badge pos-absolute bagde-notificacion badge-danger extra";
		                        this.dataCards[i].button.badgeClass = bdg;
		                        this.dataCards[i].button.useBadge = true;
		                        this.dataCards[i].button.badgeIcon = "fa fa-exclamation";
		                    }
                  		}
              		}
              		this.alertService.danger("Los días señalados requieren aprobación antes de procesar el periodo");
            	} else {
            		let temp = [];
  					data.modo = 3;
  					//data.usuario = data.detallado[0].usuario;
  					data.show_buttons = true;	 
  		 			data.emit_fn = function() {
  		 				$this.savePeriod(2); 		 				
  		 			}
  		 			this.ng_modal_distribution.open.next(data);  		
	         
            	}
        	} else {
            	this.alertService.success("Periodo aprobado de forma correcta");            	
            	this.ng_modal_distribution.close.next();
            	this.loadPeriod(this.id_period);
        	}
    	}, error =>  { this.isLoadingApprove = false;  });
    }

    dailySearchDistribution(type){
      if(type=='prev')
        this.indexDailyDistribution--;
      else
      this.indexDailyDistribution++;
    }

    ng_logs_and_comments(horario) {
        horario.buttonLogs.isLoading=true;
        horario.buttonLogs.disabled=true;
        this.httpService.getHttpAuth("/marcacion/logs/"+horario.id_marcacion,horario.buttonLogs).subscribe(x => {
          	horario.buttonLogs.isLoading=false;
          	horario.buttonLogs.disabled=false;
          	this.logs=x.data;
          	if(this.logs.length==0){
            	this.alertService.info("No existen cambios para este día");
            	return 0;
          	}
          	this.modals.history.object.open();          	
        });
    }

    disapprovePunch(){    	
    	this.modals.approve.object.dismiss();
      	this.httpService.postHttpAuth('/marcacion/anulacion/'+this.id_marcacion,{}).subscribe(x => {
        	this.alertService.success("Marcación anulada de manera correcta");        	
        	this.loadPeriod(null);
      	});
    }

    call_confirm_disapprovePunch(id_marcacion){
    	this.id_marcacion = id_marcacion;
    	this.modals.confirm_disapprove.next();
    }

    confirm_disapprove(confirm) {
    	if(confirm) {
       		this.disapprovePunch();
    	}
    }

    moveChecks() {
    	this.isLoadingApprove = true;
    	this.httpService.postHttpAuth('/periodo/ordenar/'+this.id_period,{})
  		.subscribe(x => {
  			this.isLoadingApprove = false;
    		this.alertService.success(x.data);
    		this.loadPeriod(this.id_period);
    	});
    }

    desapprovePeriod(){
      this.httpService.postHttpAuth('/periodo/anular/'+this.id_period,{})
      .subscribe(x => {
        this.alertService.success("Periodo anulado de manera correcta");
        this.modals.approve.object.dismiss();
        //(<any>$("#modalMarks")).modal("hide");
        this.loadPeriod(null);
      });
    }
    
    changeExtra(extra){
      if(extra)
        this.extraEnable++;
      else
        this.extraEnable--;
    }

   

    ngOnDestroy(){
      this.dragula.destroy('dragula-time');
      this.dragula.destroy('random');
      this.dragulaSub.unsubscribe();
    }

    // buttons objects at the end of the class

    btn11: any = {
	    text:"Guardar",
	    disabled: false,
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin",
	    isLoading:false,
	    principal_icon:"fa fa-floppy-o",
	};

    btn12 : any = {
	    text:"Guardar y Aprobar",
	    disabled: false,
	    type:"btn btn-success",
	    icon:"fa fa-spinner fa-spin",
	    isLoading:false,
	    principal_icon:"fa fa-floppy-o"
  	};
  
  	btn14 : any = {
	    text:"Siguiente",
	    disabled: false,
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin",
	    isLoading:false	    
  	};

  	btn15 : any = {
	 	principal_icon : "fa fa-minus",
	 	onlyIcon : true,
	    disabled: false,
	    isLoading:false,   
	    type:"btn btn-danger"	    
  	};

  	btn16 : any = {
	    principal_icon : "fa fa-plus",
	    onlyIcon : true,
	    disabled: false,
	    isLoading:false,  
	    type:"btn btn-primary"	  
  	};
}
