import { Component, OnInit, Input } from '@angular/core';
import {Router, ActivatedRoute } from "@angular/router";
import { SharedService } from "./../../../services/eventsEmmiter/shared.service";
import {environment} from './../../../../environments/environment'
import { GlobalsService } from '@services/globals/globals.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	@Input('user') user : any; // logued user
	configJanusSuite: any = null;
	openConfig: boolean=false;
  	//openMarks: boolean=false;
  	openSchedules: boolean=false;
  	openUsers: boolean= false;
  	openReports: boolean = false;
  	openCards : boolean = false;
  	pv_admin : boolean = false;
  	permissions;
  	token  = '';
  	role : number;

  	visibility : any = {
  		inicio : true,
  		usuarios :{ val : true, children : { administradores : true, colaboradores : true}},
  		mis_datos : true,
  		marcaciones : {val : true, children : {tarjetas : true, conceptos : true, preaprobacion : true}},
  		solicitudes : true,
  		cartas : true,
  		horarios : {val: true, children : {grupos :  true, mensual : true}} ,
  		reportes : true,
  		configuracion : {val : true, children : {compania : true, reloj : true, plantilla : true, feriado : true}},
  		cerrar : true
  	}	

   	constructor(private _sharedService: SharedService, private router: Router,  public gs: GlobalsService) {
    	_sharedService.changeEmitted$.subscribe(
      		info => {
        		this.globalEvent(info)
      		}
    	);
    	this.role = this.gs.getRole();   
    	this.config_visibility(); 	
  	}

  	config_visibility() {
  		let remove_edit_privileges = this.gs.getPermission().admin_remover_privilegios_edicion;	
  		if(this.role == 3) { // user admin
  			this.visibility.mis_datos = false;
  			this.visibility.cartas = false;  			
  		}
  		if(this.role == 7){ // user IT
  			this.visibility.mis_datos = false;
  			this.visibility.marcaciones.val = false;  			
  			this.visibility.solicitudes = false;
  			this.visibility.cartas = false;
  			this.visibility.horarios.val = false;  			
  			this.visibility.reportes = false;
  			this.visibility.configuracion.children.compania = false;
  			this.visibility.configuracion.children.plantilla = false;
  			this.visibility.configuracion.children.feriado = false;
  		}

  		if(this.role == 5) { // user EMPLOYEE
  			this.visibility.usuarios.val = false;  			
  			this.visibility.marcaciones.children.conceptos = false;
  			this.visibility.marcaciones.children.preaprobacion = false;
  			this.visibility.horarios.val = false;  			
  			this.visibility.reportes = false;
  			this.visibility.configuracion.val = false;  			
  		}

  		if(this.role == 4) { // user supervisor
  			this.visibility.mis_datos = false;
  			this.visibility.cartas = false;
  			this.visibility.configuracion.val = false;  			
  		}
  		
  		// permisos de privilegios usualmente aplicado a usuarios administradores
  		if(remove_edit_privileges) {
  			this.visibility.marcaciones.children.conceptos = false;
  			this.visibility.marcaciones.children.preaprobacion = false;
  			this.visibility.solicitudes = false;
  			this.visibility.horarios.val = false;
  			this.visibility.configuracion.children.compania = false;
  		}
  	}

  	globalEvent(info) {  		
    	this.router.navigate(["/"+info.type, info.id ]);
  	}
  
  

  	ngOnInit() {
  		this.configJanusSuite = environment.suite_conf;  
  		this.token = localStorage.getItem("token");  
  			
  	}

  	openDrop(type){   		
    	if(type=='config') this.openConfig=!this.openConfig;
    	else if (type=='users') this.openUsers=!this.openUsers;
    	//else if(type=='marks') this.openMarks=!this.openMarks;
    	//else if(type=='openReports') this.openReports=!this.openReports;
    	else if(type=='openSchedules') this.openSchedules=!this.openSchedules;
    	else if(type=='reports') this.openReports=!this.openReports;  
    	else if (type=='openCards')  this.openCards=!this.openCards;	
  	}
  	
  	logout(){
    	localStorage.removeItem("user");
    	localStorage.removeItem("token");
    	localStorage.removeItem("group");
    	localStorage.removeItem("filters_cards");	
    	localStorage.removeItem("filters_users");	
    	this.router.navigate(["/login"]);
  	}



}