import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import {Router, ActivatedRoute} from "@angular/router";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {	
	@ViewChild(DataTableDirective) dtElement: DataTableDirective;	
  	dtTrigger: Subject<any> = new Subject(); 	
  	dtOptions = {
		pagingType: 'full_numbers',
		/*dom: 'Bfrtip',
		buttons: [
			{ extend: 'print', text: 'Imprimir', title: 'Reporte de Inconsistencias' },
			{ extend: 'excel', title: 'Reporte de Inconsistencias' }   
		],*/
		language : {
			url: "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
			emptyTable : "No existen registros en la búsqueda"
		},
		pageLength : 10, 
		ordering: true,
		sorting : false,
		aoColumns  : [],
		columns : [],
		aaData : []
	};	
  	isLoadingTab: boolean = false;
  	isLoading: boolean = false;
	dtRendered = true;
	ng_module : string;	
	ng_state : string;	
	txt_pendientes = 0;
	txt_fecha = '';
	txt_estado = '';

  	constructor(public httpService: HttpService, private alertService: AlertService, public cdr: ChangeDetectorRef, private router:Router) { }

    ngOnInit() {
		let auth = JSON.parse(localStorage.getItem("user"));
		let permissions = JSON.parse(localStorage.getItem("permissions"));
		if(![37,42,43,45].includes(auth.id_empresa) || auth.id_rol != 3 || permissions.admin_remover_privilegios_reportes) {
			this.router.navigate(['/dashboard']);
		}		
		this.ng_module = 'e';	
		this.ng_state = '0';
    	this.draw();    
  	} 

   	draw() : void {		
  		let uri = '/reporte/sincronizacion?modulo='+this.ng_module+'&estado='+this.ng_state; 
  		this.isLoadingTab = true;
  		this.isLoading = true;	
		this.dtRendered = false;		
  		this.httpService.getHttpAuth(uri).subscribe(data => {       		
			let backend = data.data;
			this.txt_estado = backend.stats.estado;
			this.txt_fecha = backend.stats.fecha;
			this.txt_pendientes = backend.stats.pendientes;
			let columns = [];
			let dt_data = [];				
			if(backend.headers.length== 0) {
				this.isLoadingTab = false;
				this.isLoading = false;
				this.dtRendered = true;
				this.cdr.detectChanges();	
				return;
			}
			dt_data = backend.data;							
			for(var i = 0; i < backend.headers.length; i++) { 
				columns.push({title : backend.headers[i], data : backend.headers[i]}); 			
			}
			this.dtOptions.columns = columns;
			this.dtOptions.aoColumns = columns;
			this.dtOptions.aaData = dt_data;
			this.cdr.detectChanges();		   			
			this.dtRendered = true;
			this.cdr.detectChanges();
			this.isLoadingTab = false;
			this.isLoading = false;
			this.cdr.detectChanges();				
     	});
  	}
}