import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import {HttpService} from '@services/Http/http.service';
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import { GlobalsService } from '@services/globals/globals.service';
import * as moment from "moment";

@Component({
  	selector: 'app-users-detail-component',
  	templateUrl: './users-detail-component.component.html',
  	styleUrls: ['./users-detail-component.component.scss']
})
export class UsersDetailComponentComponent implements OnInit {    
  	user : any;
  	me : any;
  	ng_title : string = '';
  	ng_show_punch_in_btn = false;
  	ng_day : any;
  	pv_admin : boolean = false;
  	//modal : any;
  	modals : any = {punch : null, change_pass : null};
  	ng_pass : any = { old : '', new : '', new2 : ''};
  	calendar : any;
  	permission : any = {};
  	isLoadingTab : boolean;  	
  	ng_time_label = '';
  	openModalCalendar = new Subject();
  	grploader : any = JSON.parse(localStorage.getItem('grupo'));  
  	type_user_filter: string = "3,4,5";
  	ng_role;
  	ng_selected_user;
  	ng_users_list = [];
  	btn_options = {
  		disabled : false,
  		isLoading : false,
  		type : "btn btn-default btn-block",
  		icon : "fa fa-spinner fa-spin",
  		text : "Registrar Marcación" 		
  	};

  	options: any = {
	    text:"Guardar",
	    disabled: false,
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin",
	    isLoading:false,
	    principal_icon:"fa fa-floppy-o"
  	};


  	constructor(
  		public gs: GlobalsService,
  		private http : HttpService, 
  		private alertService: AlertService, 
  		private router:Router,
  		private _route: ActivatedRoute,
  		private formatHoursPipe : FormatHoursPipe) { 
  		router.routeReuseStrategy.shouldReuseRoute = () => false;
  	}

    ngOnInit() {     
    	let rol = this.grploader.id_rol; 
    	this.ng_role = rol;
    	this.me = this.grploader.usuario;   
    	this.pv_admin = this.gs.getPermission().admin_remover_privilegios_edicion;	
    	this.calendar = null; 
    	this.permission = this.grploader.usuario.permisos;  
      	this.ng_day = {
	    	fecha : '',
	    	entrada : '',
	    	salida : '',
	    	descanso : '',
	    	minutos_extra : '',
	    	turno : ''
	    };
	    this.isLoadingTab = true;
	    if(rol != 5) {
	    	let uri = '/usuario/vista?id_estado=1,2&';
	    	if(rol == 3 || rol == 7) uri += 'id_rol=3,4,5';
	    	if(rol == 4) uri += 'id_rol=5';
	    	this.http.getHttpAuth(uri).subscribe(x => {
	    		this.ng_users_list = x.data.map((user) => {	    			
	    			return {
	    				id : user.id_usuario, 
	    				nombre : '' + ((user.codigo) ? user.codigo + ' - ' : '') + user.nombre + ' ' + user.apellido
	    			};          			
        		});
        		this.ng_selected_user = this.ng_users_list.find(u => u.id == this._route.snapshot.params.id);        		
	    	});
	    }
	 
    }

    open(type) {
    	if(type==1) { // change password
    		let no_username = (this.user.nombre_usuario == null || this.user.nombre_usuario == '') ? true : false;
    		let no_mail = (this.user.email == null || this.user.email == '') ? true : false;    		
    		if(no_username && no_mail){
    			let m = 'No puede reestablecer contraseña. Éste usuario no presenta email ni nombre de usaurio.';
    			this.alertService.danger(m);
    			return;
    		}
    		this.ng_pass.old = '';
    		this.ng_pass.new = '';
    		this.ng_pass.new2 = '';
    		this.modals.change_pass.open();
    	}else{ // punch (employees only)
    		let moment_date = moment(new Date());
	    	this.ng_day.fecha = moment(this.calendar.fecha, "YYYY-MM-DD").format("DD-MM-YYYY");
	    	this.ng_day.entrada = this.formattedHour(this.calendar.entrada); 
	    	this.ng_day.salida = this.formattedHour(this.calendar.salida); 
	    	this.ng_day.descanso = this.calendar.descanso;
	    	this.ng_day.minutos_extra = this.calendar.minutos_extra;
	    	this.ng_day.turno = this.calendar.turno;    	
	    	this.ng_time_label = moment_date.format("hh:mm A");
	    	this.modals.punch.open();    	
    	}    	
    }

    change_password() {
    	if(this.ng_pass.new != this.ng_pass.new2) {
    		this.alertService.danger('Las contraseñas no coiciden');
    		return;
    	}
    	if(this.ng_pass.new == '' || this.ng_pass.new2 == '') {
    		this.alertService.info('La nueva contraseña no puede estar en blanco');
    		return;

    	}
    	
    	let data = { 
    		id_usuario : this.user.id,
    		contrasena_nueva : this.ng_pass.new, 
    		contrasena_vieja : (this.me.id == this.user.id) ? this.ng_pass.old : null 
    	};   
    	this.options.disabled = true;
	    this.options.isLoading = true; 	    
	    this.http.postHttpAuth("/usuario/password", data).subscribe(x => {
	    	this.alertService.success("Contraseña guardada");
	      	this.modals.change_pass.dismiss();     
	      	this.options.disabled = false;
	      	this.options.isLoading = false;
    	}, error => {
    		this.options.disabled = false;
    		this.options.isLoading = false;
    	});  	
    }

    save_punch() {    	
    	this.options.disabled = true;
	    this.options.isLoading = true;    
	    this.http.postHttpAuth("/marcacion-detalle", {'tipo': 'web', 'marcacion_app': false }).subscribe(x => {
	    	this.alertService.success("Marcación realizada satisfactoriamente");
	      	this.modals.punch.dismiss();     
	      	this.options.disabled = false;
	      	this.options.isLoading = false;
    	}, error => {
    		this.options.disabled = false;
    		this.options.isLoading = false;
    	});  	
    }

    modalIsReady(modal, type) {
    	if(type == 1) this.modals.change_pass = modal; 
    	if(type == 2) this.modals.punch = modal;
    }

    loadUser(data) : void {	 
    	let moment_date = moment(new Date());  	
    	this.user = data;     	
    	this.ng_title = data.nombre +' '+data.apellido;    	
    	this.isLoadingTab = false;
       	this.http.getHttpAuth("/calendario/"+data.id+"?fecha="+moment_date.format("YYYY-MM-DD")).subscribe(x => {
    		this.calendar = x.data[0];      		
    		if(this.grploader.id_rol == 5 && this.user.id_rol == 5 && this.permission.marcar_web){
    			this.ng_show_punch_in_btn = true;
    		}   		
    	});
    } 

    savedUser(data) {
    	this.user = data.usuario;    	
    }

    changed(object) {    	
    	this.router.navigate(['/users', object.id]);    	
    }   

    formattedHour(date): void{
    	return this.formatHoursPipe.transform(date,"HH:mm","hh:mm A")
  	}

  	open_modal_calendar() {
  		this.openModalCalendar.next();
  	}
}