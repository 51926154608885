import {Directive, Component, Input, OnInit, Output, ElementRef, Renderer2, ViewChild, EventEmitter} from '@angular/core';
import { DomService } from'./modal-service';
import { Subject } from "rxjs/Subject";

@Component({
  	selector: 'app-modal-confirm',
  	templateUrl: './modal-confirm.component.html',
  	styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  
  	@Input() txt : string =  '';
  	@Input() open : Subject <any>;
  	@Output() confirm : EventEmitter <boolean>  = new EventEmitter();

  	confirm_method;


  /*@Input() icon;
  @Input() classbtn;
  @Input() description;
  navchange: EventEmitter<number> = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() Confirm = new EventEmitter();
  @Output() Close = new EventEmitter(); */

  	modal : any;

  	constructor() { }

  	ngOnInit() { 
  		this.open.subscribe((obj)=> { 
  			this.modal.open(); 
  			if(obj){
  				console.log(obj);
  				this.confirm_method = obj.multiple; 
  			}
  			
  		});   
  	}

  	modalIsReady(modal) {
  		this.modal = modal;
  	}

  	click(val) {
  				
  		if(this.confirm_method == undefined)this.confirm.emit(val);
  		else this.confirm_method.multiple(val, this.confirm_method.this);   // true or false.
  		this.modal.dismiss();
  	}  
  
}
