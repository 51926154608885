import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hexagon',
  templateUrl: './hexagon.component.html',
  styleUrls: ['./hexagon.component.scss']
})
export class HexagonComponent implements OnInit {

  constructor() { }
  @Input('divider') ng_show_divider : boolean;
  @Input('title') ng_title : string;
  @Input('fa') ng_font_awesome : string;

  ngOnInit() {
  }

}
