import { Component, OnInit,ViewChild, Input } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { DataTablesModule } from 'angular-datatables';
import { DataTableDirective } from 'angular-datatables';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { IgxDatePickerModule } from "igniteui-angular";
import { HttpService } from "@services/Http/http.service"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalsService } from '@services/globals/globals.service'
import * as moment from "moment"
@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {
    pagingType: 'full_numbers',
    "language": {
              "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    }
  };
  	from: Date;
  	holidays:any=[];
  	addDay: boolean = true;
  	initTime: any;
  	endTime: any;
  	date: Date;
  	free_day_checkbox: boolean=true;
  	isholiday47_checkbox : boolean = false;
  	name: string;
  	code: string;
  	pv_admin : boolean = false;
  	button: any = {
	    text:"Guardar",
	    disabled: false,
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin",
	    isLoading:false,
	    principal_icon:"fa fa-floppy-o"
  	};
  	constructor(
	  	public httpService: HttpService, 
	  	public gs: GlobalsService,
	  	private alertService: AlertService,
	  	private atp: AmazingTimePickerService, 
	  	private xf: IgxDatePickerModule) { }

  	ngOnInit() {
  		this.pv_admin = this.gs.getPermission().admin_remover_privilegios_edicion;
    	this.httpService.getHttpAuth("/holiday/get/all").subscribe( y => { 
    		this.holidays = y.data; 
    		this.dtTrigger.next(); 
    	});

  	}

  deleteHoliday(index,id){
    this.httpService.deleteHttpAuth("/holiday/"+id,{})
    .subscribe(x=>{
      this.holidays.splice(index,1);
      this.alertService.success("El dia feriado fue eliminado exitosamente");
      this.rerender();
    });
  }


  saveHoliday(){
    var data={
      "id_codigo" : this.code,
      "nombre" :  this.name,
      "fecha" : moment(this.date).format("DD-MM-YYYY"),
      "hora_inicio":(this.free_day_checkbox ? "00:00" : this.initTime),
      "hora_fin":(this.free_day_checkbox ? "23:59" : this.initTime),
      "libre":this.free_day_checkbox,
      "aplicar_articulo" : this.isholiday47_checkbox
    }
  
  
    this.button.isLoading = true;
    this.button.disabled = true;
    
    this.httpService.postHttpAuth("/holiday",data).
    subscribe(x => {
      this.button.isLoading = false;
      this.button.disabled = false;
      this.holidays.push(x.data);
      this.code="";
      this.name="";
      this.date=null;
      this.initTime="";
      this.initTime="";
      this.free_day_checkbox=true;
      this.rerender();
      this.alertService.success("Dia feriado agregado exitosamente");
    },
     x => {
      this.button.isLoading = false;
      this.button.disabled = false;
    });
    
  }
  
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
