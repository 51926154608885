import { Component, OnInit,ViewChild,Input, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { AfterViewInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { IgxDatePickerModule } from "igniteui-angular";
import { ContentLoaderChecksComponent } from "@app/globals/content-loader-checks/content-loader-checks.component"
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular"
import { config_hierarchy,findHierarchy } from "@app/companies/configCompany";
import { Schedule } from "@interfaces/schedule";

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal.module';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarDateFormatter,
  DAYS_OF_WEEK,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay
} from 'angular-calendar';
import 'hammerjs';
import * as moment from "moment"
import 'twix';

@Component({
  	selector: 'app-schedule',
  	templateUrl: './schedule.component.html',
  	styleUrls: ['./schedule.component.scss'],
  	providers: [
    	{
      		provide: CalendarDateFormatter,
      		useClass: CustomDateFormatter
    	}
  	]
})
export class ScheduleComponent implements OnInit {
  	@ViewChild(DataTablesModule)
  	@ViewChild("uidt") dpicker: IgxDatePickerComponent;
  	@ViewChild(DataTableDirective)
  	dtElement: DataTableDirective;
  	dtTrigger: Subject<any> = new Subject();
  	dtOptions: any = {
    	pagingType : 'full_numbers',
    	language : { url : "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json" }
  	};

  	isLoadingTab: boolean = true;
  	isEditing: boolean=true;
  	listMinutes: any = [0,5,10,15,20,25,30];
  	addDaysEventFromList:Subject<any> = new Subject();
	subjectAddDaysEdit:Subject<any> = new Subject();
	newDay:Subject<any> = new Subject();

	// buttons
  	options : any = {
    	text:"Guardar",
   	 	disabled: false,
    	type:"btn btn-primary",
    	icon:"fa fa-spinner fa-spin",
    	isLoading:false,
    	principal_icon:"fa fa-floppy-o"
  	};
  	edit_options : any = {
  		disabled : false,
  		type : "btn btn-success",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-pencil",
  		onlyIcon : true,
  		isLoading : false,
  	};
  	delete_options : any = {
  		disabled : false,
  		type : "btn btn-danger",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-trash",
  		onlyIcon : true,
  		isLoading : false,
  		delete: true
  	};


  	body : Schedule;
  	modal : any;
    grploader : any = JSON.parse(localStorage.getItem('grupo'));  
    id_empresa : number;  
    branches: object = [];
    departments: object = [];
    divisions : object = [];
    templates: any = []; 
    allSchedules : any;
    template : any; 

    ng_selected_branch : number;
    ng_selected_department : number;
    ng_selected_division : number; 
    ng_is_saving : boolean = false;
    ng_start_date : any;
    ng_end_date : any;
    ng_mode : string = 'create'; // mode of the interface. it can be create or update
    ng_id = null;

    resetFields : Subject<any> = new Subject();
    saveReady : Subject<any> = new Subject();
    load : Subject<any> = new Subject();
    schedule : any;


  	constructor(
  		public httpService: HttpService, 
  		private alertService: AlertService, 
  		private atp: AmazingTimePickerService, 
  		private xf: IgxDatePickerModule) { }

  	ngOnInit() {	    	   
	    this.id_empresa = this.grploader.grupos.empresa.id;
	    this.httpService.getHttpAuth("/horario/get/all").subscribe(x => {	    	
	        this.isLoadingTab=false;
	        this.allSchedules=x.data;
	        this.dtTrigger.next();
	    }); 
  	}

  	async open(id) {
  		if(!id) {
  			this.resetFields.next();
  			this.ng_mode = 'create';
  			this.modal.open();
  		} else {
  			this.ng_mode = 'update';
  			this.ng_id = id;
  			let data = <any> await this.httpService.asyncHttpAuth("GET", "/horario/"+id);
  			let schedule = data.data;
  			this.load.next(schedule);
  			this.modal.open();
  		}  		
  	}  	

  	save(data) { // modal function
  		if(this.ng_mode == 'create') {
  			if(data.nombre == "" || !data.nombre) {
  				this.alertService.info("Registre un nombre para este horario por favor");
  				return;
  			}
  			this.httpService.postHttpAuth("/horario", data).subscribe(data => {  
  				this.alertService.success('Horario creado satisfactoriamente');	
  				this.saveReady.next();
  				this.reloadSchedule();		
  				this.modal.dismiss();
  			}, error => {  	
  				this.saveReady.next();			
  				//this.modal.dismiss();
  			});
  		} else {
  			this.httpService.putHttpAuth("/horario/"+this.ng_id, data).subscribe(data => {
  				this.options.disabled = false;
  				this.options.isLoading = false;
  				this.saveReady.next();
  				this.reloadSchedule();
  				this.modal.dismiss();
  			}, error => {
  				this.options.disabled = false;
  				this.options.isLoading = false;
  				this.saveReady.next();
  				//this.modal.dismiss();
  			});  			
  		}  		
  	}
  	
    reloadSchedule (): void {
    	this.isLoadingTab = true;
    	this.httpService.getHttpAuth("/horario/get/all").subscribe(x => {
      		this.isLoadingTab = false;
      		this.allSchedules=x.data;
       		this.rerender();
     	});
  	}

  	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      		// Destroy the table first
      		dtInstance.destroy();
      		// Call the dtTrigger to rerender again
      		this.dtTrigger.next();
    	});
  	}

  	modalIsReady(modal) : void { this.modal = modal; }

  	delete(id){
  		this.alertService.success("Horario eliminado exitosamente");
  		this.reloadSchedule();    	
  	}
}