import { Component, OnInit } from '@angular/core';
import { Router,  ActivatedRoute } from "@angular/router";
import { Request } from '@interfaces/request';
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import * as moment from "moment"

@Component({
  selector: 'app-requests-approve',
  templateUrl: './requests-approve.component.html',
  styleUrls: ['./requests-approve.component.scss']
})
export class RequestsApproveComponent implements OnInit {

  	constructor(
  		private activeRoute : ActivatedRoute,
  		private alertService: AlertService,
	    private router: Router,
	    public httpService: HttpService,
  	) { }

  	request : Request = {
		codigo : 'a',
		id_concepto : 0,
		id_motivo : 0,
		id_usuario : 0,  		
		fecha_inicio : null,
		fecha_fin : null,
		fecha : null, 
		razon: '',
		nota : '',
		token : null,
  	};
    err : boolean = false;
  	happy : boolean = true;
  	text : string = '';

  	ngOnInit() {
  		this.activeRoute.queryParams.subscribe(params => {
	    	this.request.id_usuario = params.id_usuario;	    
	    	this.request.id_concepto = params.id_concepto;	     
	        this.httpService.postHttp("/login", {nombre:"janustime", token : params.token}).subscribe(a => {
	            localStorage.removeItem("token");
	            localStorage.removeItem("user");
	            localStorage.removeItem("permissions");
	            localStorage.removeItem("grupo");
	            localStorage.setItem("token",a.token);
	            localStorage.setItem("user",JSON.stringify(a.user));
	            localStorage.setItem("permissions",JSON.stringify(a.permissions));
	            localStorage.setItem("grupo",JSON.stringify(a.grupo));

	            this.process_request(params);            
	        }, err => {
	        	this.text = 'Ésta solicitud ya ha sido aprobada.';
	        }); 	    				  	
        });	   
  	} 


  	process_request(params) {
		this.httpService.getHttpAuth("/solicitud/"+params.id).subscribe(x => {			
  			let request = x.data; 
  			this.request.fecha_inicio = request.fecha_inicio;
  			this.request.fecha_fin = request.fecha_fin;
  			this.request.fecha = request.fecha;
  			this.request.token = params.token;
  			this.request.codigo = params.codigo;
  			this.happy = (request.codigo == 'a') ? false : true; 
  		
  			if(this.happy) {      	
  				this.httpService.putHttpAuth("/solicitud/"+params.id, this.request).subscribe(x => {
  					let st = (params.codigo == 'r') ? 'rechazada' : 'aprobada';
  					this.text = 'Solicitud '+ st + ' con éxito';
  				}, error => {
  				    this.err = true;
  					this.text = error; //'La solicitud ingresada, ya ha sido aprobada o token de ingreso incorrecto';
  				});
  			} else{
  				this.text = 'Ésta solicitud ya ha sido aprobada';
  			}       		
		}, err => {
		    this.err = true;
		 	this.text = 'Solicitud aprobada o token de ingreso incorrecto';
		});	
  	} 	
}
