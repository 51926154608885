import { Component, AfterViewInit, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { GlobalsService } from '@services/globals/globals.service'
import { Subject } from 'rxjs';
import * as moment from "moment"

@Component({
  selector: 'app-report',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})

export class DistributionComponent implements OnInit {   
    
  	@ViewChild(DataTableDirective)
  	dtElement: DataTableDirective;
  	dtTrigger: Subject<any> = new Subject();
  	startDateEdit: Date = moment().toDate();
  	endDateEdit: Date = moment().toDate();
  	dtOptions: any = {
	    pagingType: 'full_numbers',
	    dom: 'Bfrtip',
    	buttons: [
        	{ extend: 'print', text: 'Imprimir', title: 'Reporte de distribución' },
        	{ extend: 'excel', title: 'Reporte de Distribución' }
      	],
  		language : {
    		url: "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
    		emptyTable : "No existen ausencias con los parametros especificados"
  		},
  		pageLength : 50     
  	};
  	type: string = "t"; 
  	isLoadingTab : boolean = false;
  	isLoading: boolean = false;
  	reports : any = [];
  	concepts: any = [];
  	groups: any = null; 
  	pv_admin : boolean = false;	

  	constructor(public httpService: HttpService, private alertService: AlertService, public gs: GlobalsService) { }
  
 	ngOnInit() {   
 		this.pv_admin = this.gs.getPermission().admin_remover_privilegios_edicion;
    	this.httpService.getHttpAuth("/concepto/caprobacion",{}).subscribe(data => { this.concepts = data.data; });
  	}
  
  	ngAfterViewInit(): void {
   		this.dtTrigger.next();
   	}

   	ngOnDestroy(): void {
     	// Do not forget to unsubscribe the event
     	this.dtTrigger.unsubscribe();
   	}

   	draw_report() : void {
  		let extra = '';  
  		let from  = moment(this.startDateEdit).format("YYYY-MM-DD");
  		let to = moment(this.endDateEdit).format("YYYY-MM-DD");		
  		if(this.groups) {
  			if(this.groups.users.selected.length > 0) {
  				let id_str = '';
				this.groups.users.selected.forEach((x,i) => {
					id_str += x.id +((i < (this.groups.users.selected.length -1)) ? ',' : '');
				});  				
				extra += '&users='+id_str;
  			} else {
  				if(this.groups.id_grupo != 0) {  			
	  				extra = '&id_tipo_grupo='+this.groups.id_tipo_grupo;
	  				if(this.groups.id_grupo) extra += '&id_grupo='+this.groups.id_grupo;
	  				if(this.groups.id_grupos) extra += '&id_grupos='+this.groups.id_grupos.toString();  			
	  			}
	  		}  			
  		}  		
  		let uri = '/reporte/distribucion?from='+from+'&to='+to+'&type='+this.type+''+extra;
  		this.isLoadingTab= true;
  		this.isLoading = true;
  		this.httpService.getHttpAuth(uri).subscribe(data => {
       		this.isLoadingTab = false;
       		this.isLoading = false;
       		this.reports = data.data;       		
       		this.rerender();
     	});
  	}
   
   	apply(){
    	this.draw_report();     
   	}
   
   	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        	// Destroy the table first
        	dtInstance.destroy();
        	// Call the dtTrigger to rerender again
        	this.dtTrigger.next();
      	});
   	}
   
   filter(event){    
     	this.groups = event;
     	if(!this.isLoadingTab) this.isLoading = event.loading; 
   	}
}
