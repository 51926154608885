import { RouterModule, Routes } from '@angular/router';

//import { DashboardComponent } from './../dashboard/dashboard.component';
//import { InfoComponent } from './../dashboard/info/info.component';
import { MainComponent } from '@pages/main/main.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { UsersComponent } from '@pages/users/users.component';
import { LoginComponent } from './../pages/login/login.component';
import { CompaniesComponent } from  "./../companies/companies.component";
import { UsersDetailComponentComponent } from "@pages/users/users-detail-component/users-detail-component.component"
import { ScheduleComponent } from '@pages/schedules/schedule/schedule.component'
//import { SchedulesDetailComponent } from './../schedule/schedules-detail/schedules-detail.component'
import { HolidaysComponent } from './../pages/configuration/holidays/holidays.component'

import { RequestsComponent } from '@pages/requests/requests.component';
import { MissingComponent } from '@pages/reports/missing/missing.component';
import { CommentsComponent } from '@pages/reports/comments/comments.component';
import { IncidentsComponent } from '@pages/reports/incidents/incidents.component';
import { SummaryComponent } from '@pages/reports/summary/summary.component';
import { DetailedComponent } from '@pages/reports/detailed/detailed.component';
import { PunchesComponent } from '@pages/reports/punches/punches.component';
import { PeriodComponent } from '@pages/reports/period/period.component';
import { ExtraHoursComponent } from '@pages/reports/extra-hours/extra-hours.component'
import { ExtraApprovesComponent } from '@pages/reports/extra-approves/extra-approves.component';
import { FundsComponent } from '@pages/reports/funds/funds.component';
import { DistributionComponent } from '@pages/reports/distribution/distribution.component';
import { SpecialComponent } from '@pages/reports/special_days/special.component';
import { MonitorComponent } from '@pages/reports/monitor/monitor.component';
import { MaintenancePageComponent } from '@pages/login/maintenance/maintenance-page.component';
import { PasswordComponent } from '@pages/login/password/password.component';
import { MonthlyScheduleComponent } from '@pages/schedules/monthly-schedule/monthly-schedule.component';
import { AuthComponent } from './../globals/auth/auth.component';
import { CompanyComponent } from '@pages/configuration/company/company.component';
import { ClockComponent } from '@pages/configuration/clock/clock.component';
import { TemplateComponent } from '@pages/configuration/template/template.component';
import { CardsComponent } from '@pages/cards/cards.component'
import { CardsDetailComponent } from '@pages/cards/cards-detail/cards-detail.component'
import { CardsDetailHoursComponent } from '@pages/cards/cards-detail-hours/cards-detail-hours.component';
import { PhoneComponent } from '@pages/punches/phone/phone.component';
import { ConceptsComponent } from '@pages/cards/concepts/concepts.component';
import { RequestsApproveComponent } from '@pages/login/requests-approve/requests-approve.component';
import { LettersComponent } from '@pages/letters/letters.component';

const routes: Routes = [
	
	{ path: '', component: LoginComponent },
	{ path: 'main',  component: MainComponent },
	{ path : 'dashboard', component : DashboardComponent },
		

	// Outer Routes
  	{ path : 'login', component: LoginComponent },
  	{ path : 'auth', component: AuthComponent },
  	{ path : 'password', component: PasswordComponent },
  	{ path : 'password/:token/:email', component: PasswordComponent },
  	{ path : 'maintenance', component: MaintenancePageComponent },
  	{ path : 'requests-approve', component : RequestsApproveComponent },

  	// content routes
  	{ path: 'users', component: UsersComponent },
    { path: 'users/:id', component: UsersDetailComponentComponent },
  	{ path: 'schedule', component: ScheduleComponent },  
  	{ path: 'monthly-schedule', component: MonthlyScheduleComponent },  	
  	{ path: 'concepts', component: ConceptsComponent },  
  	{ path: 'letters', component: LettersComponent },	
  	{ path: 'requests', component: RequestsComponent },	
  	{ path: 'cards', component: CardsComponent },
    { path: 'cards/:id', component: CardsDetailComponent },
    { path: 'cards/:id/:id_corte', component: CardsDetailComponent },
    { path: 'cards-hours/:id', component : CardsDetailHoursComponent },
    { path: 'punches', children : [{ path : 'phone', component : PhoneComponent }] },

   	// reports
   	{	
   		path : 'reports', 
   		children : [
   			{ path : 'comments', component : CommentsComponent },   
   			{ path : 'missing', component : MissingComponent },   
   			{ path : 'incidents', component : IncidentsComponent }, 			
            { path : 'extras', component: ExtraHoursComponent },
        	{ path : 'extras-approved', component: ExtraApprovesComponent },
        	{ path : 'funds', component: FundsComponent },   
        	{ path : 'punches', component: PunchesComponent },           
        	{ path : 'distribution', component: DistributionComponent },
        	{ path : 'special', component:SpecialComponent },
        	{ path : 'summary', component:SummaryComponent },
        	{ path : 'detailed', component:DetailedComponent },
        	{ path : 'period', component:PeriodComponent },
			{ path : 'monitor', component:MonitorComponent },
        ]
   	},

   	// config routes
  	{ path: 'holidays', component: HolidaysComponent },
   	{ path : 'template', component : TemplateComponent },
   	{ path : 'company', component : CompanyComponent },
   	{ path : 'clock', component : ClockComponent } 
];

export { routes }
