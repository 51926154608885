import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import * as moment from "moment"
import 'twix';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent implements OnInit {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
	dtTrigger: Subject<any> = new Subject();
	//dtElement: DataTableDirective;
   	//dtElement: DataTablesModule;
  	isLoadingTab: boolean = true;  	
  	id_empresa: number;  	
  	dtOptions: any = {
    	pagingType: 'full_numbers',
   		language : {
            "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
   	 	}
  	};

  	op_save = {
  		disabled: false,
   	 	isLoading:false,
   	 	onlyIcon : true,
  		text:"Guardar",
    	type:"btn btn-primary",
    	icon:"fa fa-spinner fa-spin",    	
    	principal_icon:"fa fa-floppy-o"
  	}; 

  	modal : any;
  	ng_clocks : any = [];

  	constructor(public httpService: HttpService,  private alertService: AlertService) { }

  	ngOnInit() { 
	    this.reset(false);
	    this.draw();	   	
  	}

  	ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

 


  	draw() {  		
  		this.isLoadingTab = true;  		
  		this.httpService.getHttpAuth("/reloj/get/all").subscribe(x => {	  
  			this.isLoadingTab = false;
  			this.ng_clocks = x.data;  	
	      	this.rerender();	     
	    });
  	}   	

  	reset(onlydetail) : void {
		
  	}

    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }
}