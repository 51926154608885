import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content-loader-checks',
  templateUrl: './content-loader-checks.component.html',
  styleUrls: ['./content-loader-checks.component.scss']
})
export class ContentLoaderChecksComponent implements OnInit {

  constructor() { }
  @Input() isLoadingTab = true;
  @Input() type = 1;

  ngOnInit() {
  }

}
