import { Component, OnInit,AfterViewInit } from '@angular/core';
import { HttpService } from "./../../services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { GlobalsService } from '@services/globals/globals.service'
import Chart from 'chart.js';
import { Gauge } from 'gaugeJS';
import * as moment from "moment";

@Component({
  	selector: 'app-dashboard',
  	templateUrl: './dashboard.component.html',
  	styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  	users_counter: number = 0;
 	request_counter: number = 0;
  	approve_pending: number = 0;
  	clocks: number = 0;
  	ng_selected_employee = "x";  
  	checks_pending: any=[];
  	system: any = [];
  	gauges: any = {entrada : 0, salida : 0, ausente: 0, total: 2000};
  	grploader : any = JSON.parse(localStorage.getItem('grupo'));
  	rol;  
  	counters = { company : 0,admins : 0, super:0, emps: 0, pending : 0,clocks : 0,today : 0};
  	tardies = [];
  	concepts = [];
  	ng_stats: any = {};  
  	checks: any;
  	gauge: any;
  	oddities : any;
  	comments = [];
  	ng_selected_incident_user = 'x';
  	ng_checks_on_users = [];  	
  	ng_incidents = [];  	
  	ng_types_loaded :boolean = false;
  	ng_gauge_loaded : boolean = false;
  	ng_punch_web_permission : boolean = false;
  	ng_satisfaction = 1;
  	employees : any = [];
  	ng_date = new Date();  
  	ng_curr_time = '';
  	ng_profile_picture = ''
  	ng_circle_title = '';
  	ng_circle_modal : any;
  	ng_circle_list_current : any;
  	ng_circle_list_employees : any;
  	ng_loading = {checks: true, tardies : true, requests: true};

  	//buttons
	btn1 = {
		text:"Marcar",
      	disabled: false,
      	isLoading:false,
      	type:"btn btn-primary",
      	icon:"fa fa-spinner fa-spin",
      	principal_icon:"fa fa-search",
      	onlyIcon : true      	
	};

  	constructor(public httpService: HttpService, private alertService: AlertService, private gs: GlobalsService) { }  
  
  	async ngOnInit() {
  		this.rol = this.grploader.id_rol;
  		let pic = this.grploader.usuario.perfil;  		
  		this.ng_profile_picture = (!pic) ? 'assets/images/perfil/alfredorods.png' : pic.url; 
  		this.reset();
  		let temp_emp = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/colaboradores', {});
  		this.employees = temp_emp.data;
	  	this.checks = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/marcaciones', {});	  	
	  	this.ng_loading.checks = false; // shows the checks panel after loading	  	
  		if(this.rol != 5) {  			
  			let counters_punches = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/contadores_empresa', {});
  			var circulos = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/circulos', {});
  			var comms = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/comentarios', {});
  			this.comments = comms.data;
  			
  			this.gauges.total = circulos.data.total;
  			this.gauges.entrada = circulos.data.entrada;
  			this.gauges.salida = circulos.data.salida;
  			this.gauges.ausente = circulos.data.ausente;
  			this.ng_circle_list_employees = circulos.data.lista;
  			  			
  			// contadores
  			this.counters.clocks = counters_punches.cant_reloj;	
  			this.counters.admins = counters_punches.cant_admin;
  			this.counters.super = counters_punches.cant_super;
  			this.counters.company = counters_punches.cant_total;
  			this.counters.emps = counters_punches.cant_colab;
  			
  			// incidents processing
  			let temp_incidents = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/incidencias', {});  	
  			for(let i = 0; i < temp_incidents.data.length; i++) {
  				let temp = temp_incidents.data[i];
  				if(temp.caso=='incompleta') {
  					temp.font_awesome = 'fa fa-question';
  					temp.color = 'icon-warning';
  				} else {
  					temp.font_awesome = 'fa fa-times';
  					temp.color = 'icon-blood';
  				}  				
  				this.ng_incidents.push(temp);
  			}	
  			
  			//this.tardies = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/tardanzas', {});  		
  			//this.ng_loading.tardies = false; 		
  			//this.concepts = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/conceptos_manuales', {});
  			//this.ng_loading.requests = false;   			  		
  		}
  		
  		//var counters = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/contadores', {});
  		if(this.rol == 5){
  			let temp_stats = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/estadisticas', {});	
  			let type_punches = <any> await this.httpService.asyncHttpAuth("GET", '/dashboard/tipos', {});
  			let ng_moment = moment(this.ng_date);
  			let info = this.grploader.usuario;  
  			var meses = [
  				'Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio',
  				'Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
  			let mes = meses[ng_moment.get('month')];
  			this.ng_curr_time = ng_moment.format('hh:mm A');
  				  			
  			this.ng_types_loaded = true;  			
  			type_punches = type_punches.data;
  			this.ng_stats = temp_stats.data;   					
  			// type punches chart code 
  			var ctx = document.getElementById('types');	   
  			var chart = new Chart(ctx, {
    			type: 'horizontalBar',
    			data: {
    				labels: ["reloj", "app", "web"],
    				datasets: [{
    					label :"Cantidad de Marcaciones ("+mes+")",
    					backgroundColor : ["#3e95cd", "#8e5ea2","#3cba9f"],
    					barThickness : 50,
    					data : [type_punches.reloj, type_punches.app, type_punches.web]			      
				    }]
    			}  		
			});		
			// success gauge code
			var gel = document.getElementById('success-gauge');			
			this.gauge = new Gauge(gel).setOptions({
				angle: 0, 
				lineWidth: 0.44, // The line thickness
				radiusScale: 1, // Relative radius
				pointer: {
				   	length: 0.6, // // Relative to gauge radius
				   	strokeWidth: 0.035, // The thickness
				    color: '#000000' // Fill color
				},
				limitMax: false,     // If false, max value increases automatically if value > maxValue
				limitMin: false,     // If true, the min value of the gauge will be fixed
				colorStart: '#6FADCF',   // Colors
				colorStop: '#8FC0DA',    // just experiment with them
				strokeColor: '#E0E0E0',  // to see which ones work best for you
				generateGradient: true,
				highDpiSupport: true,   // High resolution support
				percentColors : [[0.0, "#F25959" ], [0.50, "#F9D93A"], [1.0, "#63E95F"]],
				staticLabels: {
  					font: "10px sans-serif",  // Specifies font
  					labels: [10, 25, 50, 75, 100],  // Print labels at these value
				},
			});			
			this.gauge.maxValue = 100;
			this.gauge.setMinValue(0);  
			this.gauge.animationSpeed = 15;	
			this.ng_gauge_loaded = true;
			this.change_score('m');	
			//this.gauge.set(this.ng_stats.puntaje);
			//this.score(this.stats.puntaje); 		
  		}

  		await this.httpService.asyncHttpAuth("GET", '/dashboard/destruir', {});		  
  	}

  	search($evt) : void {
  		let id = this.ng_selected_employee;
  		this.httpService.getHttpAuth('/dashboard/marcaciones_por_usuario?id=' +id, {}).subscribe(x => {
  			this.ng_checks_on_users = x.data;
  		});
  	}

  	punch() {
  		this.btn1.disabled = true;
	    this.btn1.isLoading = true;    
	    this.httpService.postHttpAuth("/marcacion-detalle", {'tipo': 'web', 'marcacion_app': false }).subscribe(x => {
	    	this.alertService.success("Marcación realizada satisfactoriamente");	      
	      	this.btn1.disabled = false;
	      	this.btn1.isLoading = false;
    	}, error => {
    		this.btn1.disabled = false;
    		this.btn1.isLoading = false;
    	});  	
  	}

  	change_score(option) {
  		this.httpService.getHttpAuth('/dashboard/puntaje?op='+option, {}).subscribe(x => {
  			this.score(x);
  		});
  	}


  	score(x) {
  		this.gauge.set(x);
		if(x >= 0 && x < 25) this.ng_satisfaction = 1;
		else if(x >= 25 && x < 50) this.ng_satisfaction = 2;
		else if(x >= 50 && x < 75) this.ng_satisfaction = 3;
		else if(x >= 75 && x < 90) this.ng_satisfaction = 4;
		else this.ng_satisfaction = 5;
  	}

  	circles_modal_open(type) {  		
  		this.ng_circle_title = 'Lista de colaboradores ';
  		if(this.ng_circle_list_employees) {
  			if(type == "entrada") {
  				this.ng_circle_title += 'marcacando entrada'; 
  				this.ng_circle_list_current = this.ng_circle_list_employees.entrada;
  			}else if(type == "salida") {
  				this.ng_circle_title += 'marcacando salida'; 
  				this.ng_circle_list_current = this.ng_circle_list_employees.salida;
  			}else{
  				this.ng_circle_title += 'ausentes'; 
  				this.ng_circle_list_current = this.ng_circle_list_employees.ausente;
  			} 
  			this.ng_circle_modal.open();
  		}  		
  	}

    modalIsReady(modal) : void { 
    	this.ng_circle_modal = modal;     	
    } 

  
  	ngAfterViewInit(){ 
  	}

  	reset() : void {
  		this.checks = { data : []};
  		this.ng_stats = {
  			tardanzas : 0, 
  			horas_trabajadas : 0, 
  			ausencias : 0, 
  			horario : {
  				entrada : '00:00',
  				salida : '00:00',
  				descanso : '',
  				libre : ''
  			},
  			periodo_activo : {inicio : '---', fin : null},
  			sucursal : '---',
  			departamento : '---',
  			division : '---',
  			centro_costo : '---',
  			detalles : []
  		};  		
  	}

}
