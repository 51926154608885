import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from "moment"

@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss']
})
export class PeriodComponent implements OnInit {

 	@ViewChild('PDF') pdf : ElementRef<any>;
  	isLoadingTab: boolean = false;
  	isLoading: boolean = false;
  	report: any = {};
  	groups: any = {};
  	lib = [];
  	closures = [];
  	selectedClosure = null;
  	watching = '';
  	ng_start : any;
  	ng_end : any;
  	gb_date = new Date();

  	constructor(public http: HttpService, private alertService: AlertService) { }

  	ngOnInit() {
  		this.ng_start = this.gb_date;
  		this.ng_end = this.gb_date;  		
  	}

  	draw_report() {  	
  		let extra = '';
  		this.watching = '';
  		this.report = {};
  		this.isLoadingTab = true;
  		this.isLoading = true; 
  		this.lib = [];		
  		let from  = moment(this.ng_start).format("YYYY-MM-DD");
  		let to = moment(this.ng_end).format("YYYY-MM-DD"); 
  		if(this.groups) {
  			if(this.groups.users.selected.length > 0) {
  				let id_str = '';
				this.groups.users.selected.forEach((x,i) => {
					id_str += x.id +((i < (this.groups.users.selected.length -1)) ? ',' : '');
				});  				
				extra += '&users='+id_str;
  			} else {
  				if(this.groups.id_grupo != 0) {  			
	  				extra = '&id_tipo_grupo='+this.groups.id_tipo_grupo;
	  				if(this.groups.id_grupo) extra += '&id_grupo='+this.groups.id_grupo;
	  				if(this.groups.id_grupos) extra += '&id_grupos='+this.groups.id_grupos.toString();  			
	  			}
	  		}  			
  		}
   		this.http.getHttpAuth('/reporte/tarjetas?from='+from+'&to='+to+extra, {}).subscribe(data => {
      		this.isLoadingTab = false;
      		this.isLoading = false;
      		this.lib = data.data;
      		if(this.lib.length > 0){
      			this.report = this.lib[0];
      			this.watching = this.report.codigo;
      		}     		
    	});    
  	}

  	apply(){
  		this.draw_report();  		
   	}  

   	change_emp(t){
   		let index = 0;
   		this.isLoadingTab = true;
   		for(let i = 0; i < this.lib.length; i++) {
   			if(this.watching == this.lib[i].codigo){
   				if(t == 'prev') i--;
   				if(t == 'next') i++;
   				index = i;
   				break;
   			}
   		}
   		this.isLoadingTab = false;   		
   		if(index >= 0 && index < this.lib.length) {   			
   			this.report = this.lib[index];
   			this.watching = this.report.codigo;
   		}else{
   			this.alertService.info("No hay resultados");   			
   		}  		
   	}


   	filter(event){    		
     	this.groups=event;     
     	if(!this.isLoadingTab) this.isLoading = event.loading;      	
   	}

   	descarga() {   		
   		let HTML = this.pdf.nativeElement.children[0].innerHTML;   		
   		var mywindow = window.open("'PRINT', 'height=400,width=1200'");
   		mywindow.document.write(HTML);
   		mywindow.document.close();
      	mywindow.focus();
      	mywindow.print();
      	mywindow.close();
   	}

}
