import { Component, OnInit, OnDestroy, Input, HostListener, Output,  EventEmitter, ViewChild  } from '@angular/core';
import { AfterViewInit, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from "@angular/core"  
import { FormsModule } from '@angular/forms';
import { Router,  ActivatedRoute } from "@angular/router";
import { SharedService } from "@services/eventsEmmiter/shared.service"
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ContentLoaderChecksComponent } from "@app/globals/content-loader-checks/content-loader-checks.component"
import * as moment from "moment"
import { GlobalsService } from '@services/globals/globals.service'
import { ModalConfirmComponent } from "@html/modals/modal-confirm/modal-confirm.component"
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular"
import { Users } from "@interfaces/users";
import { FormatHoursPipe } from "@pipes/format-hours.pipe";

interface AngSelectEvent {
  	name: string;
  	value: any;
}

@Component({
  	selector: 'app-users',
  	templateUrl: './users.component.html',
  	styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit {

	constructor(
		private _sharedService: SharedService, 
		public httpService: HttpService,
	    public gs: GlobalsService,
	   	private formatHoursPipe: FormatHoursPipe,
	    private alertService: AlertService,
	    private router: Router,
	    private activeRoute : ActivatedRoute,
	    private resolver: ComponentFactoryResolver) 
	{ 
		router.routeReuseStrategy.shouldReuseRoute = () => false;  
	}

  	@Output() onUserChanged = new EventEmitter<any>();  	
  	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
  	@ViewChild("uidt") dpicker: IgxDatePickerComponent;
  	//@ViewChild("appModalCalendar") appModalCalendar : any;
  	@ViewChild("alertContainer", { read: ViewContainerRef }) container;
  	@ViewChild("fc") filtersCompany;

  	// datatables
  	dtTrigger: Subject<any> = new Subject();  	
  	dtOptions: any = {
	    dom: 'Bfrtip',
	    buttons: [{ 
	    	extend: 'excel',
	        title: 'Reporte de usuarios',
	        exportOptions : { 
	            stripNewlines: false,
	            columns: ':not(.notexport)'
	        } 
	    }],
	    language : {
	      	url : "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
	      	emptyTable : "No existen usuarios"
	    },
	    pageLength : 25     
	};

	componentRef: ComponentRef<any>;
  	users: any = [];  
  	isLoadingTab: boolean = true;
  	filters: any = null;
  	// my variables
  	body : Users;
  	id_empresa: number;
  	grploader : any = JSON.parse(localStorage.getItem('grupo'));    	    	
    ng_users: Array<any> = [];
  	ng_selected_users: Array<any> = [];
  	ng_user_type = 'admin';
  	ng_user_types = [];
  	type_user_filter: string = "3,4";  
  	type_state_filter: string = '1';
    modal : any;
    modal_sch : any
    ng_list_users = [];
    ng_list_concepts = [];
    ng_modals = {users: null, schedule : null};
    ng_users_schedules = [];
    ng_current_user_schedule_index = 0;   
    ng_block_left = false;
    ng_block_right = false;
    ng_registered_concepts = [];
    ng_filter_date_start = new Date();
    ng_filter_date_end = new Date();
    ng_date = new Date();
    ng_start_date = new Date();
    ng_end_date = new Date();
    resetFields : Subject<any> = new Subject();
    current_user_schedule : any = null;  
    pv_admin : boolean = false;
    search_btn : any = {
  		text:"Buscar",
	    disabled: false,
	    onlyIcon: true,
	    isLoading: false,
	    principal_icon : "fa fa-search",
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin"	 
  	};


    ngOnInit() {          	
	   	this.id_empresa = this.grploader.grupos.empresa.id;	  
	   	this.pv_admin = this.gs.getPermission().admin_remover_privilegios_edicion;
	   
        this.activeRoute.queryParams.subscribe(params => {
	    	this.ng_user_type = params.type; 
	    	let struct = JSON.parse(localStorage.getItem("filters_users"));
    		
	    	if(struct){  
	    		this.filters = {};  		
	    		if(struct.division!=0) {
	    			this.filters.id_tipo_grupo = 4;
	    			this.filters.id_grupo = struct.division;
	    		}else if(struct.department!=0){
	    			this.filters.id_tipo_grupo = 3;
	    			this.filters.id_grupo = struct.department;
	    		}else if(struct.branch != 0){
	    			this.filters.id_tipo_grupo = 2;
	    			this.filters.id_grupo = struct.branch;
	    		} else {
	    			this.filters.id_tipo_grupo = 1;
	    			this.filters.id_grupo = this.id_empresa;
	    		}  
	    		//console.log(struct); 		
	    		this.filtersCompany.reset_filters(struct);    		
	    	}        	
            if(params.type == 'admin') {             
                this.ng_user_types = [
                    {id : '3,4,7', name : "Todos"},
                    {id : '3', name : "Administrador"}, 
                    {id : '4', name: "Supervisor" },
                    {id : '7', name: "IT (Soporte Técnico)" }
                ];
                this.type_user_filter = '3,4,7';
            } else {               
                this.ng_user_types = [{id : '5', name : "Colaborador"}];
                this.type_user_filter = '5';              
            }

            this.loadAllUsers();
        });	   
  	}

  	search(){    
  		this.search_btn.isLoading = true;
    	this.search_btn.disabled = true; 	
  		if(this.filters != null) localStorage.setItem("filters_users", JSON.stringify(this.filters.filters));
  		this.loadAllUsers();
  	}

  
    modalIsReady(type, modal) : void { 
    	switch(type){
    		case 1: // users create modal
    			this.ng_modals.users = modal;
    			break;
    		case 2: // schedules modal 
    			this.ng_modals.schedule = modal;
    			break;    		
    	}    
    } 

    open(type) {    	
    	if(type == 'users') {
    		this.ng_modals.users.open();
    	} else if(type == 'schedule'){    	
  			this.resetFields.next();
  			this.ng_modals.schedule.open();
    	} 
    }

    close_modal_users(evt) {
    	this.ng_modals.users.dismiss();
    	this.loadAllUsers();
    } 

    dismiss(e) {
    	this.ng_modals.schedule.dismiss();
    } 	

    filter(filter){    	
    	this.search_btn.isLoading = true;
    	this.search_btn.disabled = true;    
    	if(!filter.loading && !filter.init) {        			
    		this.filters = filter;  
    		this.search_btn.isLoading = false;
    		this.search_btn.disabled = false;    		
    		//this.loadAllUsers();
    	}else{
    		setTimeout(() =>{ 
    			if(this.search_btn.isLoading) {
    				this.search_btn.isLoading = false;
    				this.search_btn.disabled = false;
    			}
    		}, 1500);
    	}  	
  	}

  	loadAllUsers(): void {
	    this.isLoadingTab = true;		        
	    let uri = "/usuario/vista?";
	    var filters = "";
	    if(this.filters && this.filters.id_tipo_grupo!=1)
	      	filters = `&id_grupo=${this.filters.id_grupo}&id_tipo_grupo=${this.filters.id_tipo_grupo}`;	   
	    //this.appModalCalendar.deleteList();  
	    uri += 'id_rol='+this.type_user_filter+'&id_estado='+this.type_state_filter + filters;
	    this.httpService.getHttpAuth(uri).subscribe(data => {	    	
        	this.isLoadingTab = false;      
        	this.search_btn.isLoading = false;
    		this.search_btn.disabled = false;    	
        	this.users = [];  
        	for(let i = 0; i < data.data.length; i++) {
        		var division_array = null;
        		if(data.data[i].id_rol == 4 && data.data[i].division) {
        			division_array = data.data[i].division.split(",");
        		}
        		let object = {
        			id : data.data[i].id_usuario,
        			codigo : data.data[i].codigo,
        			nombre : data.data[i].nombre +' '+ data.data[i].apellido,
        			id_rol : data.data[i].id_rol,
        			id_estado : data.data[i].id_estado,
        			rol : data.data[i].nombre_rol,
        			sucursal : data.data[i].sucursal,
        			departamento : data.data[i].departamento,
        			division : data.data[i].division,
        			division_array : division_array,
        			centro_costo : data.data[i].centro_costo

        		};
        		this.users.push(object);
        	}
        	
        	this.rerender();
      	})
  	}

  
  	userSelect(id, type) {  		
    	this._sharedService.emitChange({ id, type });
  	}


  	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      		// Destroy the table first
      		dtInstance.destroy();
      		// Call the dtTrigger to rerender again
      		this.dtTrigger.next();
    	});
  	}

  	ngAfterViewInit(): void {
    	this.dtTrigger.next();
  	}

  	changeFilter(filter){
    	this.filters = filter;
    	this.loadAllUsers();
  	}

  	delete(id){
  		this.alertService.success("Usuario eliminado exitosamente");
  		this.loadAllUsers();    	
  	}
  	
  	addRowToEdit(e, user) {
  		var index = 0;  		
	  	if(e.target.checked) {		  		
	  		this.ng_list_users.push({id : user.id, name : user.nombre });
	  	} else {	  		
	  		for(let i = 0; this.ng_list_users.length > 0; i++) {
	  			if(user.id == this.ng_list_users[i].id) { 
	  				index = i;
	  				break;
	  			}
	  		}	  		
	  		this.ng_list_users.splice(index, 1);
	  	}      	
  	}

  	deleteUserList(user){
  		var index = 0;
  		if (this.ng_list_users.length==1){
  			this.alertService.info('Debe tener al menos un colaborador seleccionado');
  			return 0;
  		}
  		this.ng_list_users.filter((u, i) => { if(u.id == user.id) this.ng_list_users.splice(i, 1); });   		
  	}

  
  	saveScheduleByUsers(data) {  	
    	this.httpService.postHttpAuth("/horario/users", data).subscribe(x => {      	
        	this.alertService.success("Horario agregado correctamente");
        	this.modal_sch.dismiss();
    	});
  	}

  	/*userDelete(id, i) {
	    const factory = this.resolver.resolveComponentFactory(<any>ModalConfirmComponent);
	    this.user_delete = id;
	    this.position_user_delete = i;
	    this.componentRef = this.container.createComponent(factory);
	    this.componentRef.instance.open();
	    this.componentRef.instance.Confirm.subscribe(x => {
      		this.componentRef.instance.justDismiss();
      		this.httpService.deleteHttpAuth("/usuario/" + this.user_delete , {}).subscribe(data => {
		        this.users.splice(this.position_user_delete, 1);
		        this.alertService.success("Usuario eliminado exitosamente");
		        this.rerender();
		        this.componentRef = null;
		        return 0;
        	})
    	});
    	this.componentRef.instance.Close.subscribe(x => { 
    		this.componentRef.instance.justDismiss();
    		this.componentRef = null; 
    	});
  	}*/

  	ngOnDestroy() {
  		this.dtTrigger.unsubscribe();
  		this.resetFields.unsubscribe();
   	}

  	// button loaders 
  	btn_2_2 = { // concept modal save button
  		text:"Crear Concepto",
		disabled: false,
		onlyIcon : true,
		type:"btn btn-primary",
		icon:"fa fa-spinner fa-spin",
		isLoading:false,
		principal_icon:"fa fa-floppy-o"
  	};

  	btn_1_1 : any = { // table edit button
  		disabled : false,
  		type : "btn btn-success",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-pencil",
  		onlyIcon : true,
  		isLoading : false,
  	};
  	btn_1_2 : any = { // table delete button
  		disabled : false,
  		type : "btn btn-danger",
  		icon : "fa fa-spinner fa-spin",
  		principal_icon:"fa fa-trash",
  		onlyIcon : true,
  		isLoading : false,
  		delete: true
  	};


}