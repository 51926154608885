import { Component, OnInit,ViewChild, ElementRef} from '@angular/core';
import {HttpService} from '@services/Http/http.service';
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import * as moment from "moment";
import { Subject } from "rxjs/Subject"
import { ScheduleTabInterface } from "./schedule-tab-interface";
import { GlobalsService } from '@services/globals/globals.service';
import { AlertService } from 'ngx-alerts';
import 'twix';
moment.locale('es');

@Component({
  	selector: 'app-monthly-schedule',
  	templateUrl: './monthly-schedule.html',
  	styleUrls: ['./monthly-schedule.scss']
})
export class MonthlyScheduleComponent implements OnInit {  
  	@ViewChild("modalSchedule") modalSchedule: any;
  	@ViewChild("modalTag") modalTag: any;  
  	@ViewChild("colorTags") tr : ElementRef;  
  	filter : any = {};  
  	tags: any = []; 
  	resetFields = new Subject();
  	colorTag: ScheduleTabInterface = <ScheduleTabInterface>{};
  	isLoadingSaveSchedule: boolean = false;
  	change: boolean = true;  	
  	listUsers : any = [];
  	ng_list_users = [];
  	days : any = [];
  	ng_now;
  	ng_month : number;
  	ng_months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre',
  		'Diciembre'];
  	ng_month_label: string = ""; 
  	ng_year : number;
  	modal_schedule = null;
  	modal_tag = null;
  	isLoadingCalendar: boolean = false;
  	search_options : any = {
  		text:"Buscar Horarios",
   	 	disabled: false,
    	type:"btn btn-default",
    	icon:"fa fa-spinner fa-spin",
    	isLoading:false,
    	onlyIcon: true,
    	principal_icon:"fa fa-search"
  	};

  	constructor(
  		public gs: GlobalsService,
  		private httpService: HttpService, 
  		private formatHoursPipe: FormatHoursPipe, 
  		private alertService: AlertService) { }
  
  
  	ngOnInit() { 
  		let now = new Date(); 		
    	this.colorTag.tag = "";
    	this.colorTag.color = "";
    	this.ng_now = moment(now);
    	this.days = this.set_range(undefined);
  	}

  	set_range(data) {
  		var range = [];
  		var cont = 0;  		
  		let from = moment(this.ng_now).startOf('month');
  		let to = moment(this.ng_now).endOf('month');
  		this.ng_month = this.ng_now.month() + 1;
    	this.ng_year = this.ng_now.year();
  		this.ng_month_label = this.ng_months[this.ng_month-1]+' '+this.ng_year;
  		var daysBetween = moment.twix(moment(from),moment(to)).iterate("days");    	
    	while(daysBetween.hasNext()){
      		let date = daysBetween.next().toDate();
      		let obj = {date :date, day:moment(date).format("DD"), data : null};
      		if(data != undefined) obj.data = data[cont];      		
      		range.push(obj);
      		cont++;
    	}    
    	return range;
  	}

  	changeFilter($evt){  	  		
    	this.listUsers = [];
    	this.tags.tags = [];
    	this.tags.days = [];
    	this.filter = [];
    	this.search_options.isLoading = $evt.loading;
    	this.search_options.disabled = $evt.loading;
    	this.isLoadingCalendar = $evt.loading;   
    	if(!this.filter.loading && $evt.users.total.length > 0){    	
    		this.filter = $evt;  
    	} 
  	}

  	async search_user_schedules(){
  		if(this.filter.length==0){
  			this.alertService.info("No hay usuarios en éste resultado.");
  			return;
  		}
  		let users = this.filter.users.total;
      	for (let i = 0; i < users.length; i++) { await this.loadCalendar(users[i], undefined); }      
      	this.showCounters();
      	this.isLoadingCalendar = false;
  	}

  	async loadCalendar(user, loadingCalendar) { 
  		this.isLoadingCalendar = true;	    
	    let uri = "/horario-tags?mes="+this.ng_month+"&ano="+this.ng_year+"&id="+user.id;	   
	    var data  = <any> await this.httpService.asyncHttpAuth("GET", uri, {});
	    var tags = data.data[0].dias;
	  	var range = this.set_range(tags);
	   	var index = this.listUsers.findIndex(function(item, i){ return item.id === user.id });  	   
	    if(index == -1) this.listUsers.push({ id: user.id, nombre: user.nombre, active : false, days: range });
	    else this.listUsers[index].days = range; 

	    if(loadingCalendar !=undefined) {
	    	this.isLoadingCalendar = loadingCalendar;
	    	this.ng_list_users = [];
	    }  	 
  	}

  	async changeDate(type) {
  		this.change = true;  		
  		this.ng_now = (type == 'prev') ? this.ng_now.subtract(1, 'months') : this.ng_now.add(1, 'months');  		
 		this.ng_month_label = this.ng_months[this.ng_now.month()-1]+' '+this.ng_now.year();
 		this.days = this.set_range(undefined);
 		this.listUsers=[];
 		if(this.filter.id_tipo_grupo == 4){
      		let users = this.filter.users.total;
      		for (let i = 0; i < users.length; i++) { await this.loadCalendar(users[i], undefined); }      
      		this.showCounters();
      		this.isLoadingCalendar = false;
    	}    
  	}  

  	modalIsReady(type, modal) {
  		if(type == 'tag') this.modal_tag = modal;
  		if(type == 'schedule') this.modal_schedule = modal;
  	}

  	open() {
  		if(this.ng_list_users.length == 0) {
  			this.alertService.info("Debes seleccionar al menos un usuario de la lista para editar horarios");
          	return 0;
  		}
  		this.resetFields.next();
  		this.modal_schedule.open();
  	}

  	dismiss(e) {
  		this.modal_schedule.dismiss();
  	}  

  	addToListCalendar(user){  		
  		user.active = (!user.active) ? true : false;  		
  		var index = 0;  		
	  	if(user.active) {	  		
	  		this.ng_list_users.push({id : user.id, name : user.nombre });
	  	} else {	  		
	  		for(let i = 0; this.ng_list_users.length > 0; i++) {
	  			if(user.id == this.ng_list_users[i].id) { 
	  				index = i;
	  				break;
	  			}
	  		}
	  		this.ng_list_users.splice(index, 1);
	  	}   	
  	}

  	saveScheduleByUsers(data) {  
  		var $this = this;	
    	this.httpService.postHttpAuth("/horario/users", data).subscribe(x => { 
    		let usr = x.data;
    		let keepLoadingCalendar = true;
    		async function call_async($this, user,klc){    			
    			await $this.loadCalendar(user, klc);
          		$this.showCounters();
    		}    		
    		for(var i = 0; i < usr.length; i++){
    			var user = undefined;
    			this.listUsers.find( (item,index) => {
             		if(item.id == usr[i].id) {
             			item.active = false;
             			user = item;
             		} 
          		});
          		keepLoadingCalendar = ((i+1) == usr.length) ? false : true;           	
          		call_async($this, user, keepLoadingCalendar);          		
    		}
        	this.alertService.success("Horario agregado correctamente");
        	this.modal_schedule.dismiss();
    	});
  	}

  	editDay(day) {     	
     	let data = {
  			id : null,
  			id_usuario : day.data.id_usuario,
  			user : this.listUsers.find((item, i) => { return item.id == day.data.id_usuario }),
  			fecha : day.date,
  			entrada : "00:00",
  			salida : "00:00",
  			descanso : 15,
  			minutos_extra : 5,
  			libre : false,
  		}; 
  		if(day.data.entrada != "sh") {  			
  			data.entrada = day.data.entrada; //this.formatHoursPipe.transform(day.data.entrada,"HH:mm","hh:mm A");
  			data.salida =  day.data.salida; //this.formatHoursPipe.transform(day.data.salida,"HH:mm","hh:mm A");
  			data.libre = day.data.libre;
  			data.minutos_extra = day.data.minutos_extra;
  			data.descanso = day.data.descanso;
  		}  
       
      	this.modalSchedule.openModal(data);
  	}

  	async dayChange(data){  		
    	await this.loadCalendar({id:data.data.id_usuario}, undefined);
   	 	this.showCounters();
   	 	this.isLoadingCalendar = false;
  	}


  /*async updatedSchedule(data){      
      for (let i = 0; i < data.data.data.length; i++) {
          this.listUsers.find( (item,index) => {
             if(item.id == data.data.data[i].id)
                item.active = false;
          });
      }
      
      
      for (let i = 0; i < data.data.data.length; i++) {
          this.listUsers.find( (item,index) => {
             if(item.id == data.data.data[i].id)
                item.active = false;
          });
          await this.loadCalendar({id:data.data.data[i].id});
          this.showCounters();
      }
      
      
  }*/



  	print() : void {
	   	for(let i = 0; i < this.tr.nativeElement.childNodes.length; i++) {
	  		let tr = this.tr.nativeElement.childNodes[i];
	  		let classes = tr.className;

	  		if(classes == undefined) continue;

	  		let res = classes.search(/print-me/);
	  		if (res == -1) continue;

	  		let td = tr.childNodes;
	  		for(let x = 0; x < td.length; x++){
	  			let tagname = td[x].tagName;

	  			if(tagname == "TD"){
		  			let background = td[x].style.backgroundColor;
		  			
		  			let rgb = background.substring(4, background.length-1).replace(/ /g, '').split(',');
		    		let r = rgb[0];
		    		let g = rgb[1];
		    		let b = rgb[2];   

				    td[x].animate([
				       	{"background": `rgb(${r},${g},${b},1)`},		   
				       	{"background": `rgb(${r},${g},${b},1)`},
				    ],{
				       	fill: 'forwards',
				       	duration: 300
				    });
	  			}
	  		}
	  	}

	  	window.print(); 	
  	}

  showCounters (): void {
      var tags = {
          "tags":[],
          "days":[]
      };
      for (let i = 0; i < this.listUsers.length; i++) {
          let days = this.listUsers[i].days;
          for (let k = 0; k < days.length; k++) {
              let day = days[k];             
              if(day.data.tag!="sh") {
                  let tag = tags.tags.findIndex( item => item.tag == day.data.tag);
                  if(tag==-1) {
                      let inserted = tags.tags.push({
                          "tag":day.data.tag,
                          "color":day.data.color,
                          "dias":JSON.parse(JSON.stringify(this.days)),//To copy element without the databinding methods from angular
                          "day":day
                      });
                      
                      let ind = tags.tags[inserted-1].dias.findIndex(item => {
                         item.counter=0; 
                         
                         if(moment(item.date).format("YYYY-MM-DD")==moment(day.date).format("YYYY-MM-DD")){
                             item.counter++;
                         }
                      });
                  }
                  else{
                     let tagDay = tags.tags[tag];
                     let dateSelected = tagDay.dias.find(item => {
                        if(moment(item.date).format("YYYY-MM-DD")==moment(day.date).format("YYYY-MM-DD")){
                            item.counter++;  
                        }
                                  
                     });
                  }
              }
          }
      }
      
      this.tags=tags;
      
      for (let i = 0; i < tags.tags.length; i++) {
          for (let k = 0; k < tags.tags[i].dias.length; k++) {
              let day = tags.tags[i].dias[k];
              let sel = tags.days.findIndex(element => {
                  return moment(element.date).format("YYYY-MM-DD")==moment(day.date).format("YYYY-MM-DD")
              });
              
              if(sel==-1) tags.days.push({date:day.date, counter:day.counter});  
              else tags.days[sel].counter += day.counter;
          }
      }
  }
  
  mouseleave($evt, day) {
      
    if(this.isLoadingCalendar)
        return 0;
      
    let background = $evt.target.style.backgroundColor;
    // let width = day.data.width*3;    
    let rgb = background.substring(4, background.length-1)
         .replace(/ /g, '')
         .split(',');
    let r = rgb[0];
    let g = rgb[1];
    let b = rgb[2];     
    
    // let width_new = [width,(width-(width/3)),(width-(width/3))/2];

    let player = $evt.target.animate([
       {"background": `rgb(${r},${g},${b},1)`},
       {"background": `rgb(${r},${g},${b},0.1)`},
       {"background": `rgb(${r},${g},${b},0.2)`},
       {"background": `rgb(${r},${g},${b},0.3)`},
       {"background": `rgb(${r},${g},${b},0.4)`},
       {"background": `rgb(${r},${g},${b},0.5)`},
       {"background": `rgb(${r},${g},${b},0.6)`},
       {"background": `rgb(${r},${g},${b},0.7)`},
       {"background": `rgb(${r},${g},${b},0.8)`},
       {"background": `rgb(${r},${g},${b},0.9)`},
       {"background": `rgb(${r},${g},${b},1)`},
     ],{
       fill: 'forwards',
       duration: 300
     });

  }
  
  hoverDay($evt,day){
    
    if(this.isLoadingCalendar)
        return 0;
    
    let background = $evt.target.style.backgroundColor;
    let rgb = background.substring(4, background.length-1)
         .replace(/ /g, '')
         .split(',');
    
    let r = rgb[0];
    let g = rgb[1];
    let b = rgb[2];

    
    var player = $evt.target.animate([
       {"background": `rgb(${r},${g},${b},1)`},
       {"background": `rgb(${r},${g},${b},0.9)`},
       {"background": `rgb(${r},${g},${b},0.8)`},
       {"background": `rgb(${r},${g},${b},0.7)`},
       {"background": `rgb(${r},${g},${b},0.6)`},
       {"background": `rgb(${r},${g},${b},0.5)`},
       {"background": `rgb(${r},${g},${b},0.4)`},
       {"background": `rgb(${r},${g},${b},0.3)`},
       {"background": `rgb(${r},${g},${b},0.2)`},
       {"background": `rgb(${r},${g},${b},0.1)`},
       {"background": `rgb(255,255,255)`},
     ],{
       fill: 'forwards',
       duration: 150
     });
     
  }
  
  getLabelByDay(day) {    	
      if(day.data.entrada=='sh')
        return "Sin horario asignado";
        
      if(day.data.libre)
        return "Libre";
        
      let entrada = this.formatHoursPipe.transform(day.data.entrada,"HH:mm","hh:mm A");
      let salida = this.formatHoursPipe.transform(day.data.salida,"HH:mm","hh:mm A");
      
      return entrada + " - "+ salida;
  }
  

  
  	showEditTag(tag){
      	this.colorTag.id=JSON.parse(JSON.stringify(tag)).color;
      	this.colorTag.old_tag=JSON.parse(JSON.stringify(tag)).tag;
      	this.colorTag.color = "#"+tag.color;
      	this.colorTag.tag = tag.tag;
      	this.modalTag.open();
 	}
  
  	editTag($evt){
      
      	this.colorTag.color = this.colorTag.color.replace('#', '');
      	if(this.colorTag.tag.length==0)
      	{     
          	this.alertService.danger("Debes agregar un identificador");
          	return 0;
      	}
      this.isLoadingSaveSchedule = true;
      this.httpService.putHttpAuth("/horario-tags/"+this.colorTag.id, this.colorTag)
      .subscribe(data => {
        
        for (let i = 0; i < this.listUsers.length; i++) {
            let days = this.listUsers[i].days;
            for (let k = 0; k < days.length; k++) {
              let day = days[k];
              if(day.data.color == this.colorTag.id) day.data.color = this.colorTag.color;
              if(day.data.tag == this.colorTag.old_tag) day.data.tag = this.colorTag.tag;
            }
        }
        
        for (let i = 0; i < this.tags.tags.length; i++) {
            let tag = this.tags.tags[i];
            if(tag.color == this.colorTag.id) tag.color  = this.colorTag.color;
            if(tag.tag == this.colorTag.old_tag) tag.tag = this.colorTag.tag;
        }
        let list = JSON.parse(JSON.stringify(this.listUsers));
        this.listUsers=[];
        this.listUsers = list;
        this.alertService.success("Identificador editado exitosamente");
        this.isLoadingSaveSchedule = false;
        this.modalTag.dismiss();
      }, err =>  this.isLoadingSaveSchedule = false);
  }
    
  	/*userDeleted(id){
      	console.log(id);
      	this.listUsers.find( (item,index) => {
         	if(item.id == id)
            	item.active = false;
      	});
  	}*/
}
