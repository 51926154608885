import {Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import {Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import {HttpService} from '@services/Http/http.service';
import {AlertService} from 'ngx-alerts';
//import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-letters',
  templateUrl: './letters.component.html',
  styleUrls: ['./letters.component.scss']
})
export class LettersComponent implements OnInit {

  constructor(private http: HttpService, private alertService: AlertService) { }


   	@ViewChild(DataTableDirective) dtElement: DataTableDirective; 	
   	modal: any;  
  	dtTrigger: Subject<any> = new Subject();
  	modal_title: String = 'Cartas';
  	tags: any[];  	
  	dtOptions: any = {
	    pagingType : 'full_numbers',
	    searching : false,
	   	lengthChange : false,
	    language : { url : 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json' },
    	pageLength : 10
	};
  	isLoadingTab: Boolean = false;
  	letter : any;
  	user : any;
  	ng_users_letters = [];  	
  	ng_letters_list = [];
  	ng_buttons :  any = {
  		create : {
  			text:"Crear Carta",
	    	disabled: false,
	    	onlyIcon: false,
	    	isLoading: false,
	    	type:"btn btn-default",
	    	icon:"fa fa-spinner fa-spin"	 
  		},
  		download : {
  			onlyIcon: true,
  			disabled: false,	
	    	isLoading: false,
	    	principal_icon:"fa fa-download",
	    	type:"btn btn-primary",
	    	icon:"fa fa-spinner fa-spin"	 
  		},
  		delete : {
  			onlyIcon: true,
  			disabled: false,	
	    	isLoading: false,
	    	principal_icon:"fa fa-minus",
	    	type:"btn btn-danger",
	    	icon:"fa fa-spinner fa-spin"	 
  		},
  		save : {
  			text:"Guardar",
	    	disabled: false,
	    	onlyIcon: true,
	    	isLoading: false,
	    	principal_icon:"fa fa-floppy-o",
	    	type:"btn btn-primary",
	    	icon:"fa fa-spinner fa-spin"	 
  		}, 
  		close : {
  			text:"Cancelar",
	    	disabled: false,
	    	onlyIcon: false,
	    	isLoading: false,	    	
	    	type:"btn btn-default",
	    	icon:"fa fa-spinner fa-spin"	 
  		}
  	}


	ngOnInit() {
		this.user = JSON.parse(localStorage.getItem("user"));
		this.reset();		
		this.draw();
	}

	reset() : void {		
		this.letter = {
			id_usuario: this.user.id,
      		id_archivo: 0,      		
      		tags: [],
		};
	}

	draw (): void {
    	this.isLoadingTab = true;
    	this.http.getHttpAuth("/cartas/get/all?id_usuario="+this.user.id).subscribe(x => {
      		this.isLoadingTab = false;
      		this.ng_users_letters = x.data;
       		this.rerender();
     	});
  	}

	open() {  		
		this.ng_letters_list = [];
		this.ng_buttons.save.disabled = true;
		this.ng_buttons.create.isLoading = true;
		this.ng_buttons.create.disabled = true
		this.http.getHttpAuth('/cartas').subscribe(data => {
			this.ng_buttons.create.isLoading = false;
			this.ng_buttons.create.disabled = false;
			if(data.data.length==0){
				this.alertService.info("No cuenta con plantillas de cartas compatibles para utilizar ésta función");
			} else{
				this.ng_letters_list = data.data;
				this.modal.open();
			}  
    	}, err => {
    		this.ng_buttons.create.isLoading = false;
			this.ng_buttons.create.disabled = false;
    	});    
  	}

  	get_letter_tags(value) {
  		this.ng_buttons.save.disabled = true;
  		if(this.letter.id_archivo== 0) {
  			this.reset();
  			return;		
  		}
  		let url = '/cartas/tags-match?id_archivo='+this.letter.id_archivo+'&id_usuario='+this.user.id;
  		this.http.getHttpAuth(url).subscribe(data => {
  			let tags_arr = [];
			for(let i = 0; i < data.data.length; i++) {				
				this.letter.tags.push({
					id : data.data[i].id,
					expresion : data.data[i].expresion,
					nombre : data.data[i].nombre_tag.toUpperCase(),
					valor : data.data[i].valor,
					editable : data.data[i].editable
				});			
			}
			this.ng_buttons.save.disabled = false;
   
    	}, err => this.ng_buttons.save.disabled = false );
  	}

  	save() {
  		this.isLoadingTab = true;
  		this.ng_buttons.save.disabled = true;
  		this.ng_buttons.save.isLoading = true;
	    this.http.postHttpAuth('/cartas', this.letter).subscribe(data => {
	      	this.isLoadingTab = false;
	      	this.ng_buttons.save.disabled = false;
  			this.ng_buttons.save.isLoading = false;
	      	this.alertService.success('Carta creado exitosamente');
	      	this.modal.dismiss();
	      	this.draw(); 
	    }, err => {
	    	this.isLoadingTab = false;
	      	this.ng_buttons.save.disabled = false;
  			this.ng_buttons.save.isLoading = false;
	    });
  	}

  	download(id) {
  		this.ng_buttons.download.isLoading = true;
  		this.ng_buttons.download.disabled = true;
  		this.ng_buttons.delete.disabled = true;
  		this.ng_buttons.create.disabled = true;
	    this.http.downloadHttp('/cartas/download/' + id).subscribe(blob => {
	      	const file = new Blob([blob], {type : 'application/pdf'});
	      	saveAs(file,  'carta_' + moment(new Date()).format('YYYY-MM-DD') + '.pdf');
	      	this.ng_buttons.download.isLoading = false;
  			this.ng_buttons.download.disabled = false;
  			this.ng_buttons.delete.disabled = false;
  			this.ng_buttons.create.disabled = false;
	    });
  	}

  	delete() {
  		this.alertService.success('Carta eliminada exitosamente');
  		this.draw();  		
  	}

  	close() {
    	this.modal.dismiss(); 
    	this.ng_letters_list = [];
    	this.reset();
  	}

  	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      		dtInstance.destroy();
      		this.dtTrigger.next();
    	});
  	}

  	modalIsReady(modal): void {
    	this.modal = modal;
  	}

 	ngAfterViewInit(): void {
    	this.dtTrigger.next();
  	}

}
