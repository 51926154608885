import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ButtonOpts } from "./interface-button";
import { HttpService } from "@services/Http/http.service"
import { Subject } from 'rxjs';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss']
})

export class ButtonLoaderComponent implements OnInit {
  	@Input() options: ButtonOpts;
	@Input() element: string;
	@Input() id : number;
	@Input() target : string;
  	@Output() onClick = new EventEmitter();
  	
  	open_modal = new Subject();

  	constructor( private http: HttpService) { }

  	ngOnInit() {}

  	action(confirm) {
  		if(this.id && this.target) {
  			if(confirm == undefined) {
  				this.open_modal.next();
  			} else {
  				if(confirm == true) {
  					this.http.deleteHttpAuth("/"+this.target+"/" +this.id , {}).subscribe(data => {	    
	                	this.onClick.emit(this.id);
	                	//this.componentRef = null;	                	           
	            	});
  				}
  			}
  		} else {
  			this.onClick.emit();
  		}
  	}

}