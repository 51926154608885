import { Component, OnInit } from '@angular/core';
import { HttpService } from '@services/Http/http.service'
import {environment} from '../../../environments/environment'
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import {
    AuthService,
    FacebookLoginProvider,
    GoogleLoginProvider
} from 'angular-6-social-login';

@Component({
  	selector: 'app-login',
  	templateUrl: './login.component.html',
  	styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  	constructor(
  		private alertService: AlertService, 
  		private serviceHttp: HttpService, 
  		private router: Router,
  		private active_route : ActivatedRoute,
  		private socialAuthService: AuthService  ) { }

  	name: string;
  	password: string;
  	parameters : any= {};
  	options: any = {
    	text:"Iniciar sesión",
    	disabled: false,
    	type:"btn btn-primary",
    	icon:"fa fa-spinner fa-spin",
    	isLoading:false
  	};

  	ngOnInit() {
  		this.parameters = {nombre : "", password : "", log : false};

  		// check f get param log is true
  		this.active_route.queryParams.subscribe(params => {
  			if(params.log) this.parameters.log = true;
  		});
    	console.log(environment.url_api+"/login");
  	}

  	loginMethod(event:any){
   		if(event.keyCode == 13){
      		this.login();
   		}else{
   		}
  	}

 	loginSuccess(data) :void {
 		localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("permissions");
        localStorage.removeItem("grupo");
        localStorage.removeItem("role_info");


        console.log(data);

        localStorage.setItem("token",data.token);
        localStorage.setItem("user",JSON.stringify(data.user));
        localStorage.setItem("permissions",JSON.stringify(data.permissions));
        localStorage.setItem("grupo",JSON.stringify(data.grupo));
        localStorage.setItem("role_info", JSON.stringify(data.user.rol))
        this.router.navigateByUrl('dashboard');
 	}

 	loginGoogle() { 		
 		var pseudo_this = this;
	    let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
	   
	    this.socialAuthService.signIn(socialPlatformProvider).then(
	      	(userData) => {
	      		this.parameters.email = userData.email;
	      		this.parameters.token = userData.id;
	      		this.serviceHttp.postHttp("/login-google", this.parameters).subscribe(a => {
	      			pseudo_this.loginSuccess(a);
	      		});           
	    }, (error) => {
	        console.log(error);
	        // Now sign-in with userData
	        // ...
	            
	      	}
	    );
  	}

  	login():void{     	
  		if(!this.name || !this.password){
  			this.alertService.danger("Campo de usuario o contraseña vacíos");
  			return;
  		}
  		var pseudo_this = this;  
     	this.parameters.nombre = this.name;
     	this.parameters.password = this.password;  
        this.options.disabled=true;
        this.options.isLoading=true;       
        this.serviceHttp.postHttp("/login", this.parameters).subscribe(a => { pseudo_this.loginSuccess(a); });
  	}
}
