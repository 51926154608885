import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";
import { HttpService } from "@app/services/Http/http.service";
import * as moment from "moment";
import { GlobalsService } from '@services/globals/globals.service';
import { ModalConfirmComponent } from "@html/modals/modal-confirm/modal-confirm.component";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Periodo } from '@app/globals/models/periodo';


@Component({
  	selector: 'app-cards-detail-hours',
  	templateUrl: './cards-detail-hours.component.html',
  	styleUrls: ['./cards-detail-hours.component.scss']
})
export class CardsDetailHoursComponent implements OnInit {
    checks = [];
    concepts = [];
    periodo: Periodo;  
    user = {};
    total = 0;
    totalAcumulado = 0;
    totalConceptos = 0;
    totalExtra = 0;
    isLoadingTab = false;

  	constructor(private httpService: HttpService,
              private _route: ActivatedRoute,       
              public gs: GlobalsService,           
              private router:Router) { 
      this.periodo = new Periodo([], null, null);
    }

  	ngOnInit() {
      this.isLoadingTab=true;
  		this.httpService.getHttpAuth("/periodo/horas/"+this._route.snapshot.params.id).subscribe(data => {
      		this.changeVariables(data)
    	});
  	}

    changeVariables(data){
      this.concepts = data.data.conceptos.conceptos;
      this.totalConceptos = data.data.conceptos.total;
      this.periodo = data.data.periodo;           
      this.user = data.data.usuario;  
      var checks = Object.values(data.data.marcaciones.marcaciones);
      this.checks = checks;
      this.total = data.data.marcaciones.total;
      this.totalAcumulado = data.data.marcaciones.total;
      this.totalExtra = data.data.marcaciones.total_extras;
      console.log(this.periodo);
      this.isLoadingTab=false;
    }

    changePeriod(id){
      if(id != null){
        this.isLoadingTab=true;
        this.httpService.getHttpAuth("/periodo/horas/"+id).subscribe(data => {
            this.changeVariables(data);
        });
      }
    }

    printPdf(){
      var mywindow = window.open("'PRINT', 'height=400,width=1000'");
      mywindow.document.write('<html><head><title>Datos Completos de Horas</title>');
      mywindow.document.write('</head><body>');
      mywindow.document.write('<style type="text/css">.table {width: 100%;margin-bottom: 1rem;background-color: transparent; font-size:0.8em;}'+
        '.table th,.table td {padding: 0px;vertical-align: top;border-top: 1px solid #dee2e6;}.table thead th {vertical-align: bottom;' +
  'border-bottom: 2px solid #000!important;} .table tbody + tbody {border-top: 4px solid #dee2e6;} .table .table {background-color: #fff;} .btn{display:none;}' + 
  ' .table-striped2 tbody tr:nth-of-type(odd) {background-color: rgba(0, 0, 0, 0.05);} .table-dark.table-striped tbody tr:nth-of-type(odd) { '+
  'background-color: rgba(255, 255, 255, 0.05);} thead{border-bottom: 2px solid black!important; font-weight:bold} a {color:#000; text-decoration: none} p{margin:0px;} </style>');
      mywindow.document.write('<h2>Datos Completos de Horas</h2>');
      mywindow.document.write(document.getElementById("toPrint").innerHTML);
      mywindow.document.write('<div style="margin-top:3%" class="col-md-12"><p>_______________________</p></div>');
      mywindow.document.write('<div class="col-md-12"><p>Supervisor</p></div>');
      mywindow.document.write('</body></html>');
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    }

}
