import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {

  constructor() { }

  	private attached_file : any;

  	myFile : any = {
  		size : 0,
  		extention : 0
  	};

	async upload(file) { 
    	const base_ = await this.fileRender(file); 
    		
    	this.myFile = {
    		name : file.name,
    		size : file.size,
            ext: (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : undefined,
    		type : file.type,
    		base64 : base_,
    		obj :  file
    	}
   	}

   	_get_my_file () { return this.myFile; }

    fileRender(inputFile):any {
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            temporaryFileReader.readAsDataURL(inputFile);
        });
    };

    /*bytesToSize(bytes) : number {
   		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
   		if (bytes == 0) return '0 Byte';
   		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
   		return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	};*/
}
