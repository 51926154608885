import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  constructor() {  }

  getRole(){
    let user=localStorage.getItem("user");
    let user_json = JSON.parse(user);
    let role_id = user_json.id_rol;
    return role_id;
  }

  getUserId(){
    let user=localStorage.getItem("user");
    let user_json = JSON.parse(user);
    let id = user_json.id;
    return id;
  }
  
  getUserName(){
    let user=localStorage.getItem("user");
    let user_json = JSON.parse(user);
    let name = user_json.nombre + " " + user_json.apellido;
    return name;
  }
  
  getPermission(){
    let permissions=localStorage.getItem("permissions");
    let permissions_json = JSON.parse(permissions);
    
    return permissions_json;
  }

  getCompany(){
  	return JSON.parse(localStorage.getItem('grupo')).grupos.empresa;
  }

  getHierarchy() :number {
  	 return JSON.parse(localStorage.getItem("role_info")).jerarquia; 
  }
}
